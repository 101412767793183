import { ManageAppointmentScenario, BookAppointmentScenario } from './../../common/Constants';
import { IRapPageContext, Services, RapService, IRapService } from "../Context";
import { ApplicationInsights, IExtendedConfiguration, IPageActionOverrideValues } from '@microsoft/1ds-analytics-web-js';
import { localizedStrings } from "../../common/localization/LocalizedStrings";
import * as Constants from "../../common/Constants";

/**
 * The telemetry service is used to publish telemetry events back to Azure appInsights.
 */
 export interface IOneDsService extends IRapService {
    capturePageView(): void;
    capturePageAction(overrideValues: IPageActionOverrideValues): void;
}

export class OneDsService extends RapService implements IOneDsService {
    private analytics: ApplicationInsights;

    public _serviceStart(appContext: IRapPageContext) {
        super._serviceStart(appContext);
        this.analytics = new ApplicationInsights();
        this._initializeService();
    }

    private _initializeService(): void {
        const environment = (window.location.hostname.includes(Constants.Dev) || window.location.hostname.includes(Constants.Ppe))
            ? "PPE"
            : "PROD";
        const market = localizedStrings.getLanguage();
        const page = window.location.pathname.includes(Constants.PageRouteChange) ? ManageAppointmentScenario : BookAppointmentScenario;

        var config: IExtendedConfiguration = {
            instrumentationKey: "f7c2b075921c4b049c6dd863561c2e7e-c028dac4-debd-4e9e-9d59-73c47891b6c8-7172",
            channelConfiguration:{
                eventsLimitInMem: 50
            },
            webAnalyticsConfiguration:{
                autoCapture: {
                    scroll: true,
                    pageView: false,
                    onLoad: true,
                    onUnload: true,
                    click: true,
                    resize: true,
                    jsError: true,
                },
                coreData: {
                    market: market,
                    pageName: page
                },
                urlCollectQuery: true,
            },
            propertyConfiguration: {
                env: environment
            }
        };
        //Initialize SDK
        this.analytics.initialize(config, []);
    }

    public capturePageView() {
        this.analytics.capturePageView();
    }

    public capturePageAction(overrideValues: IPageActionOverrideValues) {
        this.analytics.capturePageAction(document.createElement('div'), overrideValues);
    }
}

Services.add("IOneDsService", { serviceFactory: OneDsService });
