import React from "react";

import PageError from "../PageError/PageError";

interface IErrorBoundaryState {
    hasError?: boolean;
    error?: Error;
    errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends React.Component<{ children?: React.ReactNode }, IErrorBoundaryState> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    public render() {
        const { hasError, error } = this.state;

        if (hasError) {
            return <PageError title="Something went wrong." details={error ? error.message + "\n" + error.stack : undefined} />;
        }

        return this.props.children;
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({ hasError: true, error, errorInfo });
    }
}

export default ErrorBoundary;
