import produce from "immer";
import { AppointmentUpdateType } from "../../../common/Constants";

import { IAppointmentsViewState, AppointmentsViewModels, AppointmentStages, ManagementStages } from "../Contracts";
import { AppointmentsActionsType, AppointmentsActionTypes } from "./AppointmentsActions";

const Resources = require("./../components/Resources.json");

export function appointmentsReducer(state: IAppointmentsViewState, action: AppointmentsActionsType): IAppointmentsViewState {
    return produce(state || {}, draft => {
        switch (action.type) {
            case AppointmentsActionTypes.InitScheduler: {
                draft.scheduler = {
                    stage: AppointmentStages.TYPE,
                    isDateValid: false,
                    appointment: {
                        customerFirstName: "",
                        customerLastName: "",
                        customerEmail: "",
                        appointmentDate: new Date(),
                        customResponses: [],
                        appointmentTypeId: "",
                        appointmentCategoryId: ""
                    },
                    accordions: {
                        isTypeOpen: false,
                        isTopicOpen: false,
                        isDateOpen: false,
                        isInfoOpen: false
                    }
                }
                draft.modals = {}
                break;
            }
            case AppointmentsActionTypes.InitManager: {
                draft.manager = {
                    stage: ManagementStages.CONFIRMATION_NUMBER,
                    appointment: {}
                }
                draft.modals = {};
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentServiceTypesByStoreSuccess: {
                draft.serviceTypes = action.payload;
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentServiceTopics: {
                draft.serviceTopics = undefined;
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentServiceTopicsSuccess: {
                draft.serviceTopics = action.payload;
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentTimeslotsSuccess: {
                draft.timeslots = action.payload;
                draft.areTimeslotsLoading = false;
                break;
            }
            case AppointmentsActionTypes.CreateAppointmentSuccess: {
                draft.createdAppointment = action.payload;
                draft.selectedAppointment = action.payload;
                draft.isSubmitInProgress = false;
                break;
            }
            case AppointmentsActionTypes.CreateAppointmentFailure: {
                draft.error = action.payload;
                draft.isSubmitInProgress = false;
                console.error(action.payload);
                break;
            }
            case AppointmentsActionTypes.FetchRedirectsFailure:
            case AppointmentsActionTypes.FetchCaptchaSiteKeyFailure:
            case AppointmentsActionTypes.FetchStoresFailure:
            case AppointmentsActionTypes.FetchStoreByStoreIdFailure:
            case AppointmentsActionTypes.FetchAppointmentServiceTypesByStoreFailure:
            case AppointmentsActionTypes.FetchAppointmentServiceTopicsFailure:
            case AppointmentsActionTypes.FetchAppointmentStatusReasonsFailure:
            case AppointmentsActionTypes.FetchAppointmentStatusesFailure:
            case AppointmentsActionTypes.FetchTopicCustomQuestionsFailure: {
                draft.error = action.payload;
                console.error(action.payload);
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentTimeslotsFailure: {
                draft.error = action.payload;
                draft.areTimeslotsLoading = false;
                console.error(action.payload);
                break;
            }
            case AppointmentsActionTypes.UpdateAppointmentFailure: {
                draft.error = action.payload;
                console.error(action.payload);
                draft.isSubmitInProgress = false;
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentByConfirmationNumber: {
                draft.selectedAppointment = undefined;
                draft.manager.appointment = {};
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentByConfirmationNumberSuccess: {
                draft.selectedAppointment = action.payload;
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentByConfirmationNumberFailure: {
                draft.error = action.payload;
                draft.selectedAppointment = undefined;
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentStatusReasonsSuccess: {
                draft.appointmentStatusReasons = action.payload;
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentStatusesSuccess: {
                draft.appointmentStatuses = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateAppointmentSuccess: {
                draft.isSubmitInProgress = false;
                if (action.payload.type === AppointmentUpdateType.Cancel) {
                } else if (action.payload.type === AppointmentUpdateType.Update) {
                    draft.selectedAppointment = action.payload.appt;
                }
                break;
            }
            case AppointmentsActionTypes.FetchTopicCustomQuestionsSuccess: {
                draft.customQuestions = action.payload;
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentTimeslots: {
                draft.areTimeslotsLoading = true;
                break;
            }
            case AppointmentsActionTypes.UpdateSelectedTimezone: {
                draft.selectedTimezone = action.payload;
                break;
            }
            case AppointmentsActionTypes.ResetCreatedAppointment: {
                draft.createdAppointment = undefined;
                break;
            }
            case AppointmentsActionTypes.SetIsSubmitInProgress: {
                draft.isSubmitInProgress = action.payload;
                break;
            }
            case AppointmentsActionTypes.FetchStoreByStoreIdSuccess: {
                draft.storeSearchResults = action.payload;
                break;
            }
            case AppointmentsActionTypes.FetchStoreByStoreId: {
                draft.storeSearchResults = undefined;
                break;
            }
            case AppointmentsActionTypes.FetchStoresSuccess: {
                draft.stores = action.payload;
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentByIdSuccess: {
                draft.selectedAppointment = action.payload;
                break;
            }
            case AppointmentsActionTypes.FetchAppointmentByIdFailure: {
                draft.selectedAppointment = undefined;
                draft.error = action.payload;
                break;
            }

            case AppointmentsActionTypes.SetErrorMessage: {
                draft.error = action.payload;
                break;
            }
            case AppointmentsActionTypes.ResetErrorMessage: {
                draft.error = undefined;
                break;
            }
            case AppointmentsActionTypes.FetchCaptchaSiteKeySuccess: {
                draft.captchaSiteKey = action.payload;
                break;
            }
            case AppointmentsActionTypes.ResetAppointmentTimeslots: {
                draft.timeslots = undefined;
                break;
            }
            case AppointmentsActionTypes.FetchTopicCustomQuestions: {
                draft.customQuestions = undefined;
                break;
            }
            case AppointmentsActionTypes.UpdateAppointment:
            case AppointmentsActionTypes.CreateAppointment: {
                draft.isSubmitInProgress = true;
                break;
            }
            case AppointmentsActionTypes.UpdateValidatedStoreId: {
                draft.validatedStoreId = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateSchedulerStage: {
                draft.scheduler.stage = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateSchedulerSelectedDate: {
                draft.scheduler.selectedDate = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateSchedulerSelectedTime: {
                draft.scheduler.selectedTime = action.payload;
                break;
            }
            case AppointmentsActionTypes.ResetSchedulerSelectedTime: {
                draft.scheduler.selectedTime = undefined;
                break;
            }
            case AppointmentsActionTypes.UpdateSchedulerSelectedTypeName: {
                draft.scheduler.selectedTypeName = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateSchedulerSelectedTopicName: {
                draft.scheduler.selectedTopicName = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateSchedulerAppointment: {
                let { stage, appt } = action.payload;
                draft.scheduler.appointment = appt;
                draft.scheduler.stage = stage;

                /* 
                The purpose of this switch is reset all the sections BELOW the updated section, but not the sections above it.
                Leaving the breaks out of the switch mimics the behavior we want in the form: everything below the current section is executed, but not the stuff above it. 
                So no need to add the same things to multiple sections, the rolling switch execution will hit everything below it. 
                
                ** To keep this clean, each section should only reset the section IMMEDIATELY FOLLOWING IT in the form **
                So the Type case resets the Topic section, which then resets the Date section, etc. 
                There might be some special cases though like the custom responses needing to be reset if the topic changes even though thats part of the info section.
                */
                switch(stage) {
                    case AppointmentStages.TYPE: {
                        draft.scheduler.accordions.isTopicOpen = false;
                        draft.scheduler.appointment.appointmentCategoryId = "";
                        draft.scheduler.selectedTopicName = "";
                    }
                    case AppointmentStages.TOPIC: {
                        draft.scheduler.appointment.customResponses = [];
                        draft.scheduler.selectedDate = "";
                        draft.scheduler.selectedTime = "";
                        draft.scheduler.isDateValid = false;
                        draft.scheduler.accordions.isDateOpen = false;
                    }
                    case AppointmentStages.DATE_TIME: {
                        draft.scheduler.accordions.isInfoOpen = false;
                    }
                }
                break;
            }
            case AppointmentsActionTypes.UpdateSchedulerAccordions: {
                draft.scheduler.accordions = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateSchedulerIsDateValid: {
                draft.scheduler.isDateValid = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateManagerSelectedDate: {
                draft.manager.selectedDate = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateManagerSelectedTime: {
                draft.manager.selectedTime = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateConfirmationNumber: {
                draft.manager.confirmationNumber = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateManagerStage: {
                draft.manager.stage = action.payload;
                break;
            }
            case AppointmentsActionTypes.FetchRedirects: {
                draft.redirects = undefined;
                break;
            }
            case AppointmentsActionTypes.FetchRedirectsSuccess: {
                draft.redirects = action.payload;
                break;
            }
            case AppointmentsActionTypes.UpdateModals: {
                draft.modals = {...state.modals, ...action.payload};
                break;
            }
            case AppointmentsActionTypes.UpdateManagerAppointment: {
                draft.manager.appointment = {...state.manager.appointment, ...action.payload};
                break;
            }
            default:
                return state;
        }
    });
}
