import React, { createRef, FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DefaultButton, PrimaryButton } from "@fluentui/react";

import { IRapComponentProperties } from "../../../../platform/Layout";
import { ManagementStages } from "../../Contracts";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { AppointmentsActions } from "../../redux/AppointmentsActions";
import { IAppointment } from "../../../../contracts/swagger/_generated";
import { CallToAction } from "../../../../common/components/CallToAction/CallToAction";
import { IDashboardViewState } from "../../../../pages/Dashboard/Contracts";
import { AppointmentsFeature } from "../../../../common/Constants";
import { isAppointmentEditable } from "../../../../common/Util";
import { getRedirectUrl, getLearnAboutAppointmentsLabel } from "../../AppointmentsHelper";
import { CancelModal } from "./components/CancelModal/CancelModal";
import { RescheduleButton } from "./components/RescheduleButton/RescheduleButton";

import ManageAppointment from "../ManageAppointment/ManageAppointment";
import AppointmentDateTime from "../AppointmentDateTime/AppointmentDateTime";
import AppointmentCancel from "../AppointmentCancel/AppointmentCancel";
import AppointmentConfirmation from "../AppointmentConfirmation/AppointmentConfirmation";
import SummaryImage from "./../../../../styling/images/sidebar@2x.png";
import GroupImage from "./../../../../styling/images/confirm-group@2x.png";
import ManagerSummary from "../ManagerSummary/ManagerSummary";

import * as FeatureManagementSelectors from "../../../FeatureManagement/redux/FeatureManagementSelectors";
import * as AppointmentSelectors from "../../redux/AppointmentsSelectors";
import * as Constants from "../../../../common/Constants";

import "./AppointmentManager.scss";
import { announce } from "../../../../platform/core/util/Accessibility";

interface IAppointmentManagerState {
    isDateValid: boolean;
}

//Props passed by parent component
interface IAppointmentManagerProvidedProps extends IRapComponentProperties {
}

//Props mapped from state object
interface IAppointmentManagerInitializerOwnProps {
    selectedAppointment: IAppointment;
    isCancellationReasonEnabled: boolean;
    EnableScheduleAutoAssignment: boolean | undefined;
    confirmationNumber: string;
    stage?: ManagementStages;
}

// eslint-disable-next-line
export type IAppointmentManagerInitializerProps = ConnectedProps<typeof connector> & IAppointmentManagerProvidedProps

const AppointmentManagerInitializer: FC<IAppointmentManagerInitializerProps> = (props) => {
    const pageHeader = createRef<HTMLDivElement>();
    const [ isDateValid, setIsDateValid ] = useState(false);

    useEffect(() => {
        props.logTelemetry(AppointmentsFeature.AppointmentManager, "Initializing appointment manager", undefined);
        props.initView();

        props.logOneDSPageView();
        if (props.stage === ManagementStages.CONFIRMATION_NUMBER) {
            props.logOneDSAction({
                pageName: Constants.ManageAppointmentScenario,
                behavior: Constants.StartProcessBehavior,
                contentTags: {
                    scn: Constants.ManageAppointmentScenario,
                    scnstp: Constants.ManageStartStep,
                    stpnum: "1"
                }
            });
        }

        props.logTelemetry(AppointmentsFeature.AppointmentManager, "Appointment manager intialized", undefined);
    }, []); // component did mount

    useEffect(() => {
        // make sure focus goes to the top of the page when we load a new stage and announce the page title
        pageHeader.current?.focus();
        announce(pageHeader.current?.textContent ? pageHeader.current.textContent : "", true);
    }, [props.stage]);

    const getContent = () => {
        switch(props.stage) {
            case ManagementStages.CONFIRMATION_NUMBER: {
                return <ManageAppointment confirmationNumber={props.confirmationNumber} />
            }
            case ManagementStages.DATE_TIME: {
                return props.selectedAppointment && props.EnableScheduleAutoAssignment !== undefined &&
                    <AppointmentDateTime 
                        onSelect={setDate}
                        flow={"Manage"}
                    />
            }
            case ManagementStages.CANCEL: {
                return props.selectedAppointment && <AppointmentCancel canceledAppointment={props.selectedAppointment}/>
            }
            case ManagementStages.CONFIRM_UPDATE: {
                return props.selectedAppointment && 
                    <AppointmentConfirmation 
                        confirmationNumber={props.selectedAppointment.confirmationNumber as string}
                        appointment={props.selectedAppointment}
                        showSummary={true}
                    />
            }
            default: {
                return <>Test</>
            }
        }
    }

    const getButtonGroup = () => {
        switch(props.stage) {
            case ManagementStages.CONFIRMATION_NUMBER: {
                return (
                    <>
                        {props.selectedAppointment && isAppointmentEditable(props.selectedAppointment as IAppointment) && (
                            <>
                                <DefaultButton 
                                    text={localizedStrings.AppointmentManager?.cancelAppointment}
                                    onClick={() => props.updateModals({ displayCancellationModal: true })}
                                    allowDisabledFocus 
                                    className={"c-cancel-button"}
                                    data-bi-bhvr={Constants.CheckpointBehavior1DS}
                                    data-bi-scn={Constants.ManageAppointmentScenario}
                                    data-bi-scnstp={Constants.CancelStep}
                                    data-bi-stpnum={4}
                                />
                                <PrimaryButton 
                                    text={localizedStrings.AppointmentManager?.rescheduleAppointment}
                                    onClick={() => props.updateManagerStage(ManagementStages.DATE_TIME)}
                                    className={"c-reschedule-button"} 
                                    data-bi-bhvr={Constants.CheckpointBehavior1DS}
                                    data-bi-scn={Constants.ManageAppointmentScenario}
                                    data-bi-scnstp={Constants.RescheduleStep}
                                    data-bi-stpnum={2}
                                />
                                <div className="c-cta-container">
                                    <CallToAction 
                                        title={getLearnAboutAppointmentsLabel(props.selectedAppointment?.storeNumber)}
                                        href={getRedirectUrl(props.selectedAppointment?.storeNumber, props.selectedAppointment?.appointmentType)}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )
            }
            case ManagementStages.DATE_TIME: {
                return (
                    <>
                        <DefaultButton 
                            text={localizedStrings.AppointmentScheduler?.back} 
                            onClick={previousStage} 
                            className={"c-back-button"} 
                        />
                        <RescheduleButton
                            disabled={!isDateValid}
                        />
                    </>
                )
            }
            default: {
                return (
                    <>
                    </>
                )
            }
        }
    }

    const getHeader = () => {
        switch(props.stage) {
            case ManagementStages.CONFIRMATION_NUMBER: {
                return localizedStrings.AppointmentManager?.manageTitle;
            }
            case ManagementStages.CANCEL: {
                return localizedStrings.AppointmentManager?.cancelTitle;
            }
            case ManagementStages.DATE_TIME: {
                return localizedStrings.AppointmentManager?.rescheduleTitle;
            }
            case ManagementStages.CONFIRM_UPDATE: {
                return localizedStrings.AppointmentManager?.thanksForRescheduling;
            }
        }
    }

    const getImage = () => {
        switch(props.stage) {
            case ManagementStages.CONFIRMATION_NUMBER:
            case ManagementStages.DATE_TIME: {
                return (
                    <img 
                        className="c-image"
                        src={SummaryImage} 
                        alt="A person uses their phone and laptop to join an online appointment with a Microsoft product expert." 
                    />
                )
            }
            case ManagementStages.CANCEL:
            case ManagementStages.CONFIRM_UPDATE: {
                return (
                    <img 
                        className="c-image"
                        src={GroupImage} 
                        alt="A team of Microsoft product experts." 
                    />
                )
            }
        }
    }

    const setDate = (date: Date, formattedDate: string, bookableResourceId?: string) => {
        props.setAppointmentForUpdate({
            appointmentDate: date, 
            formattedAppointmentDate: formattedDate, 
            bookableResourceId: props.EnableScheduleAutoAssignment ? bookableResourceId : undefined,
            ownerId: props.EnableScheduleAutoAssignment ? undefined : Constants.DefaultOwnerId
        });
        setIsDateValid(true);
    }

    const previousStage = () => {
        // User is navigating back from reschedule step, so clear the selections
        setIsDateValid(false);
        if(props.stage) {
            props.updateManagerStage(props.stage-1);
            props.updateManagerSelectedDate(undefined);
            props.updateManagerSelectedTime(undefined);
        }
        props.resetAppointmentTimeslots();
    }

    return (
        <div className="c-manage">
            <div className="c-manager">
                <div className="c-manager-container">
                    <h1 ref={pageHeader} tabIndex={0}>{getHeader()}</h1>
                    <div className="c-manager-content">
                        {getContent()}
                        <div className="c-button-container">
                            {getButtonGroup()}
                        </div>
                    </div>
                </div>
                <div className="c-side-container">
                    {getImage()}
                    {props.stage === ManagementStages.CONFIRM_UPDATE && props.selectedAppointment ? (
                        <div className="c-summary">
                            <ManagerSummary 
                                appointment={props.selectedAppointment} 
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <CancelModal/>
        </div>
    )
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IAppointmentManagerProvidedProps): Partial<IAppointmentManagerInitializerOwnProps> {
    return {
        ...providedProps,
        selectedAppointment: AppointmentSelectors.getSelectedAppointment(state),
        EnableScheduleAutoAssignment: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableScheduleAutoAssignment", undefined),
        confirmationNumber: AppointmentSelectors.getConfirmationNumber(state),
        stage: AppointmentSelectors.getManagerStage(state),
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    initView: AppointmentsActions.initManager,
    logTelemetry: AppointmentsActions.logTelemetry,
    logOneDSPageView: AppointmentsActions.logOneDSPageView,
    logOneDSAction: AppointmentsActions.logOneDSAction,
    resetAppointmentTimeslots: AppointmentsActions.resetAppointmentTimeslots,
    updateManagerStage: AppointmentsActions.updateManagerStage,
    updateManagerSelectedTime: AppointmentsActions.updateManagerSelectedTime,
    updateManagerSelectedDate: AppointmentsActions.updateManagerSelectedDate,
    updateModals: AppointmentsActions.updateModals,
    setAppointmentForUpdate: AppointmentsActions.updateManagerAppointment
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(AppointmentManagerInitializer);
