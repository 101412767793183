import { IPageActionOverrideValues } from '@microsoft/1ds-analytics-web-js';
import { IOneDsService } from './../platform/services/OneDs';
import { IRapPageContext } from "../platform/Context";

export interface IOneDsLogger {
    capturePageView(): void;
    capturePageAction(overrideValues: IPageActionOverrideValues): void;
}

export class OneDsLogger implements IOneDsLogger {
    private context: IRapPageContext;

    public constructor(context: IRapPageContext) {
        this.context = context;
    }

    capturePageView = (): void => {
        const oneDsService = this.context.getService<IOneDsService>("IOneDsService");
        oneDsService.capturePageView();
    }

    capturePageAction = (overrideValues: IPageActionOverrideValues): void => {
        const oneDsService = this.context.getService<IOneDsService>("IOneDsService");
        oneDsService.capturePageAction(overrideValues);
    }
}
