import React, { FC, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";

import { IRapComponentProperties } from "../../../../../../platform/Layout";
import { IDashboardViewState } from "../../../../../../pages/Dashboard/Contracts";
import { RecaptchaButton } from "../../../../../../common/components/RecaptchaButton/RecaptchaButton";
import { localizedStrings } from "../../../../../../common/localization/LocalizedStrings";
import { cleanAppointmentDataForTelemetry, getAppointmentEditError, isAppointmentEditable } from "../../../../../../common/Util";
import { AppointmentForUpdateDto, IAppointment, IAppointmentForUpdateDto } from "../../../../../../contracts/swagger/_generated";
import { AppointmentsActions } from "../../../../redux/AppointmentsActions";
import { ITelemetryAttributes, ManagementStages } from "../../../../Contracts";
import { AppointmentsFeature, AppointmentUpdateType } from "../../../../../../common/Constants";

import * as FeatureManagementSelectors from "../../../../../FeatureManagement/redux/FeatureManagementSelectors";
import * as AppointmentSelectors from "../../../../redux/AppointmentsSelectors";
import * as Constants from "../../../../../../common/Constants";


const rescheduleButtonAttributes: ITelemetryAttributes = {
    dataBiBhvr: Constants.CompleteProcessBehavior1DS,
    dataBiScn: Constants.ManageAppointmentScenario,
    dataBiScnstp: Constants.NewDateTimeStep,
    dataBiStpnum: 3
};

interface IRescheduleButtonProvidedProps extends IRapComponentProperties {
    disabled?: boolean;
}

interface IRescheduleButtonOwnProps {
    isCaptchaEnabled: boolean;
    selectedAppointment: IAppointment;
    appointmentForUpdate: IAppointmentForUpdateDto;
    selectedDate: string;
    selectedTime: string;
}

// eslint-disable-next-line
export type IRescheduleButtonProps = ConnectedProps<typeof connector> & IRescheduleButtonProvidedProps;

const RescheduleButtonInitializer: FC<IRescheduleButtonProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    const submitAppointment = (token?: string) => {
        setIsLoading(true);
        props.fetchAppointmentById(props.selectedAppointment?.storeNumber as number, 
            props.selectedAppointment?.appointmentId as string).then(appt => {
                //check that appointment is still editable
                if(isAppointmentEditable(appt)) {
                    props.logTelemetry(AppointmentsFeature.AppointmentManager, 
                        "Attempting to update appointment", 
                        props.selectedAppointment?.storeNumber, 
                        cleanAppointmentDataForTelemetry(props.selectedAppointment as IAppointment)
                    );

                    props.updateAppointment(
                        props.selectedAppointment?.storeNumber ? props.selectedAppointment.storeNumber : 0, 
                        props.selectedAppointment?.appointmentId ? props.selectedAppointment.appointmentId : "",
                        new AppointmentForUpdateDto(props.appointmentForUpdate),
                        AppointmentUpdateType.Update,
                        token
                    ).then(() => {
                        props.logTelemetry(AppointmentsFeature.AppointmentManager, "Appointment updated...redirecting", undefined);
                        props.updateManagerStage(ManagementStages.CONFIRM_UPDATE);
                    });
                } else {
                    setIsLoading(false);
                    props.setErrorMessage(getAppointmentEditError(appt, props.logTelemetry));
                }
        });
    }
    
    
    return (
        <>
            {props.isCaptchaEnabled ? (
                <RecaptchaButton
                    text={isLoading ? "" : localizedStrings.AppointmentManager?.submit} 
                    action={Constants.CaptchaActions.UpdateAppointment}
                    onClick={submitAppointment} 
                    allowDisabledFocus={!isLoading}
                    disabled={props.disabled || isLoading} 
                    className={"c-next-button"}
                    telemetryAttributes={{
                        ...rescheduleButtonAttributes, 
                        dataBiField1: `${moment(props.selectedDate).format("MMM DD, YYYY")} ${props.selectedTime}`
                    }}
                >
                    {isLoading && <Spinner size={SpinnerSize.small} />}
                    <span aria-live="assertive" className="screen-reader-only">
                        {isLoading ? localizedStrings.AppointmentManager?.submitInProgress : ""}
                    </span>
                </RecaptchaButton>
            ) : (
                <PrimaryButton 
                    text={isLoading ? "" : localizedStrings.AppointmentManager?.submit} 
                    onClick={() => submitAppointment()} 
                    allowDisabledFocus={!isLoading}
                    disabled={props.disabled || isLoading} 
                    className={"c-next-button"}
                    data-bi-bhvr={Constants.CompleteProcessBehavior1DS}
                    data-bi-scn={Constants.ManageAppointmentScenario}
                    data-bi-scnstp={Constants.NewDateTimeStep}
                    data-bi-stpnum={3}
                    data-bi-field1={`${moment(props.selectedDate).format("MMM DD, YYYY")} ${props.selectedTime}`}
                >
                    {isLoading && <Spinner size={SpinnerSize.small} />}
                    <span aria-live="assertive" className="screen-reader-only">
                        {isLoading ? localizedStrings.AppointmentManager?.submitInProgress : ""}
                    </span>
                </PrimaryButton>
            )}
        </>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IRescheduleButtonProvidedProps): Partial<IRescheduleButtonOwnProps> {
    return {
        ...providedProps,
        isCaptchaEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableCaptcha", false),
        selectedAppointment: AppointmentSelectors.getSelectedAppointment(state),
        appointmentForUpdate: AppointmentSelectors.getManagerAppointment(state),
        selectedDate: AppointmentSelectors.getManagerSelectedDate(state),
        selectedTime: AppointmentSelectors.getManagerSelectedTime(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logOneDSAction: AppointmentsActions.logOneDSAction,
    fetchAppointmentById: AppointmentsActions.fetchAppointmentById,
    updateAppointment: AppointmentsActions.updateAppointment,
    logTelemetry: AppointmentsActions.logTelemetry,
    setErrorMessage: AppointmentsActions.setErrorMessage,
    updateManagerStage: AppointmentsActions.updateManagerStage
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const RescheduleButton = connector(RescheduleButtonInitializer);