import React from "react";
import { connect } from "react-redux";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../../../platform/Layout";
import { Accordion } from "../../../../common/components/Accordion/Accordion"; 

import { IDashboardViewState } from "../../../../pages/Dashboard/Contracts";
import { AppointmentsActions } from "../../redux/AppointmentsActions";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";

import "./AppointmentTopic.scss";
import { IAppointmentForCreationDto, IAppointmentTopic, ITimeSlotDto } from "../../../../contracts/swagger/_generated";
import * as Constants from "../../../../common/Constants";
import * as AppointmentsSelectors from "../../redux/AppointmentsSelectors";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { getServiceNameCategory, timeoutFunction } from "../../AppointmentsHelper"
import { AppointmentStages, IAccordionState } from "../../Contracts";
import { announce } from "../../../../platform/core/util/Accessibility";

interface IAppointmentTopicState {
}

//Props passed by parent component
interface IAppointmentTopicProvidedProps extends IRapComponentProperties {
}

//Props mapped from state object
interface IAppointmentTopicInitializerOwnProps extends IAppointmentTopicProvidedProps {
    serviceTopics: IAppointmentTopic[];
    serviceName: string;
    accordions: IAccordionState;
    appointment: IAppointmentForCreationDto;
    storeId: number;
    timeslots: Date[] | ITimeSlotDto[];
    selectedTopicName: string;
}

// eslint-disable-next-line
export type IAppointmentTopicInitializerProps = IAppointmentTopicInitializerOwnProps & typeof ActionsToDispatch;

class AppointmentTopicInitializer extends RapComponent<IAppointmentTopicInitializerProps, IAppointmentTopicState> {
    constructor(props: IAppointmentTopicInitializerProps) {
        super(props);
        if(this.props.appointment.appointmentTypeId) {
            this.props.fetchAppointmentServiceTopics(this.props.storeId, this.props.appointment.appointmentTypeId);
        }
    }

    public componentDidUpdate(prevProps: IAppointmentTopicInitializerProps) {
        // service types load
        if(this.props.appointment.appointmentTypeId && prevProps.appointment.appointmentTypeId !== this.props.appointment.appointmentTypeId) {
            this.props.fetchAppointmentServiceTopics(this.props.storeId, this.props.appointment.appointmentTypeId);
        }

        if(this.props.appointment.appointmentCategoryId && !this.props.selectedTopicName && this.props.serviceTopics) {
            const appointmentServiceTopic = this.props.serviceTopics?.find(x => x.topicId === this.props.appointment?.appointmentCategoryId);
            this.props.updateSelectedTopicName(appointmentServiceTopic?.topicName as string);
        }

        if(!prevProps.serviceTopics && this.props.serviceTopics && this.props.serviceTopics.length === 0) {
            this.props.setErrorMessage(localizedStrings.TypeTopic?.noServices as string);
        }
    }

    private isValidTopic(topic: IChoiceGroupOption ) {
        if (!topic.key || !topic.text) {
            return false;
        }

        // hide Surface live demo and Hololens for non en-us commercial users
        if (localizedStrings.getLanguage() !== Constants.UnitedStatesLocale && this.props.storeId === 9988) {
            if(topic.text.toLowerCase() === Constants.SurfaceProductLiveDemonstrationTopic.toLowerCase() || topic.text.toLowerCase() === Constants.HoloLensTopic.toLowerCase()) {
                return false;
            }
        }
        // hide surface live demo and trade-in for non en-us consumer users
        if (localizedStrings.getLanguage() !== Constants.UnitedStatesLocale && this.props.storeId === 9987) {
            if(topic.text.toLowerCase() === Constants.SurfaceLiveDemoTopic.toLowerCase() || topic.text.toLowerCase() === Constants.TradeinTopic.toLowerCase()) {
                return false;
            }
        }

        return true;
    }

    private onKeyDown = (e: React.KeyboardEvent, id?: string, name?: string) => {
        // only select the topic on enter/space keyboard event not on arrow up/down
        const enterOrSpace =
            e.key === "Enter" ||
            e.key === " " ||
            e.key === "Spacebar"
        if (enterOrSpace) {
            this.onTopicPress(id, name);
        }
    };

    private onClick = (e: React.MouseEvent, id?: string, name?: string) => {
        // filter out keyboard events (which have no screen coordinates) picked up by click listener
        if (e.screenX !== 0 || e.screenY !== 0) {
            this.onTopicPress(id, name);
        }
    }

    private getOptions = (): IChoiceGroupOption[] => {
        if(this.props.serviceTopics) {
            let options = this.props.serviceTopics.map((topic: IAppointmentTopic) => {
                return (
                    {   
                        key: topic.topicId, 
                        text: topic.topicName,
                        onKeyDown: e => this.onKeyDown(e, topic.topicId, topic.topicName),
                        onClick: e => this.onClick(e, topic.topicId, topic.topicName)
                    } as IChoiceGroupOption
                );
            });
            return options.filter(x => this.isValidTopic(x) === true);
        }
        return [];
    }

    private onTopicPress = (topicId?: string, topicName?: string) => {
        if (topicId && topicName) {
            this.props.logTelemetry(Constants.AppointmentsFeature.SelectType, "Appointment topic selected: " + topicName, this.props.storeId);
            
            // Manually log 1DS action since we can't tag the topic buttons (Fluent UI component)
            this.props.logOneDSAction({
                behavior: Constants.CheckpointBehavior1DS,
                contentTags: {
                    scn: Constants.BookAppointmentScenario,
                    scnstp: Constants.TopicStep,
                    stpnum: "3",
                    field2: topicName
                }
            });

            this.props.updateAppointment(
                AppointmentStages.TOPIC, 
                {
                    ...this.props.appointment, 
                    appointmentCategoryId: topicId as string
                }
            );


            this.props.updateAccordions({...this.props.accordions, isTopicOpen: false});
            this.props.updateSelectedTopic(topicName);

            timeoutFunction(
                () => {
                    if(this.props.timeslots) {
                        return true;
                    }
                    return false;
                },
                () => this.props.updateAccordions({...this.props.accordions, isDateOpen: true }),
                500,
                100,
                50,
                localizedStrings.AppointmentScheduler?.unableToLoadDates as string
            );
        }
        announce(`${topicName} ${localizedStrings.DateAndTime?.selectedLabel}`);
    }

    private getLabel = () => {
        var serviceNameCategory = getServiceNameCategory(this.props.serviceName);

        switch (serviceNameCategory) {
            case Constants.PersonalSetupTrainingAppointmentTypes:
                return localizedStrings.TypeTopic?.topicLabels?.personalSetupTraining;
            case Constants.HardwareAppointmentTypes:
                return localizedStrings.TypeTopic?.topicLabels?.hardwareSupport;
            case Constants.BusinessAppointmentTypes:
                return localizedStrings.TypeTopic?.topicLabels?.businessConsultation;
            case Constants.PersonalSetupAppointmentTypes:
                return localizedStrings.TypeTopic?.topicLabels?.personalSetup;
            case Constants.PersonalAppointmentTypes:
                return localizedStrings.TypeTopic?.topicLabels?.personalShopping;
            case Constants.PersonalTrainingAppointmentTypes:
                return localizedStrings.TypeTopic?.topicLabels?.personalTraining;
            case Constants.BusinessTrainingAppointmentTypes:
                return localizedStrings.TypeTopic?.topicLabels?.personalTrainingBusiness;
            case Constants.EducationAppointmentTypes:
                return localizedStrings.TypeTopic?.topicLabels?.educationConsultation;
            case Constants.LenovoAppointmentTypes:
                return localizedStrings.TypeTopic?.topicLabels?.lenovo;
            default:
                return "Error";
        }
    }

    public render() {
        return (
            <>
                <Accordion 
                    title={localizedStrings.TypeTopic?.chooseTopic} 
                    isOpen={this.props.accordions.isTopicOpen} 
                    onClick={() => this.props.updateAccordions({...this.props.accordions, isTopicOpen: !this.props.accordions.isTopicOpen })}
                    disabled={this.props.appointment.appointmentTypeId === ""}
                    isValid={this.props.appointment.appointmentCategoryId !== ""}
                >
                    {this.props.accordions.isTopicOpen && this.props.accordions.isTopicOpen === true && (
                        <div className="c-topic-container">
                            <ChoiceGroup
                                options={this.getOptions()} 
                                label={this.getLabel()} 
                                selectedKey={this.props.appointment.appointmentCategoryId} 
                                required
                            />
                            <div className="c-topic-required">{localizedStrings.AppointmentScheduler?.requiredText}</div>
                        </div>
                    )}
                    
                </Accordion>
            </>
        )
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IAppointmentTopicProvidedProps): Partial<IAppointmentTopicInitializerOwnProps> {
    return {
        ...providedProps,
        serviceTopics: AppointmentsSelectors.getAppointmentServiceTopics(state),
        serviceName: AppointmentsSelectors.getSelectedTypeName(state),
        appointment: AppointmentsSelectors.getSchedulerAppointment(state),
        accordions: AppointmentsSelectors.getSchedulerAccordions(state),
        storeId: AppointmentsSelectors.getValidatedStoreId(state),
        timeslots: AppointmentsSelectors.getAppointmentTimeslots(state),
        selectedTopicName: AppointmentsSelectors.getSelectedTopicName(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: AppointmentsActions.logTelemetry,
    logOneDSAction: AppointmentsActions.logOneDSAction,
    fetchAppointmentServiceTopics: AppointmentsActions.fetchAppointmentServiceTopics,
    updateSelectedTopic: AppointmentsActions.updateSchedulerSelectedTopicName,
    setErrorMessage: AppointmentsActions.setErrorMessage,
    updateAppointment: AppointmentsActions.updateSchedulerAppointment,
    updateAccordions: AppointmentsActions.updateSchedulerAccordions,
    updateSelectedTopicName: AppointmentsActions.updateSchedulerSelectedTopicName
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentTopicInitializer);
