import { OneDsLogger } from './../../../common/OneDs';
import { SagaIterator } from "redux-saga";
import { takeEvery, call, put, getContext, select } from "redux-saga/effects";
import { ActionsOfType } from "../../../platform/redux/ActionHelper";
import { AppointmentsActions, AppointmentsActionsType, AppointmentsActionTypes } from "./AppointmentsActions";
import { AppointmentsTelemetryLogger } from "../AppointmentsTelemetryLogger";
import { IRapPageContext } from "../../../platform/Context";
import { AppointmentsFeature, AppointmentUpdateType, HardwareAppointmentTypes } from "../../../common/Constants";
import { ApiException, Appointment, AppointmentServiceType, AppointmentTopic, AppointmentTopicCustomQuestion, IAppointment, IAppointmentServiceType, IDigitalReservationsApiClient, ITimeSlotDto, OptionSetAttribute, Redirects, StoreDto, StoreRedirect } from "../../../contracts/swagger/_generated";
import { getAppointmentServiceTypes, getSelectedAppointment } from "./AppointmentsSelectors";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";
import { cleanAppointmentDataForTelemetry, cleanAppointmentForCreationForTelemetry } from "../../../common/Util";

import moment from "moment";
import { isFeatureFlagEnabled } from "../../FeatureManagement/redux/FeatureManagementSelectors";

export function* appointmentsSaga(): SagaIterator {
    yield takeEvery(AppointmentsActionTypes.LogTelemetry, logTelemetry);
    yield takeEvery(AppointmentsActionTypes.LogOneDSPageView, logOneDSPageView);
    yield takeEvery(AppointmentsActionTypes.LogOneDSAction, logOneDSAction);
    yield takeEvery(AppointmentsActionTypes.FetchAppointmentServiceTypesByStore, fetchAppointmentServiceTypesByStore);
    yield takeEvery(AppointmentsActionTypes.FetchAppointmentServiceTopics, fetchAppointmentServiceTopics);
    yield takeEvery(AppointmentsActionTypes.FetchAppointmentByConfirmationNumber, fetchAppointmentByConfirmationNumber);
    yield takeEvery(AppointmentsActionTypes.FetchAppointmentTimeslots, fetchAppointmentTimeslots);
    yield takeEvery(AppointmentsActionTypes.FetchAppointmentStatuses, fetchAppointmentStatuses);
    yield takeEvery(AppointmentsActionTypes.FetchAppointmentStatusReasons, fetchAppointmentStatusReasons);
    yield takeEvery(AppointmentsActionTypes.UpdateAppointment, updateAppointment);
    yield takeEvery(AppointmentsActionTypes.CreateAppointment, createAppointment);
    yield takeEvery(AppointmentsActionTypes.FetchTopicCustomQuestions, fetchTopicCustomQuestions);
    yield takeEvery(AppointmentsActionTypes.FetchStoreByStoreId, fetchStoreByStoreId);
    yield takeEvery(AppointmentsActionTypes.FetchStores, fetchStores);
    yield takeEvery(AppointmentsActionTypes.FetchAppointmentById, fetchAppointmentById);
    yield takeEvery(AppointmentsActionTypes.FetchCaptchaSiteKey, fetchCaptchaSiteKey);
    yield takeEvery(AppointmentsActionTypes.FetchRedirects, fetchRedirects);
}

export function* fetchAppointmentServiceTypesByStore(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchAppointmentServiceTypesByStore>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const storeId = action.payload;
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );
            
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getAppointmentServiceTypesByStore' call", storeId));

            const AppointmentsData: AppointmentServiceType[] = ((yield call(
                [reservationsClient,
                reservationsClient.getAppointmentServiceTypesByStore],
                storeId
            )) as unknown) as AppointmentServiceType[];

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getAppointmentServiceTypesByStore' call", storeId, AppointmentsData));

            yield put(AppointmentsActions.fetchAppointmentServiceTypesByStoreSuccess(AppointmentsData));
        } catch (error) {
            let message = error as string;
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getAppointmentServiceTypesByStore' call", undefined, {error: error}));
            yield put(AppointmentsActions.fetchAppointmentServiceTypesByStoreFailure(message));
        }
    } else {
        yield put(AppointmentsActions.fetchAppointmentServiceTypesByStoreFailure("IdToken token is invalid."));
    }
}

export function* fetchAppointmentServiceTopics(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchAppointmentServiceTopics>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const {storeId, serviceTypeId} = action.payload;
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getAppointmentTopics' call", storeId, action.payload));

            const AppointmentsData: AppointmentTopic[] = ((yield call(
                [reservationsClient,
                reservationsClient.getAppointmentTopics],
                storeId,
                serviceTypeId
            )) as unknown) as AppointmentTopic[];

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getAppointmentTopics' call", storeId, AppointmentsData));

            yield put(AppointmentsActions.fetchAppointmentServiceTopicsSuccess(AppointmentsData));
        } catch (error) {
            let message = error as string;
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getAppointmentTopics' call", undefined, {error: error}));
            yield put(AppointmentsActions.fetchAppointmentServiceTopicsFailure(message));
        }
    } else {
        yield put(AppointmentsActions.fetchAppointmentServiceTopicsFailure("IdToken token is invalid."));
    }
}

export function* fetchAppointmentTimeslots(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchAppointmentTimeslots>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const {storeId, serviceTypeId} = action.payload;

            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            let EnableScheduleAutoAssignment = ((yield select(isFeatureFlagEnabled, "EnableScheduleAutoAssignment")) as unknown) as boolean;
            let serviceTypes = ((yield select(getAppointmentServiceTypes)) as unknown) as IAppointmentServiceType[];

            if(!serviceTypes || serviceTypes.length === 0) {
                serviceTypes = ((yield call(
                    [reservationsClient,
                    reservationsClient.getAppointmentServiceTypesByStore],
                    storeId
                )) as unknown) as AppointmentServiceType[];
            }

            let label = serviceTypes.find((type: IAppointmentServiceType) => { return type.typeId === serviceTypeId;})?.typeName;
            var timeslots: Date[] | ITimeSlotDto[];

            if(EnableScheduleAutoAssignment && label !== HardwareAppointmentTypes.HardwareAppointmentType) {
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getAvailableUserTimeslots' call", storeId, action.payload));
                timeslots = ((yield call(
                    [reservationsClient,
                    reservationsClient.getAvailableUserTimeslots],
                    storeId,
                    serviceTypeId
                )) as unknown) as ITimeSlotDto[];
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getAvailableUserTimeslots' call", storeId, timeslots));
            }
            else {
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getAvailableTimeslots' call", storeId, action.payload));
                timeslots = ((yield call(
                    [reservationsClient,
                    reservationsClient.getAvailableTimeslots],
                    storeId,
                    serviceTypeId
                )) as unknown) as Date[];
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getAvailableTimeslots' call", storeId, timeslots));
            }
            yield put(AppointmentsActions.fetchAppointmentTimeslotsSuccess(timeslots));
        } catch (error) {
            let message = error as string;
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'fetchAppointmentTimeslots' call", undefined, {error: error}));
            yield put(AppointmentsActions.fetchAppointmentTimeslotsFailure(message));
        }
    } else {
        yield put(AppointmentsActions.fetchAppointmentTimeslotsFailure("IdToken token is invalid."));
    }
}

export function* fetchAppointmentByConfirmationNumber(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchAppointmentByConfirmationNumber>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        const confirmationNumber = action.payload;
        try {
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getAppointmentByConfirmationNumber' call", undefined, {confirmationNumber: action.payload}));

            const AppointmentsData: Appointment = ((yield call(
                [reservationsClient,
                reservationsClient.getAppointmentByConfirmationNumber],
                confirmationNumber
            )) as unknown) as Appointment;

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, 
                "Recieved response for 'getAppointmentByConfirmationNumber' call", 
                undefined, 
                cleanAppointmentDataForTelemetry(AppointmentsData)
            ));

            action.resolve(AppointmentsData);
            yield put(AppointmentsActions.fetchAppointmentByConfirmationNumberSuccess(AppointmentsData));
        }
        catch (error) {
            let ex: ApiException = error as ApiException;

            if(ex.status === 404) {
                yield put(AppointmentsActions.fetchAppointmentByConfirmationNumberFailure(
                    localizedStrings.formatString(localizedStrings.AppointmentManager?.unableToFindAppointment as string, { confirmationNumber: confirmationNumber}) as string
                    )
                );
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "'getAppointmentByConfirmationNumber' call resulted in not found", undefined, {error: error}));
            }
            else {
                let message = error as string;
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getAppointmentByConfirmationNumber' call", undefined, {error: error}));
                yield put(AppointmentsActions.fetchAppointmentByConfirmationNumberFailure(message));
            }
            action.reject();
        } 
    } else {
        yield put(AppointmentsActions.fetchAppointmentByConfirmationNumberFailure("IdToken token is invalid."));
    }
}

export function* fetchAppointmentStatuses(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchAppointmentStatuses>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getAppointmentStatuses' call", undefined));

            const AppointmentsData: OptionSetAttribute[] = ((yield call(
                [reservationsClient,
                reservationsClient.getAppointmentStatuses],
            )) as unknown) as OptionSetAttribute[];

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getAppointmentStatuses' call", undefined, AppointmentsData));

            yield put(AppointmentsActions.fetchAppointmentStatusesSuccess(AppointmentsData));
        } catch (error) {
            let message = error as string;
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getAppointmentStatuses' call", undefined, {error: error}));
            yield put(AppointmentsActions.fetchAppointmentStatusesFailure(message));
        }
    } else {
        yield put(AppointmentsActions.fetchAppointmentStatusesFailure("IdToken token is invalid."));
    }
}

export function* fetchAppointmentStatusReasons(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchAppointmentStatusReasons>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getAppointmentStatusReasons' call", undefined));

            const AppointmentsData: OptionSetAttribute[] = ((yield call(
                [reservationsClient,
                reservationsClient.getAppointmentStatusReasons],
            )) as unknown) as OptionSetAttribute[];

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getAppointmentStatusReasons' call", undefined, AppointmentsData));

            yield put(AppointmentsActions.fetchAppointmentStatusReasonsSuccess(AppointmentsData));
        } catch (error) {
            let message = error as string;
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getAppointmentStatusReasons' call", undefined, {error: error}));
            yield put(AppointmentsActions.fetchAppointmentStatusReasonsFailure(message));
        }
    } else {
        yield put(AppointmentsActions.fetchAppointmentStatusReasonsFailure("IdToken token is invalid."));
    }
}

export function* updateAppointment(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.UpdateAppointment>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const {storeNumber, appointmentId, captchaToken, appt, type} = action.payload;
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'updateAppointment' call", storeNumber, action.payload));
            
            const result: any = ((yield call(
                [reservationsClient,
                reservationsClient.updateAppointment],
                storeNumber,
                appointmentId,
                captchaToken,
                appt
            )) as unknown) as any;

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'updateAppointment' call", storeNumber, result));
            
            let selectedAppointment: IAppointment | undefined = ((yield select(getSelectedAppointment)) as unknown) as IAppointment;
            if(type === AppointmentUpdateType.Update) {
                let start = moment(selectedAppointment.appointmentDate);
                let end = moment(selectedAppointment.scheduledEndDate);
                let diff = end.diff(start, "minutes");


                selectedAppointment.appointmentDate = appt.appointmentDate;
                selectedAppointment.scheduledEndDate = moment(appt.appointmentDate).add(diff, 'minutes').toDate();
            }

            action.resolve(selectedAppointment);
            
            yield put(AppointmentsActions.updateAppointmentSuccess(type, selectedAppointment));
        } catch (error) {
            action.reject(error);
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'updateAppointment' call", undefined, {error: error}));
            yield put(AppointmentsActions.updateAppointmentFailure(localizedStrings.AppointmentManager?.updateError as string));
        }
    } else {
        yield put(AppointmentsActions.fetchAppointmentByConfirmationNumberFailure("IdToken token is invalid."));
    }
}

export function* createAppointment(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.CreateAppointment>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const { storeId, captchaToken, appointment } = action.payload;
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'createAppointment' call", storeId,
                {
                    storeId: storeId,
                    appointment: cleanAppointmentForCreationForTelemetry(appointment),
                    token: captchaToken
                }));

            const response: Appointment = ((yield call(
                [reservationsClient, reservationsClient.createAppointment],
                storeId,
                captchaToken,
                appointment
            )) as unknown) as Appointment;

            //set PII data that doesnt get returned with the response
            response.customerFirstName = appointment.customerFirstName;
            response.customerLastName = appointment.customerLastName;
            response.customerEmail = appointment.customerEmail;

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'createAppointment' call", storeId, cleanAppointmentDataForTelemetry(response)));

            yield put(AppointmentsActions.createAppointmentSuccess(response));
        } catch (error) {
            let ex: ApiException = error as ApiException;
            var response;
            try {
                response = JSON.parse(ex.response);
            }
            catch (ex) {
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error parsing exception", undefined, { error: ex }));
            }
            let duplicateType = response.title as string;
            let confirmationNumber = response.detail as string;
            if (ex.status === 409) {
                if (duplicateType.includes("Service")) {
                    yield put(AppointmentsActions.createAppointmentFailure(localizedStrings.formatString(localizedStrings.AppointmentScheduler?.duplicateServiceAppointment as string, { confirmationNumber: confirmationNumber.replace(/"/g, '') }) as string));
                } else { // Timeslot
                    yield put(AppointmentsActions.createAppointmentFailure(localizedStrings.formatString(localizedStrings.AppointmentScheduler?.duplicateTimeAppointment as string, { confirmationNumber: confirmationNumber.replace(/"/g, '') }) as string));
                }
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'createAppointment' call duplicate appointment found", undefined, { error: error }));

            } else {

                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'createAppointment' call", undefined, { error: error }));
                yield put(AppointmentsActions.createAppointmentFailure(localizedStrings.AppointmentScheduler?.unableToCreateAppointment as string));
            }
        }
    } else {
        yield put(AppointmentsActions.createAppointmentFailure("IdToken token is invalid."));
    }
}

export function* fetchTopicCustomQuestions(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchTopicCustomQuestions>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const { storeId, serviceTypeId, topicId } = action.payload;
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getTopicCustomQuestions' call", storeId, action.payload));

            const customQuestions: AppointmentTopicCustomQuestion[] = ((yield call(
                [reservationsClient, reservationsClient.getTopicCustomQuestions],
                storeId,
                serviceTypeId,
                topicId
            )) as unknown) as AppointmentTopicCustomQuestion[];

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getTopicCustomQuestions' call", storeId, customQuestions));

            yield put(AppointmentsActions.fetchTopicCustomQuestionsSuccess(customQuestions));
        } catch (error) {
            let message = error as string;
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getTopicCustomQuestions' call", undefined, {error: error}));
            yield put(AppointmentsActions.fetchTopicCustomQuestionsFailure(message));
        }
    } else {
        yield put(AppointmentsActions.fetchTopicCustomQuestionsFailure("IdToken token is invalid."));
    }
}

export function* fetchStoreByStoreId(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchStoreByStoreId>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const storeId = action.payload;
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getStoreByStoreId' call", undefined, {storeId: action.payload}));

            const stores: StoreDto[] = ((yield call(
                [reservationsClient, reservationsClient.getStoreByStoreId],
                storeId
            )) as unknown) as StoreDto[];

            action.resolve(stores);
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getStoreByStoreId' call", undefined, stores));

            yield put(AppointmentsActions.fetchStoreByStoreIdSuccess(stores));
        } catch (error) {
            let ex: ApiException = error as ApiException;
            action.reject(ex.message);
            if(ex.status === 404) {
                yield put(AppointmentsActions.fetchStoreByStoreIdFailure(localizedStrings.AppointmentScheduler?.invalidStore as string));
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "'getStoreByStoreId' call resulted in not found", undefined, {error: error}));
            }
            else {
                let message = error as string;
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getStoreByStoreId' call", undefined, {error: error}));
                yield put(AppointmentsActions.fetchStoreByStoreIdFailure(message));
            }
        }
    } else {
        yield put(AppointmentsActions.fetchStoreByStoreIdFailure("IdToken token is invalid."));
    }
}

export function* fetchStores(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchStores>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getStores' call", undefined));

            const stores: StoreDto[] = ((yield call(
                [reservationsClient, reservationsClient.getStores]
            )) as unknown) as StoreDto[];

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getStores' call", undefined, stores));

            yield put(AppointmentsActions.fetchStoresSuccess(stores));
        } catch (error) {
            let message = error as string;
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getStores' call", undefined, {error: error}));
            yield put(AppointmentsActions.fetchStoresFailure(message));
        }
    } else {
        yield put(AppointmentsActions.fetchStoresFailure("IdToken token is invalid."));
    }
}

export function* fetchAppointmentById(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchAppointmentById>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const {storeNumber, appointmentId} = action.payload;
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getAppointmentDetails' call", storeNumber, action.payload));

            const appt: Appointment = ((yield call(
                [reservationsClient, reservationsClient.getAppointmentDetails],
                storeNumber, 
                appointmentId
            )) as unknown) as Appointment;

            action.resolve(appt);

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getAppointmentDetails' call", storeNumber, cleanAppointmentDataForTelemetry(appt)));
            yield put(AppointmentsActions.fetchAppointmentByIdSuccess(appt));
        } catch (error) {
            let ex: ApiException = error as ApiException;

            action.reject(error);

            if(ex.status === 404) {
                yield put(AppointmentsActions.fetchAppointmentByIdFailure(localizedStrings.Confirmation?.unableToFindAppointment as string));
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "'getAppointmentDetails' call resulted in not found", undefined, {error: error}));
            }
            else {
                let message = error as string;
                yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getAppointmentDetails' call", undefined, {error: error}));
                yield put(AppointmentsActions.fetchAppointmentByIdFailure(message));
            }
        }
    } else {
        yield put(AppointmentsActions.fetchAppointmentByIdFailure("IdToken token is invalid."));
    }
}

export function* logOneDSPageView(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.LogOneDSPageView>): SagaIterator {
    const pageContext = yield getContext("pageContext");

    if (pageContext) {
        const oneDsLogger: OneDsLogger = new OneDsLogger(pageContext);

        try {
            yield call(oneDsLogger.capturePageView);
        } catch (error) {
            console.log("Unable to log OneDS page view", error);
        }
    }
}

export function* logOneDSAction(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.LogOneDSAction>): SagaIterator {
    const pageContext = yield getContext("pageContext");

    if (pageContext) {
        const overrideValues = action.payload;
        const oneDsLogger: OneDsLogger = new OneDsLogger(pageContext);

        try {
            yield call(oneDsLogger.capturePageAction, overrideValues);
        } catch (error) {
            console.log("Unable to log OneDS action", error);
        }
    }
}

export function* fetchCaptchaSiteKey(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchCaptchaSiteKey>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getCaptchaId' call", undefined));

            const key: string = ((yield call(
                [reservationsClient, reservationsClient.getCaptchaId]
            )) as unknown) as string;

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getCaptchaId' call", undefined, {key: key}));

            yield put(AppointmentsActions.fetchCaptchaSiteKeySuccess(key));
        } catch (error) {
            let message = error as string;
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getCaptchaId' call", undefined, {error: error}));
            yield put(AppointmentsActions.fetchCaptchaSiteKeyFailure(message));
        }
    } else {
        yield put(AppointmentsActions.fetchCaptchaSiteKeyFailure("IdToken token is invalid."));
    }
}

export function* fetchRedirects(action: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.FetchRedirects>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Dispatching 'getRedirects' call", undefined));

            const redirects: Redirects = ((yield call(
                [reservationsClient, reservationsClient.getRedirects]
            )) as unknown) as Redirects

            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Recieved response for 'getRedirects' call", undefined, {redirects: redirects}));

            yield put(AppointmentsActions.fetchRedirectsSuccess(redirects));
        } catch (error) {
            let message = error as string;
            yield put(AppointmentsActions.logTelemetry(AppointmentsFeature.AppointmentSaga, "Error in 'getRedirects' call", undefined, {error: error}));
            yield put(AppointmentsActions.fetchRedirectsFailure(message));
        }
    } else {
        yield put(AppointmentsActions.fetchCaptchaSiteKeyFailure("IdToken token is invalid."));
    }
}

export function* logTelemetry(telemetryAction: ActionsOfType<AppointmentsActionsType, AppointmentsActionTypes.LogTelemetry>): SagaIterator {
    const { feature, action, storeId, properties, errorMessage } = telemetryAction.payload;
    const pageContext = yield getContext("pageContext");

    if (pageContext) {
        var telemetryLogger: AppointmentsTelemetryLogger = new AppointmentsTelemetryLogger(pageContext, feature, action);

        try {
            if (errorMessage && errorMessage.length > 0) {
                yield call(telemetryLogger.captureError, errorMessage, storeId);
            } else {
                yield call(telemetryLogger.captureTelemetry, properties ? properties : {}, storeId);
            }
        } catch (error) {
            console.log("Unable to log telemetry", error);
        }
    }
}
