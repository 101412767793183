import { IRapPageContext, IRapService, Services, RapService } from "../Context";
import { IPageRestClient } from "../../contracts/dataaccess/Page";
import { Page } from "../../contracts/Page";
import { selectProperties } from "@fluentui/react";

/**
 * This is the basic data that describes the state of the page being executed.
 *  It has basic information about the service and the entities accessed.
 */
export interface IPageData {
    /**
     * Client version number.
     */
    versionNumber: string;

    /**
     * Does the user have owner permissions.
     */
    isOwner: boolean;

    /**
     * Does the user have admin permissions.
     */
    isAdmin: boolean;

    /**
     * Defines whether or not the page is being created in debug mode.
     */
    isDebugMode?: boolean;

    /**
     * Defines whether or not the page is coming from a devfabric environment.
     */
    isDevfabric?: boolean;

    /**
     * Defines whether or not the page is coming from a Production of Dev environment.
     */
    isProduction?: boolean;
}

export interface IRapPageService extends IRapService {
    getData(): Promise<IPageData>;
}

export class RapPageService extends RapService implements IRapPageService {
    private _pageData: IPageData;
    private _isLoaded: boolean;
    private _loadFailed: boolean;

    public _serviceStart(pageContext: IRapPageContext) {
        super._serviceStart(pageContext);

        this._pageData = { versionNumber: "", isOwner: false, isAdmin: false };
        this._isLoaded = false;
        this._loadFailed = false;

        this._initializeService();
    }

    private _initializeService(): void {
        this._fetchPageData();
    }

    private async _fetchPageData() {
        try {
            const pageClient: IPageRestClient = this.pageContext.getRestClient<IPageRestClient>("IPageRestClient");
            await pageClient
                .fetchPageData()
                .then((value: Page) => {
                    this._pageData.versionNumber = value.versionNumber;
                    this._pageData.isOwner = value.isOwner;
                    this._pageData.isAdmin = value.isAdmin;
                })
                .finally(() => {
                    this._isLoaded = true;
                    this._loadFailed = false;
                });
        } catch (exception) {
            this._isLoaded = true;
            this._loadFailed = true;
        }
    }

    public async getData(): Promise<IPageData> {
        if (this._loadFailed) {
            this._isLoaded = false;
            this._loadFailed = false;
            await this._fetchPageData();
        }

        //Wait for data to be loaded
        while (!this._isLoaded) {
            await new Promise((resolve, reject) => setTimeout(resolve, 50));
        }

        return this._pageData;
    }
}

Services.add("IRapPageService", { serviceFactory: RapPageService });
