import React, { FC } from "react";
import { DefaultButton } from '@fluentui/react/lib/Button';
import "./CallToAction.scss";
import { Icon } from "@fluentui/react/lib/Icon";

export interface ICallToActionProps {
    title?: string;
    href?: string;
}

export const CallToAction: FC<ICallToActionProps> = props => {
    return (
        <DefaultButton className="c-cta-button" href={props.href} target={props.href ? "_blank" : ""}>
            <span className="c-cta-title">{props.title}</span>
            <Icon iconName="ChevronRight" className="c-cta-icon"/>
        </DefaultButton>
    )
}