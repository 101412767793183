import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ICalendar } from "datebook";
import { DefaultButton, PrimaryButton, Link } from "@fluentui/react";

import { IRapComponentProperties, RapComponent } from "../../../../platform/Layout";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { IAppointment } from "../../../../contracts/swagger/_generated";
import { getAdditionalParametersFromQueryStringAsQueryString } from "../../../../platform/core/util/Util";
import { IDashboardViewState } from "../../../../pages/Dashboard/Contracts";
import { AppointmentsActions } from "../../redux/AppointmentsActions";
import { getConfirmationImage, getRedirectUrl, getLearnAboutAppointmentsLabel, isStoreMec } from "../../AppointmentsHelper";

import ManagerSummary from "../ManagerSummary/ManagerSummary";

import * as Constants from "../../../../common/Constants";

import * as AppointmentSelectors from "../../redux/AppointmentsSelectors";
import * as FeatureManagementSelectors from "../../../FeatureManagement/redux/FeatureManagementSelectors";

import "./AppointmentConfirmation.scss";


interface IAppointmentConfirmationState {
}

//Props passed by parent component
interface IAppointmentConfirmationProvidedProps extends IRapComponentProperties {
    showSummary?: boolean;
    showImage?: boolean;
    confirmationNumber: string;
}

//Props mapped from state object
interface IAppointmentConfirmationOwnProps {
    appointment: IAppointment;
    validatedStoreId: number;
    isAddToCalendarEnabled: boolean;
}

// eslint-disable-next-line
export type IAppointmentConfirmationProps = ConnectedProps<typeof connector> & IAppointmentConfirmationProvidedProps;

class AppointmentConfirmationInitializer extends RapComponent<IAppointmentConfirmationProps, IAppointmentConfirmationState> {
    constructor(props: IAppointmentConfirmationProps) {
        super(props);
    }

    private renderConfirmationText = () => {
        if (localizedStrings.Confirmation?.confirmText && this.props.appointment && this.props.appointment.customerEmail) {
            return localizedStrings.formatString(localizedStrings.Confirmation.confirmText, { email: this.props.appointment.customerEmail});
        }
        return "";        
    }

    private renderConfirmationNumber = () => {
        if (!localizedStrings.Confirmation?.confirmationNumber || !this.props.appointment?.confirmationNumber) {
            return "";
        }

        return `${localizedStrings.Confirmation.confirmationNumber}: ${this.props.appointment.confirmationNumber}`;
    }

    private getManageAppointmentRoute = () => {
        const confirmno = this.props.appointment?.confirmationNumber ? this.props.appointment.confirmationNumber : "";
        let matches = window.location.pathname.match(Constants.localeRegex);
        let locale = matches ? matches[0] : "/en-us/";

        return window.location.origin + locale + Constants.ManageAppointmentExtAndConfirmNo + confirmno;
    }

    private onBookAgainPress = () => {
        var s = "000" + this.props.appointment?.storeNumber?.toString();
        let finalStoreid = s.substr(s.length-4);

        let matches = window.location.pathname.match(Constants.localeRegex);
        var locale;

        if(matches && matches.length > 0) {
            locale = matches[0];
        } else {
            const interfaceLocale = localizedStrings.getInterfaceLanguage().toLowerCase();
            var loc = localizedStrings.getAvailableLanguages().includes(interfaceLocale) ? interfaceLocale : Constants.DefaultLocale;
            locale = `/${loc}/`;
        }

        var getUrl = window.location;
        var url = `${getUrl.protocol}//${getUrl.host}${locale}appointment/create/?storeid=${finalStoreid}`;

        url += getAdditionalParametersFromQueryStringAsQueryString(this.props.params, Constants.ConfirmationPageExpectedParameters)
        window.location.href = url;
    }

    private onAddToCalendarPress = () => {
        if (!this.props.appointment) {
            return;
        }
        
        const invite = this.createCalendarInvite();
        invite.download();
    }

    private createCalendarInvite = () => {
        const strings = localizedStrings.AppointmentScheduler;
        const description = `${strings?.service} - ${this.props.createdAppointment.subject}
        ${strings?.confirmationNumber} - ${this.props.createdAppointment.confirmationNumber}

        ${strings?.calendarInfo}`;
        
        return new ICalendar({
            title: strings?.calendarTitle,
            location: strings?.teamsMeeting,
            description: description,
            start: this.props.createdAppointment.appointmentDate!,
            end: this.props.createdAppointment.scheduledEndDate
        }).addAlarm({
            action: "DISPLAY",
            trigger: {
                minutes: 15
            }
        });
    }

    private renderInstructions = () => {
        if(isStoreMec(this.props.validatedStoreId)) {
            return (
                <span>{localizedStrings.Confirmation?.instructionsText}</span>
            );
        }
        return (
            <>
                <span className="c-bold-text">{localizedStrings.Confirmation?.instructionsTextOnline}</span>
                <span>{` ${localizedStrings.Confirmation?.audioCall}`}</span>
            </>
        );
    }

    public render() {
        const image = getConfirmationImage(this.props.validatedStoreId as number);

        return (
            <>
                {this.props.appointment && (
                    <div className="c-confirmation-container">
                        <div className="c-confirmation">
                            {this.props.showImage && (
                                <img 
                                    className="c-image"
                                    src={image.image} 
                                    alt={image.altText}
                                />
                            )}
                            <span>{this.renderConfirmationText()}</span>
                            <div className="c-instructions">
                                {this.renderInstructions()}
                            </div>
                            <div className="c-bold-text">{this.renderConfirmationNumber()}</div>
                            {this.props.showSummary && this.props.appointment ? (
                                <div className="c-summary">
                                    <ManagerSummary appointment={this.props.appointment} />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="c-button-container">
                            <DefaultButton 
                                role="button"
                                text={localizedStrings.Confirmation?.manageAppointment}
                                href={this.getManageAppointmentRoute()}
                                className={"c-manage-button"} 
                            />
                            <PrimaryButton 
                                role="button"
                                text={getLearnAboutAppointmentsLabel(this.props.validatedStoreId)}
                                href={getRedirectUrl(this.props.validatedStoreId, this.props.appointment.appointmentType)}
                                className={"c-learn-button"}
                            />
                        </div>
                        {this.props.isAddToCalendarEnabled && (
                            <>
                                <Link onClick={this.onAddToCalendarPress}>
                                    {localizedStrings.Confirmation?.addToCalendar}
                                </Link>
                                <br/>
                            </>
                        )}
                        <Link onClick={this.onBookAgainPress}>
                            {localizedStrings.Confirmation?.bookAnotherAppointment}
                        </Link>
                    </div>
                )}
            </> 
        );
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IAppointmentConfirmationProvidedProps): Partial<IAppointmentConfirmationOwnProps> {
    return {
        ...providedProps,
        appointment: AppointmentSelectors.getSelectedAppointment(state),
        validatedStoreId: AppointmentSelectors.getValidatedStoreId(state),
        isAddToCalendarEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableAddToCalendar", false)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(AppointmentConfirmationInitializer);
