import React, { FC, useEffect, useState } from "react";
import { DefaultButton } from '@fluentui/react/lib/Button';
import { ITelemetryAttributes } from "../../../views/AppointmentsView/Contracts";
import './DefaultButtonGroup.scss';
import { localizedStrings } from "../../localization/LocalizedStrings";

export interface IOption {
    value: string;
    optionalValue1?: string;
    optionalValue2?: string;
    ariaLabel?: string;
}

export interface IDefaultButtonGroupProps {
    options: IOption[];
    onPress(val: IOption): void;
    defaultSelected?: string;
    className?: string;
    disabled?: boolean;
    telemetryAttributes?: ITelemetryAttributes
}

export const DefaultButtonGroup: FC<IDefaultButtonGroupProps> = (props) => {
    const [checked, setChecked] = useState(props.defaultSelected ? {value: props.defaultSelected} as IOption : {value: ""});
    const [announced, setAnnounced] = useState<JSX.Element | undefined>(undefined);

    const onClick = (value: IOption) => {
        checked === value ? setChecked({value: ""}) : setChecked(value);
    }

    useEffect(() => {
        setChecked(props.defaultSelected ? {value: props.defaultSelected} : {value: ""});
    }, [props.defaultSelected])

    useEffect(() => {
        props.onPress(checked);
    }, [checked])

    useEffect(() => {
        if (props.options.find(option => option.value === props.defaultSelected) === undefined) {
            setChecked({value: ""});
        }
    }, [props.options])
    
    const getContent = () => {
        return props.options.map((option: IOption, index: number) => {
            return (
                <DefaultButton 
                    toggle
                    onClick={() => onClick(option)}
                    text={option.value}
                    ariaLabel={`${option.ariaLabel ? option.ariaLabel : option.value} ${localizedStrings.DateAndTime?.requiredLabel}`}
                    key={index} 
                    className={`c-selection ${checked.value === option.value ? "checked" : ""} ${props.className ? props.className : ""}`} 
                    tabIndex={0}
                    checked={checked.value === option.value}
                    disabled={props.disabled}
                    data-bi-bhvr={props.telemetryAttributes?.dataBiBhvr}
                    data-bi-scn={props.telemetryAttributes?.dataBiScn}
                    data-bi-scnstp={props.telemetryAttributes?.dataBiScnstp}
                    data-bi-stpnum={props.telemetryAttributes?.dataBiStpnum}
                    data-bi-field3={props.telemetryAttributes?.dataBiField3}
                    data-bi-field4={props.telemetryAttributes ? option.value : undefined}
                />
            );
        })
    }
    
    return (
        <div className="c-selection-list" aria-live="polite">
            {announced}
            {getContent()}
        </div>
    )
}