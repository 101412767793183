import { ActionsUnion, createAction, createActionWithCallback, createThunkActionType } from "../../../platform/redux/ActionHelper";
import { FeatureFlag } from "../../../contracts/FeatureFlag";

/**
 * Actions for FeatureManagement view
 */
export enum FeatureManagementActionTypes {
    FetchFeatureFlags = "FeatureManagement/FetchFeatureFlags",
    FetchFeatureFlagsSuccess = "FeatureManagement/FetchFeatureFlagsSuccess",
    FetchFeatureFlagsFailure = "FeatureManagement/FetchFeatureFlagsFailure"
}

export const FeatureManagementStandardActions = {
    fetchFeatureFlagsSuccess: (featureFlags: FeatureFlag[]) => createAction(FeatureManagementActionTypes.FetchFeatureFlagsSuccess, featureFlags),
    fetchFeatureFlagsFailure: (error: string) => createAction(FeatureManagementActionTypes.FetchFeatureFlagsFailure, error)
};

const FeatureManagementThunkActions = {
    fetchFeatureFlags: (storeId: number) => createActionWithCallback<FeatureFlag[]>(FeatureManagementActionTypes.FetchFeatureFlags, storeId),
}

const FeatureManagementThunkActionsTypes = {
    fetchFeatureFlags: (storeId: number) => createThunkActionType(FeatureManagementActionTypes.FetchFeatureFlags, storeId),
}

export const FeatureManagementActions = {...FeatureManagementStandardActions, ...FeatureManagementThunkActions};
const AllActionsTypes = {...FeatureManagementStandardActions, ...FeatureManagementThunkActionsTypes};
export type FeatureManagementActionsType = ActionsUnion<typeof AllActionsTypes>;
