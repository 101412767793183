import React from "react";
import { RapComponent } from "../../../../platform/Layout";

import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import "./AppointmentCancel.scss";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import * as Constants from "../../../../common/Constants";
import { StoreIdErrors } from "../../../../common/Constants";
import { IAppointment } from "../../../../contracts/swagger/_generated";
import { getRedirectUrl, getLearnAboutAppointmentsLabel } from "../../AppointmentsHelper";

export interface IAppointmentCancelProps {
    canceledAppointment: IAppointment;
}

export default class AppointmentCancel extends RapComponent<IAppointmentCancelProps> {
    public componentDidMount(): void {
        window.scrollTo(0, 0);
    }

    public render() {
        return (
            <div className="c-cancel-container">
                <div className="c-cancel">
                    <div>{localizedStrings.Cancellation?.text}</div>
                </div>

                <div className="c-button-container">
                    <DefaultButton 
                        role="button"
                        text={localizedStrings.Confirmation?.bookAnotherAppointment}
                        href={this.getApointmentCreatePageRoute()}
                        className={"c-book-appointment-button"} 
                    />
                    <PrimaryButton 
                        role="button"
                        text={getLearnAboutAppointmentsLabel(this.props.canceledAppointment.storeNumber)}
                        href={getRedirectUrl(this.props.canceledAppointment.storeNumber, this.props.canceledAppointment.appointmentType)}
                        className={"c-learn-button"}
                    />
                </div>
            </div>
        );
    }

    private getApointmentCreatePageRoute = () => {
        let storeid = this.props.canceledAppointment.storeNumber ? this.props.canceledAppointment.storeNumber : StoreIdErrors.NoIdProvided;

        let matches = window.location.pathname.match(Constants.localeRegex);
        let locale = matches ? matches[0] : "/en-us/";

        return window.location.origin + locale + Constants.AppointmentCreateExtAndStoreParam + storeid;
    }
}
