import { Area, TelemetryProperties } from "./Contracts";
import { AppointmentsFeature } from "../../common/Constants";
import { TelemetryLogger } from "../../common/Telemetry";
import { IRapPageContext } from "../../platform/Context";

export class AppointmentsTelemetryLogger {
    protected _adminTelemetry: TelemetryLogger;
    private _feature: AppointmentsFeature;
    private _action: string;

    public constructor(context: IRapPageContext, feature: AppointmentsFeature, action: string) {
        this._adminTelemetry = new TelemetryLogger(context);

        this._feature = feature;
        this._action = action;
    }

    public captureAttemptStart = (): void => {
        this.captureTelemetry({});
    };

    public captureError = (errorMessage: string, storeId?: number): void => {
        var properties: { [key: string]: any } = {};
        properties[TelemetryProperties.Action] = this._action;
        properties[TelemetryProperties.ErrorMessage] = errorMessage;

        this._adminTelemetry.sendComplexTelemetry(Area.Dashboard, AppointmentsFeature[this._feature], properties, storeId);
    };

    public captureTelemetry = (properties: { [key: string]: any }, storeId?: number): void => {
        properties[TelemetryProperties.Action] = this._action;

        this._adminTelemetry.sendComplexTelemetry(Area.Dashboard, AppointmentsFeature[this._feature], properties, storeId);
    };
}
