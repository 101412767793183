import React from 'react';
import { IRapPageContext } from "./Context";
import { IObservable, IObservableObject, ObservableObject } from "./core/Observable";
import { TimerManagement } from "./core/TimerManagement";
import { ICancelablePromise, makeCancelable } from "./core/util/Promise";
import { EventManagement } from "./core/EventManagement";

/**
 * Basic component context available to all components that are rendered within a properly
 * configured React.DOM tree. This applies to trees rendered with page regions.
 */
export interface IRapComponentContext {
    pageContext: IRapPageContext;
}

/**
 * Any IObservableProperty is used to represent a property thats value is an IObservable<T>.
 * When supplied to the RapComponent it represents a value from the props that should
 * be managed via the component.
 */
export interface IObservableProperty<TProps> {
    /**
     * The name of the property that should be observed.
     */
    propertyName: keyof TProps;

    /**
     * The delegate function that will be called when the properties underlying observable
     * sends a notification.
     */
    observer: (value: any, action?: string) => void;

    /**
     * Optional value that defines a single action that should be observed. If omitted all
     * actions will be subscribed too.
     */
    action?: string;
}

/**
 * The IBoundObservable represents an IObservableProperty that has been bound to the property.
 */
interface IBoundObservable {
    /**
     * Instance of the observable property we are bound too.
     */
    observable: IObservable<any>;

    /**
     * The observing delegate used for this observable.
     */
    observer: (value: any, action?: string) => void;

    /**
     * The optional action this observer is bound too.
     */
    action?: string;
}

/**
 * This is an empty interface used to represent an object that contains a components
 * creation properties. It doesn't actaully define any values.
 */
export interface IRapComponentProperties {
    /**
     * Components may specify a css classe list that should be applied to the primary
     * element of the component when it is rendered.
     */
    className?: string;

    /**
     * Components MAY specify an order value which is a number > 0 which defines its
     * rendering order when multiple components target the same componentRegion. If the
     * order is NOT specified it defaults to Number.MAX_VALUE.
     */
    componentOrder?: number;

    /**
     * Key value for this component that MUST be set when the component is rendered
     * into a set of components.
     */
    key?: string | number;

    /**
     * Any of the properties MAY be accessed as an IObservable.
     */
    [property: string]: IObservable<any> | any;
}

/**
 * Global component registry for UI components used by the layout manager
 */
export const Components: IObservableObject<
    React.ComponentClass<IRapComponentProperties> | ((props?: any) => JSX.Element) | React.FC<any>
> = new ObservableObject<React.ComponentClass<IRapComponentProperties> | ((props?: any) => JSX.Element) | React.FC<any>>();

// We always use the context with an actual value, so setting an empty object as the default value here.
// (Note: {} is not actually a valid component context - pageContext is not optional - so casting here).
const RapComponentContext = React.createContext<IRapComponentContext>({} as IRapComponentContext);

export abstract class RapComponent<P extends IRapComponentProperties = IRapComponentProperties, S = {}, SS = {}> extends React.Component<P, S, SS> {
    public static uniqueNumber: number = 0;
    public static defaultProps = {};
    public static contextType = RapComponentContext;

    public static register(componentType: string, reactClass: React.ComponentClass<IRapComponentProperties> | ((props?: any) => JSX.Element)): void {
        Components.add(componentType, reactClass);
    }

    private observables?: { [propertyName: string]: IBoundObservable[] };
    private observers?: { [propertyName in keyof P]?: IObservableProperty<P>[] };
    private timers?: TimerManagement;
    private events?: EventManagement;
    private promises: ICancelablePromise<any>[];
    private unmountCallbacks: Array<{ (): void }> = [];

    protected componentElement: HTMLElement | null;
    protected componentId: number;

    public context!: React.ContextType<typeof RapComponentContext>;

    constructor(props: P, context?: IRapComponentContext) {
        super(props, context);

        // Save the context if one was supplied, this will allow the derived component
        // to use the context on the object during initialization.
        if (context) {
            this.context = context;
        }

        // Each component has a unique number for its componentId.
        this.componentId = ++RapComponent.uniqueNumber;

        // This is only for back compat, suppress react warning for this class
        (RapComponent.prototype.componentWillReceiveProps as any).__suppressDeprecationWarning = true;
    }

    /**
     * When components are being created the componentDidMount call is made when the
     * component has been mounted.
     *
     * Derived components that implement componentDidMount need to be sure to call
     * super.componentDidMount.
     */
    public componentDidMount(): void {}

    /**
     * When components are updated the componentDidUpdate call is made after the changes
     * have been made to the actual DOM. This is the opportunity for all components to
     * update any internal state.
     *
     * Derived components that implement componentDidUpdate need to be sure to call
     * super.componentDidUpdate.
     */
    public componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: SS): void {
        this._manageObservables(this.props);
    }

    /**
     * @deprecated This method is deprecated, use getDerivedStateFromProps instead.
     *
     * When components are being updated by their parent (containing) component a new
     * set of props is supplied. This gives us the opportunity to manage any state
     * from the old and new properties.
     *
     * Derived components that implement componentWillReceiveProps need to be sure to call
     * super.componentWillReceiveProps.
     *
     * @param newProps The new set of properties the component is receiving from the parent.
     */
    public componentWillReceiveProps(newProps: P): void {}

    /**
     * When components are unmounted resources they own are cleaned up.
     *
     * Derived components that implement componentWillUnmount need to be sure to call
     * super.componentWillUnmount.
     */
    public componentWillUnmount(): void {
        // Release any previously managed observables.
        this._manageObservables();

        // If any event listeners were attached to event targets throughout the components
        // lifetime, we will remove them when the component is unmounting.
        if (this.events) {
            this.events.removeAllListeners();
            delete this.events;
        }

        if (this.timers) {
            this.timers.clearAllTimers();
            delete this.timers;
        }

        if (this.promises) {
            for (const promise of this.promises) {
                promise.cancel();
            }
        }

        for (const unmountCallback of this.unmountCallbacks) {
            unmountCallback.call(this);
        }
    }

    /**
     * addEventListener provides a component level method for adding event listeners
     * to any EventTarget in the DOM and tracking them with the scope of this component.
     * This ensures that when the component is unmounted, any outstanding listeners
     * will be removed.
     *
     * @param target - The EventTarget to add the listener too.
     *
     * @param type - The event type the listener is signing up too.
     *
     * @param listener - The listener callback delegate to be called when the events occur.
     *
     * @param useCapture - Use capture mode for event phase.
     */
    protected addEventListener(target: EventTarget, type: string, listener: (event?: Event) => boolean | void, useCapture?: boolean): void {
        if (!this.events) {
            this.events = new EventManagement();
        }

        this.events.addEventListener(target, type, listener, useCapture);
    }

    /**
     * Registers a callback method that gets run during componentWillUnmount
     *
     * @param callback The callback method to execute on unmount
     */
    protected addUnmountCallback(callback: () => void) {
        this.unmountCallbacks.push(callback);
    }

    /**
     * removeEventListener provides a way to remove an event listener from any EventTarget
     * in the DOM and will remove the components tracking reference on this listener.
     *
     * @param target - The EventTarget to remove the listener from.
     *
     * @param type - The event type the listener was signed up too.
     *
     * @param listener - The listener callback delegate to remove.
     *
     * @param useCapture - Was the event using capture mode.
     */
    protected removeEventListener(target: EventTarget, type: string, listener: (event?: Event) => boolean | void, useCapture?: boolean): void {
        if (this.events) {
            this.events.removeEventListener(target, type, listener, useCapture);
        }
    }

    /**
     * clearInterval is used to stop the series of callbacks that was setup through setInterval.
     *
     * @param intervalId - The id returned from eh setInterval call that you want stopped.
     */
    protected clearInterval(intervalId: number): void {
        if (this.timers) {
            this.timers.clearInterval(intervalId);
        }
    }

    /**
     * clearTimeout is used to stop a timeout callback that was setup through setTimeout.
     *
     * @param timeoutId - The id returned from eh setTimeout call that you want stopped.
     */
    protected clearTimeout(timeoutId: number): void {
        if (this.timers) {
            this.timers.clearTimeout(timeoutId);
        }
    }

    /**
     * setInterval is used to setup a callback that is called on an interval.
     *
     * @param callback - The callback that should be called each interval time period.
     *
     * @param milliseconds - The number of milliseconds between each callback.
     *
     * @param args - Optional variable argument list passed to the callback.
     *
     * @returns - returns a handle to the interval, this can be used to cancel through clearInterval method.
     */
    protected setInterval(callback: (...args: any[]) => void, milliseconds: number, ...args: any[]): number {
        if (!this.timers) {
            this.timers = new TimerManagement();
        }

        return this.timers.setInterval(callback, milliseconds, ...args);
    }

    /**
     * setTimeout is used to setup a onetime callback that is called after the specified timeout.
     *
     * @param callback - The callback that should be called when the time period has elapsed.
     *
     * @param milliseconds - The number of milliseconds before the callback should be called.
     *  Even if a timeout of 0 is used the callback will be executed asynchronouly.
     *
     * @param args - Optional variable argument list passed to the callback.
     *
     * @returns - returns a handle to the timeout, this can be used to cancel through clearTimeout method.
     */
    protected setTimeout(callback: (...args: any[]) => void, milliseconds?: number, ...args: any[]): number {
        if (!this.timers) {
            this.timers = new TimerManagement();
        }

        return this.timers.setTimeout(callback, milliseconds, ...args);
    }

    /**
     * Tracks a promise so that it will be cancelled on component unmount. This prevents
     * the promise's callbacks from being invoked after the component has unmounted
     *
     * @param promise Promise to track
     */
    protected trackPromise<T = any>(promise: Promise<T>): ICancelablePromise<T> {
        if (!this.promises) {
            this.promises = [];
        }

        const cancelablePromise = makeCancelable<T>(promise);

        // Remove the tracked promise when it completes
        promise.then(
            () => {
                this.promises = this.promises.filter(tp => tp !== cancelablePromise);
            },
            () => {
                this.promises = this.promises.filter(tp => tp !== cancelablePromise);
            }
        );

        this.promises.push(cancelablePromise);
        return cancelablePromise;
    }

    /**
     * observe describes a value from the component properties that is an IObservable that needs
     * to be subscribed to. This will manage the subscriptions for these properties over the
     * lifecycle of the component.
     *
     * @param property - Name of the property that is an Observable
     *
     * @param observer - The delegate that is called when the Observable sends a matching notification.
     *
     * @param action - An optional action, which defines a single action from the observer that should
     *  be subscribed to. If omitted the subscription will be made to all actions.
     */
    protected observe(properties: IObservableProperty<P>[]): void {
        // Create a new set of observers, this call must have all of them.
        this.observers = {};

        // Only create the observables the first time we setup for observers. We can't wipe out this
        // since it holds our current observable state.
        if (!this.observables) {
            this.observables = {};
        }

        // Add the observed properties to the set of observed propeties.
        for (const property of properties) {
            if (!this.observers[property.propertyName]) {
                this.observers[property.propertyName] = [];
            }

            this.observers[property.propertyName]!.push(property);
        }

        // Update the state of our observables.
        this._manageObservables(this.props);
    }

    private _manageObservables(newProps?: IRapComponentProperties): void {
        if (this.observables) {
            // Clean up any existing observables, we will reattach the ones we need.
            for (const propertyName in this.observables) {
                for (const boundObservable of this.observables[propertyName]) {
                    boundObservable.observable.unsubscribe(boundObservable.observer, boundObservable.action);
                }

                delete this.observables[propertyName];
            }

            // If any observable properties were supplied we will subscribe to them.
            if (newProps && this.observers) {
                for (const propertyName in this.observers) {
                    const observable = newProps[propertyName] as IObservable<any>;

                    // Ensure this is a valid IObservable before we subscribe too it.
                    if (observable && observable.subscribe) {
                        for (const observer of this.observers[propertyName]!) {
                            observable.subscribe(observer.observer, observer.action);

                            if (!this.observables[propertyName]) {
                                this.observables[propertyName] = [];
                            }

                            this.observables[propertyName].push({ observable: observable, observer: observer.observer, action: observer.action });
                        }
                    }
                }
            }
        }
    }
}

/**
 * When creating a new React rendering tree using ReactDOM.render the caller should
 * use a ReactRootComponent and supply the required properties. This will ensure the
 * React component tree has the appropriate context setup.
 */
export interface IReactRootComponentProperties {
    pageContext: IRapPageContext;
    children?: React.ReactNode;
}

/**
 * A ReactRootComponent is used to house the root of a React tree, this should be used anytime
 * ReactDOM.render is used to encapsulate the needed state management of the tree.
 *
 * This component MUST only have a single child, and it will not add any DOM to the produced
 * structure.
 */
export class ReactRootComponent extends RapComponent<IReactRootComponentProperties, {}> {
    public render(): React.ReactNode {
        const root = React.createElement(
            RapComponentContext.Provider,
            {
                value: {
                    pageContext: this.props.pageContext
                }
            },
            this.props.children
        );

        return root;
    }
}