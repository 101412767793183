import React from "react";
import { connect } from "react-redux";

import { RapComponent, IRapComponentProperties } from "../../../../platform/Layout";
import { AppointmentStages, IAccordionState, ICustomResponse, ITypeObject } from "../../Contracts";

import { IDashboardViewState } from "../../../../pages/Dashboard/Contracts";
import { AppointmentsActions } from "../../redux/AppointmentsActions";
import { AppointmentsFeature } from "../../../../common/Constants";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { timeoutFunction } from "../../AppointmentsHelper";
import { IAppointment, IAppointmentForCreationDto, IAppointmentServiceType, IAppointmentTopic, AppointmentTopicCustomQuestion, AppointmentForCreationDto, StoreRedirect } from "../../../../contracts/swagger/_generated";

import AppointmentType from "../AppointmentType/AppointmentType";
import AppointmentTopic from "../AppointmentTopic/AppointmentTopic";
import AppointmentDateTime from "../AppointmentDateTime/AppointmentDateTime";
import AppointmentPersonalInfo from "../AppointmentPersonalInfo/AppointmentPersonalInfo";
import AppointmentConfirmation from "../AppointmentConfirmation/AppointmentConfirmation";
import AppointmentSummary from "../AppointmentSummary/AppointmentSummary";

import * as AppointmentSelectors from "../../redux/AppointmentsSelectors";
import * as Constants from "./../../../../common/Constants";

import "./AppointmentScheduler.scss";
import { announce } from "../../../../platform/core/util/Accessibility";


interface IAppointmentSchedulerState {
    didPageActionFire: boolean;
    confirmationError?: boolean;
}

//Props passed by parent component
interface IAppointmentSchedulerProvidedProps extends IRapComponentProperties {
    //TODO: Remove once we add appointment in progress to redux state
    serviceId?: string;
    topicId?: string;
}

//Props mapped from state object
interface IAppointmentSchedulerInitializerOwnProps extends IAppointmentSchedulerProvidedProps {
    createdAppointment: IAppointment; 
    serviceTypes: IAppointmentServiceType[];
    serviceTopics: IAppointmentTopic[]; 

    stage: AppointmentStages;
    appointment: IAppointmentForCreationDto;
    accordions: IAccordionState;
}

// eslint-disable-next-line
export type IAppointmentSchedulerInitializerProps = IAppointmentSchedulerInitializerOwnProps & typeof ActionsToDispatch;

class AppointmentSchedulerInitializer extends RapComponent<IAppointmentSchedulerInitializerProps, IAppointmentSchedulerState> {
    pageHeader: React.RefObject<HTMLDivElement>;

    constructor(props: IAppointmentSchedulerInitializerProps) {
        super(props);
        this.props.logTelemetry(AppointmentsFeature.AppointmentScheduler, "Initializing appointment scheduler", undefined);
        this.state = { didPageActionFire: false, confirmationError: false }
        this.props.logOneDSPageView();
        this.props.logOneDSAction({
            pageName: Constants.BookAppointmentScenario,
            behavior: Constants.StartProcessBehavior,
            contentTags: {
                scn: Constants.BookAppointmentScenario,
                scnstp: Constants.BookStartStep,
                stpnum: "1"
            }
        });
        this.setState({ didPageActionFire: true });
        this.props.logTelemetry(AppointmentsFeature.AppointmentScheduler, "Appointment scheduler initialized", this.props.validatedStoreId, this.state);
        
        this.pageHeader = React.createRef();
    }

    componentDidUpdate(prevProps: IAppointmentSchedulerInitializerProps) {
        if(this.props.createdAppointment && this.props.stage !== AppointmentStages.CONFIRM) {
            this.props.updateAppointmentStage(AppointmentStages.CONFIRM);
        }

        // when scheduler page loads, the accordion will set the correct focus but
        // when review or confirm page loads, set keyboard focus to the top of the page and announce the page title
        if (prevProps.stage !== this.props.stage && (this.props.stage === AppointmentStages.CONFIRM || this.props.stage === AppointmentStages.REVIEW)) {
            this.pageHeader.current?.focus(); 
            announce(this.pageHeader.current?.textContent ? this.pageHeader.current.textContent : "", true);
        }
    }

    public componentDidMount(): void {
        this.setInitialStage();
    }

    private setInitialStage = () => {
        let stage = this.props.stage;
        if(this.props.serviceId && this.props.topicId) {
            this.props.updateAppointment(
                AppointmentStages.DATE_TIME, 
                {
                    ...this.props.appointment, 
                    appointmentTypeId: this.props.serviceId, 
                    appointmentCategoryId: this.props.topicId 
                }
            );
            stage = AppointmentStages.DATE_TIME;
        }
        else if(this.props.serviceId) {
            this.props.updateAppointment(
                AppointmentStages.TOPIC,
                {
                    ...this.props.appointment, 
                    appointmentTypeId: this.props.serviceId 
                }
            );
            stage = AppointmentStages.TOPIC;
        }
        else if(this.props.topicId) {
            this.props.updateAppointment(
                AppointmentStages.TYPE,
                {
                    ...this.props.appointment, 
                    appointmentCategoryId: this.props.topicId 
                }
            );
            stage = AppointmentStages.TYPE;
        }

        switch(stage) {
            case AppointmentStages.TYPE: {
                timeoutFunction(
                        () => {
                        if(this.props.serviceTypes) {return true}
                        return false;
                    }, 
                    () => this.toggleDropdown(AppointmentStages.TYPE, true), 
                    200, 
                    200, 
                    30,
                    localizedStrings.AppointmentScheduler?.unableToLoadTypes as string
                );
                break;
            }
            case AppointmentStages.TOPIC: {
                timeoutFunction(
                        () => {
                        if(this.props.serviceTopics) {return true}
                        return false;
                    }, 
                    () => this.toggleDropdown(AppointmentStages.TOPIC, true), 
                    200, 
                    200, 
                    30,
                    localizedStrings.AppointmentScheduler?.unableToLoadTopics as string
                );
                break;
            }
            case AppointmentStages.DATE_TIME: {
                this.toggleDropdown(AppointmentStages.DATE_TIME, true);
                break;
            }
        }
        this.props.updateAppointmentStage(stage);
    }

    private getHeader() {
        if (this.props.stage === AppointmentStages.CONFIRM) {
            return (
                <h1 className="c-scheduler-header" ref={this.pageHeader} tabIndex={0}>
                    {this.state.confirmationError ? localizedStrings.AppointmentScheduler?.error : localizedStrings.AppointmentScheduler?.youreAllSet}
                </h1>
            )
        } else if (this.props.stage === AppointmentStages.REVIEW){
            return <h1 className="c-scheduler-header" ref={this.pageHeader} tabIndex={0}>{localizedStrings.Review?.title}</h1>;
        }else {
            return <h1 className="c-scheduler-header" ref={this.pageHeader} tabIndex={0}>{localizedStrings.AppointmentScheduler?.title}</h1>;
        }
    }

    private toggleDropdown = (stage: AppointmentStages, isOpen?: boolean) => {
        switch(stage) {
            case AppointmentStages.TYPE: {
                this.props.updateAccordions(
                {
                    ...this.props.accordions, 
                    isTypeOpen: isOpen != undefined ? isOpen : !this.props.accordions.isTypeOpen
                });
                break;
            }
            case AppointmentStages.TOPIC: {
                this.props.updateAccordions(
                {
                    ...this.props.accordions,
                    isTopicOpen: isOpen != undefined ? isOpen : !this.props.accordions.isTopicOpen
                });
                break;
            }
            case AppointmentStages.DATE_TIME: {
                this.props.updateAccordions(
                {
                    ...this.props.accordions,
                    isDateOpen: isOpen != undefined ? isOpen : !this.props.accordions.isDateOpen
                });
                break;
            }
            case AppointmentStages.INFO: {
                this.props.updateAccordions(
                {
                    ...this.props.accordions,
                    isInfoOpen: isOpen != undefined ? isOpen : !this.props.accordions.isInfoOpen
                });
                break;
            }
        }
    }

    public render() {
        return (
            <div className="c-schedule">
                <div className="c-scheduler">
                    <div>{this.getHeader()}</div>
                    <div className="c-scheduler-container">
                        <div className={"c-form-container"} 
                            style={{display: this.props.stage != AppointmentStages.REVIEW && this.props.stage != AppointmentStages.CONFIRM ? "block" : "none"}}
                        >
                            <hr/>
                            <AppointmentType/>
                            <hr />
                            <AppointmentTopic/>
                            <hr />
                            <AppointmentDateTime flow={"Schedule"}
                            />
                            <hr />
                            <AppointmentPersonalInfo/>
                            <hr />
                        </div>
                        {this.props.stage !== AppointmentStages.CONFIRM && (
                            <AppointmentSummary/>
                        )}
                        {this.props.stage === AppointmentStages.CONFIRM && (
                            <AppointmentConfirmation confirmationNumber={this.props.createdAppointment.confirmationNumber as string} showImage/>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IAppointmentSchedulerProvidedProps): Partial<IAppointmentSchedulerInitializerOwnProps> {
    return {
        ...providedProps,
        createdAppointment: AppointmentSelectors.getCreatedAppointment(state),
        serviceTypes: AppointmentSelectors.getAppointmentServiceTypes(state),
        serviceTopics: AppointmentSelectors.getAppointmentServiceTopics(state),
        stage: AppointmentSelectors.getSchedulerStage(state),
        appointment: AppointmentSelectors.getSchedulerAppointment(state),
        accordions: AppointmentSelectors.getSchedulerAccordions(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: AppointmentsActions.logTelemetry,
    logOneDSPageView: AppointmentsActions.logOneDSPageView,
    logOneDSAction: AppointmentsActions.logOneDSAction,
    updateAppointmentStage: AppointmentsActions.updateSchedulerStage,
    updateAppointment: AppointmentsActions.updateSchedulerAppointment,
    updateAccordions: AppointmentsActions.updateSchedulerAccordions
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentSchedulerInitializer);
