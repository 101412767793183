import React, { FC, useEffect, useRef } from "react";
import { Icon } from "@fluentui/react/lib/Icon";

import "./Accordion.scss";

export interface IAccordionProps {
    title: string | undefined;
    disabled?: boolean;
    label?: string;
    isOpen?: boolean;
    onClick(): void;
    isValid?: boolean;
    children?: React.ReactNode;
}

export const Accordion: FC<IAccordionProps> = props => {
    const accordionHeaderRef: React.RefObject<HTMLDivElement> = React.createRef();
    let isFocused: boolean = false;

    // give keyboard focus to the accordion header when it's opened for the first time
    useEffect(() => {
        if (props.isOpen === true && isFocused === false) {
            accordionHeaderRef.current?.focus();
        }
    }, [props.isOpen]);

    const toggleIsFocused = () => {
        isFocused = !isFocused;
    }

    const onClick = () => {
        if(!props.disabled) {
            props.onClick();
        }    
    }

    const onKeyPress = (e: React.KeyboardEvent) => {
        const enterOrSpace =
            e.key === "Enter" ||
            e.key === " " ||
            e.key === "Spacebar"
        if (enterOrSpace && !props.disabled) {
            e.preventDefault();
            onClick();
        }
    };

    return (
        <div className="c-accordion">
            <div 
                ref={accordionHeaderRef}
                onClick={onClick}
                onFocus={toggleIsFocused}
                onBlur={toggleIsFocused}
                onKeyPress={e => onKeyPress(e)}
                role="button"
                aria-expanded={props.isOpen}
                tabIndex={0} 
                aria-label={props.label ? props.label : props.title}
                data-bi-name={props.label ? props.label : props.title}
                className={`c-accordion-header ${props.disabled ? "disabled" : ""}`}
            >
                <Icon iconName="ChevronRight" className={`c-icon ${props.isOpen ? "open" : ""}`}/>
                <h5>{props.title}</h5>
                {props.isValid && (
                    <Icon iconName="CheckMark" className={`c-icon-check`}/>
                )}
            </div>
            <div 
                className={`c-accordion-item ${props.isOpen ? "open" : ""}`} 
                style={{ height: "fit-content" }}> 
                    {props.children}
            </div>
        </div>
    );
};