import { IAppointmentsAwareState } from "../../views/AppointmentsView/Contracts";
import { IPageAwareState } from "../../views/Page/Contracts";

export interface IDashboardViewState extends IAppointmentsAwareState, IPageAwareState {}

export class Area {
    public static Dashboard = "Dashboard";
}

export class TelemetryProperties {
    public static Action = "Action";
    public static ErrorMessage = "ErrorMessage";
}
