import { ITypeObjectDetails } from "./Contracts";
import * as Constants from "../../common/Constants";
import { localizedStrings } from "../../common/localization/LocalizedStrings";

import sidebarImage from "../../styling/images/sidebar@2x.png";
import sidebarBusinessImage from "../../styling/images/sidebar-business@2x.png";
import sidebarMecImage from "../../styling/images/sidebar-MEC@2x.png";
import confirmImage from "../../styling/images/confirm-group@2x.png";
import confirmBusinessImage from "../../styling/images/confirm-business-group@2x.png";

import BusinessIcon from "../../styling/images/business-consult-icon.svg";
import EducationIcon from "../../styling/images/education-consult-icon.svg";
import MecIcon from "../../styling/images/MEC-icon.svg";
import SetupIcon from "../../styling/images/setup-icon.svg";
import TrainingIcon from "../../styling/images/training-icon.svg";

export const getServiceNameCategory = (serviceName: string | undefined) => {
    var serviceNameLower = serviceName?.toLowerCase();

    if(serviceNameLower?.includes("setup") && serviceNameLower?.includes("training")){
        return Constants.PersonalSetupTrainingAppointmentTypes;
    }
    else if(serviceNameLower?.includes("hardware support")) {
        return Constants.HardwareAppointmentTypes;
    }
    else if(serviceNameLower?.includes("business consultation")) {
        return Constants.BusinessAppointmentTypes;
    }
    else if(serviceNameLower?.includes("education consultation")) {
        return Constants.EducationAppointmentTypes;
    }
    else if(serviceNameLower?.includes("setup") || serviceNameLower?.includes("set-up")) {
        return Constants.PersonalSetupAppointmentTypes;
    }
    else if(serviceNameLower?.includes("personal shopping")) {
        return Constants.PersonalAppointmentTypes;
    }
    else if(serviceNameLower?.includes("training") && serviceNameLower.includes("business")) {
        return Constants.BusinessTrainingAppointmentTypes;
    }
    else if(serviceNameLower?.includes("training")) {
        return Constants.PersonalTrainingAppointmentTypes;
    }
    else if(serviceNameLower?.includes("lenovo")) {
        return Constants.LenovoAppointmentTypes;
    }
    else {
        return "Error";
    }
}

export const getTypeObjectDetails = (serviceName: string) => {
    var serviceNameCategory = getServiceNameCategory(serviceName);

    switch (serviceNameCategory) {
        case Constants.PersonalSetupTrainingAppointmentTypes: 
            return {
                label: localizedStrings.TypeTopic?.typeLabels?.personalSetupTraining,
                iconName: "Devices2",
                buttonLabel: localizedStrings.TypeTopic?.buttonLabels?.personalSetupTraining,
                friendlyLabel: localizedStrings.TypeTopic?.friendlyLabels?.personalSetupTraining
            } as ITypeObjectDetails;
        case Constants.HardwareAppointmentTypes:
            return {
                label: localizedStrings.TypeTopic?.typeLabels?.hardwareSupport,
                svg: MecIcon,
                buttonLabel: localizedStrings.TypeTopic?.buttonLabels?.hardwareSupport,
                friendlyLabel: localizedStrings.TypeTopic?.friendlyLabels?.hardwareSupport
            } as ITypeObjectDetails;
        case Constants.BusinessAppointmentTypes:
            return {
                label: localizedStrings.TypeTopic?.typeLabels?.businessConsultation,
                svg: BusinessIcon,
                buttonLabel: localizedStrings.TypeTopic?.buttonLabels?.businessConsultation,
                friendlyLabel: localizedStrings.TypeTopic?.friendlyLabels?.businessConsultation
            } as ITypeObjectDetails;
        case Constants.EducationAppointmentTypes:
            return {
                label: localizedStrings.TypeTopic?.typeLabels?.educationConsultation,
                svg: EducationIcon,
                buttonLabel: localizedStrings.TypeTopic?.buttonLabels?.educationConsultation,
                friendlyLabel: localizedStrings.TypeTopic?.friendlyLabels?.educationConsultation
            } as ITypeObjectDetails;
            case Constants.LenovoAppointmentTypes:
                return {
                    label: localizedStrings.TypeTopic?.typeLabels?.lenovo,
                    iconName: "System",
                    buttonLabel: localizedStrings.TypeTopic?.buttonLabels?.lenovo,
                    friendlyLabel: localizedStrings.TypeTopic?.friendlyLabels?.lenovo
                } as ITypeObjectDetails;
        case Constants.PersonalSetupAppointmentTypes:
            return {
                label: localizedStrings.TypeTopic?.typeLabels?.personalSetup,
                svg: SetupIcon,
                buttonLabel: localizedStrings.TypeTopic?.buttonLabels?.personalSetup,
                friendlyLabel: localizedStrings.TypeTopic?.friendlyLabels?.personalSetup
            } as ITypeObjectDetails;
        case Constants.PersonalAppointmentTypes:
            return {
                label: localizedStrings.TypeTopic?.typeLabels?.personalShopping,
                iconName: "Shop",
                buttonLabel: localizedStrings.TypeTopic?.buttonLabels?.personalShopping,
                friendlyLabel: localizedStrings.TypeTopic?.friendlyLabels?.personalShopping
            } as ITypeObjectDetails;
        case Constants.PersonalTrainingAppointmentTypes:
            return {
                label: localizedStrings.TypeTopic?.typeLabels?.personalTraining,
                svg: TrainingIcon,
                buttonLabel: localizedStrings.TypeTopic?.buttonLabels?.personalTraining,
                friendlyLabel: localizedStrings.TypeTopic?.friendlyLabels?.personalTraining
            } as ITypeObjectDetails;
        case Constants.BusinessTrainingAppointmentTypes:
            return {
                label: localizedStrings.TypeTopic?.typeLabels?.personalTrainingBusiness,
                svg: TrainingIcon,
                buttonLabel: localizedStrings.TypeTopic?.buttonLabels?.personalTrainingBusiness,
                friendlyLabel: localizedStrings.TypeTopic?.friendlyLabels?.personalTrainingBusiness
            } as ITypeObjectDetails;
        default:
            return {
                label: "Error",
                iconName: "Error",
                buttonLabel: "Oh no"
            } as ITypeObjectDetails;
    }
}

export const isValidGuid = (guid: string) => {
    if(!guid) return false;
    return Constants.guidRegex.test(guid);
}

export const isValidStoreId = (id: string) => {
    if(!id) return false;
    return Constants.storeIdRegex.test(id);
}

export const timeoutFunction = (condition: () => boolean, execute: () => void, initialWait: number, retryWait: number, retries: number, errorMessage: string) => {
    setTimeout(() => {
        if(condition()) {
            execute();
        } else if(retries > 0) {
            timeoutFunction(condition, execute, retryWait, retryWait, retries-1, errorMessage);
        }
        else {
            console.error(errorMessage);
        }
    }, initialWait);
}

export const formatCustomerPhone = (customerPhone: string) => {
    let matches;
    switch(localizedStrings.getLanguage()) {
        case Constants.UnitedKingdomLocale:
            matches = customerPhone.match(Constants.UnitedKingdomPhoneRegex);
            if(matches) {
                return `${matches[1]} ${matches[2]} ${matches[3]}`;
            }
            break;
        case Constants.AustraliaLocale:
            matches = customerPhone.match(Constants.AustraliaPhoneRegex);
            if(matches) {
                return `${matches[1]} ${matches[2]} ${matches[3]} ${matches[4]}`;
            }
            break;
        default:
            matches = customerPhone.match(Constants.NAPhoneRegex);
            if(matches) {
                return `${matches[1]} (${matches[2]}) ${matches[3]}-${matches[4]}`;
            }
            break;
    }
    return "";
}

export const getSidebarImage = (storeNumber: number): { image: string, altText: string } => {
    //for some reason it was casting storeNumber to a string when i tried this with a switch so just gonna use the if blocks for now
    //the same issue happens when using '===' so we are keeping it as '=='
    if (storeNumber == 9987 || storeNumber == 9990) {      
        return { image: sidebarImage, altText: localizedStrings.AppointmentScheduler?.sidebarAltText as string };
    }
    else if (storeNumber == 9988 || storeNumber == 9989 || storeNumber == 9983 || storeNumber == 9993) {
        return { image: sidebarBusinessImage, altText: localizedStrings.AppointmentScheduler?.sidebarBusinessAltText as string };
    }
    else if(isStoreMec(storeNumber)) {
        return { image: sidebarMecImage, altText: localizedStrings.AppointmentScheduler?.sidebarMecAltText as string };
    }
    else {
        return { image: sidebarImage, altText: localizedStrings.AppointmentScheduler?.sidebarAltText as string };
    }
}

export const getConfirmationImage = (storeNumber: number): { image: string, altText: string } => {
    //for some reason it was casting storeNumber to a string when i tried this with a switch so just gonna use the if blocks for now
    //the same issue happens when using '===' so we are keeping it as '=='
    if(storeNumber == 9988 || storeNumber == 9989 || storeNumber == 9983) {
        return { image: confirmBusinessImage, altText: localizedStrings.Confirmation?.confirmImageAltText as string };
    }
    else {
        return { image: confirmImage, altText: localizedStrings.Confirmation?.confirmImageAltText as string};
    }
}

export const getRedirectUrl = (validatedStoreId: number | undefined, appointmentType: string | undefined) => {
    //for some reason validatedStoreId is getting cast to a string so using '==' for comparison
    if(validatedStoreId == 85) {
        return "https://www.microsoft.com/en-us/store/locations/answer-desk?icid=mec_ny_spotlight1-ad";
    }
    else if(validatedStoreId == 120) {
        return "https://www.microsoft.com/en-gb/store/locations/answer-desk?icid=sdp_answer-desk";
    }
    else if(validatedStoreId == 136) {
        return "https://www.microsoft.com/en-au/store/locations/answer-desk?icid=mec_au_spotlight1-ad";
    }
    else {
        let appointmentTypeName = appointmentType ? appointmentType : "";
        let url = Constants.BaseUrl + localizedStrings.getLanguage();
        return url.concat((appointmentTypeName && (appointmentTypeName.toLowerCase().includes(Constants.Business) 
        || appointmentTypeName.toLowerCase().includes(Constants.Education))) ? Constants.BusinessUrlExt : Constants.PersonalUrlExt);
    }
}

export const getLearnAboutAppointmentsLabel = (validatedStoreId: number | undefined): string => {
    if(isStoreMec(validatedStoreId)) {
        return localizedStrings.Cancellation?.learnAboutAppointments as string;
    }
    return  localizedStrings.Cancellation?.learnAboutAppointmentsOnline as string;
}

export const isStoreMec = (validatedStoreId: number | undefined): boolean => {
    //for some reason validatedStoreId is getting cast to a string so using '==' for comparison
    return (validatedStoreId == 85 || validatedStoreId == 120 || validatedStoreId == 136);
}