import { Page } from "./../../contracts/Page";
import { FeatureFlag } from "./../../contracts/FeatureFlag";

export interface IPageAwareState {
    page: IPageViewState;
    features: IFeatureManagementState;
}

export interface IPageViewState {
    sideDrawerOpen: boolean;
    settingsMenuItems: NavMenuItem[];
    settingsMenuOpen: boolean;
    navMenuItems: NavMenuItem[];
    selectedNavMenuItemId: number;
    pageData: Page;
    errorMessage: string;
    userRole: UserRole;
    displayAdminSettingsPanel: boolean;
    displayUserSettingsPanel: boolean;
    isMaintenanceMessageBarOpen: boolean;
}

export interface NavMenuItem {
    id: number;
    title: string;
    iconName: string;
    minimumRequiredUserRole: UserRole;
}

//Note: user roles should be listed in ascending order of precedence: ie if you have level 3 you get access to everything below that level as well.
export enum UserRole {
    None = 0,
    Member = 1,
    Owner = 2,
    Admin = 3
}

export interface IFeatureManagementState {
    featureFlags: FeatureFlag[];
}
