import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import queryString from 'query-string';

import ManagerGateway from "../../../../views/AppointmentsView/components/Gateways/ManagerGateway/ManagerGateway";
import SchedulerGateway from "../../../../views/AppointmentsView/components/Gateways/SchedulerGateway/SchedulerGateway";
import * as Constants from "../../../../common/Constants";

import PageError from "./../PageError/PageError";
import Page from "./../Page/Page";
import createStore from "../../../redux/CreateStore";

import { IPageDefinition } from "./../Page/PageDefinitionTypes";
import { RapComponent } from "../../../Layout";
import { makeFullUrl } from "../../../../routing";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";

import "./Shell.scss";

type RouteParams = {
    locale: string;
};

class Shell extends RapComponent {
    public render() {
        return (
            <BrowserRouter>
                <div id="page">
                    <Provider store={createStore(this.context.pageContext)}>
                        <div id="main-content" className="main-content" role="main">
                            <Switch>
                                {this.createRoute(
                                    {
                                        component: SchedulerGateway,
                                        id: "AppointmentScheduler",
                                        group: undefined,
                                        name:
                                            localizedStrings.AppointmentScheduler?.name ? localizedStrings.AppointmentScheduler.name : "Schedule an appointment",
                                        url: "appointmentscheduler"
                                    },
                                    "appointmentscheduler",
                                    "/:locale?/appointment/create/"
                                )}
                                {this.createRoute(
                                    {
                                        component: ManagerGateway,
                                        id: "manage",
                                        group: undefined,
                                        name:
                                            localizedStrings.AppointmentManager?.name ? localizedStrings.AppointmentManager.name : "Manage appointments",
                                        url: "manage"
                                    },
                                    "manage",
                                    "/:locale?/appointment/change/"
                                )}
                                {
                                    // Create a redirect for the empty/root path
                                    <Route path="/:locale/" key="emptyWithLocale" render={this.renderEmptyWithLocale} exact />
                                }
                                {
                                    <Route path="/" key="empty" render={this.renderEmpty} exact />
                                }
                                {
                                    // Create a fallback route for the "page not found" scenario
                                    <Route render={this.renderFallback} key="fallback" />
                                }
                            </Switch>
                        </div>
                    </Provider>
                </div>
            </BrowserRouter>
        );
    }

    private createRoute(page: IPageDefinition, key: number | string, routeOverride?: string): JSX.Element {
        const renderPage = (props: RouteComponentProps<RouteParams>) => {
            // If the storeid parameter is set to one of the de-activated stores (9994, 9995, 9997),
            // re-route the user to the new store (1111) that includes all of the above
            // Do not delete this block as it could break links that customers have bookmarked
            let currentQueryParams = new URLSearchParams(props.location.search);
            const storeidParam = currentQueryParams.get("storeid");
            if (storeidParam && Constants.DeprecatedOrganizationalUnits.includes(storeidParam.trim())) {
                currentQueryParams.set("storeid", Constants.NewOrganizationalUnitId);
                return <Redirect to={`${props.location.pathname}?${currentQueryParams.toString()}`} />;
            }

            // Check to see if the path contains a supported locale
            if (!localizedStrings.getAvailableLanguages().includes(props.match.params.locale)) {
                return <Redirect to={`/${this.getLocale()}${props.location.pathname}${props.location.search}`} />;
            }

            // If the url contains a locale different from the interface locale (which is inferred by react-localization)
            // then we need to set the language to match it
            const interfaceLocale = this.getLocale();
            if (interfaceLocale !== props.match.params.locale) {
                localizedStrings.setLanguage(props.match.params.locale);
            }
            document.title = page.name;
            return <Page page={page} key={key} routeOverride={routeOverride} params={queryString.parse(props.location.search.toLowerCase())} />;
        };

        return <Route render={renderPage} exact={true} key={key} path={routeOverride || makeFullUrl(page.url)} />;
    }

    private renderEmptyWithLocale = (props: RouteComponentProps<RouteParams>): JSX.Element => {
        // Check to see if the path contains a supported locale
        if (!localizedStrings.getAvailableLanguages().includes(props.match.params.locale)) {
            return <PageError title="Page not found" details={`Invalid path: ${props.location.pathname}`} />;
        }

        return <Redirect to={`/${props.match.params.locale}/appointment/create/`} />;
    }

    private renderEmpty = (): JSX.Element => {
        return <Redirect to={`/${this.getLocale()}`} />;
    }

    private renderFallback = (props: RouteComponentProps<RouteParams>): JSX.Element => {
        return <PageError title="Page not found" details={`Invalid path: ${props.location.pathname}`} />;
    };

    private getLocale(): string {
        let interfaceLocale = localizedStrings.getInterfaceLanguage().toLowerCase();
        interfaceLocale = localizedStrings.getAvailableLanguages().includes(interfaceLocale) ? interfaceLocale : Constants.DefaultLocale;
        localizedStrings.setLanguage(interfaceLocale);
        return interfaceLocale;
    }
}

export default Shell;
