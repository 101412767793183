import { Checkbox, ChoiceGroup, Dropdown, IChoiceGroupOption, IDropdownOption, Label, Stack, TextField } from "@fluentui/react";
import React, { FC, FormEvent } from "react";
import { AppointmentTopicCustomQuestion, CustomQuestionType } from "../../../contracts/swagger/_generated";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";
import ReactHtmlParser from 'html-react-parser';
import "./CustomQuestion.scss";
import * as Constants from "../../../common/Constants";

export interface ICustomQuestionProps {
    question: AppointmentTopicCustomQuestion;
    className: string;
    onChange: (question: AppointmentTopicCustomQuestion, newValue?: any, checked?: boolean) => void;
    onBlur: () => void;
    currentValue: any;
    disabled?: boolean;
}

export const CustomQuestion: FC<ICustomQuestionProps> = props => {

    const getChoiceGroupOptions = () => {
        let options: IChoiceGroupOption[] = [];
        props.question.choices?.forEach((choice, index) => options.push({
            key: index.toString(),
            text: choice,
            disabled: props.disabled,
            tabIndex: 0,
            onRenderLabel: () => 
                <div className="ms-ChoiceFieldLabel c-option-text">
                    { ReactHtmlParser(choice) }
                </div>
        }));

        return options;
    }

    const getCurrentValueKey = () => {
        return props.question.choices?.indexOf(props.currentValue).toString();
    }

    const getContent = () => {
        switch (props.question.questionType) {
            case CustomQuestionType.SimpleText:
                return (
                    <TextField
                        label={props.question.questionText}
                        value={props.currentValue}
                        maxLength={Constants.SingleLineTextFieldCharacterLimit}
                        validateOnLoad={false}
                        validateOnFocusOut
                        onGetErrorMessage={(value: string): string => {
                            return props.question.isRequired && (!value || value.length === 0) && localizedStrings.PersonalInfo?.requiredFieldError ? localizedStrings.PersonalInfo.requiredFieldError : '';
                        }}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                            props.onChange(props.question, newValue)
                        }
                        onBlur={props.onBlur}
                        required={props.question.isRequired}
                        aria-required={props.question.isRequired}
                        className={props.className}
                        disabled={props.disabled}
                    />
                );
            case CustomQuestionType.Boolean: 
                let options: IChoiceGroupOption[] = [
                    {key: "true", text: localizedStrings.PersonalInfo?.true ? localizedStrings.PersonalInfo?.true : "True", disabled: props.disabled}, 
                    {key: "false", text: localizedStrings.PersonalInfo?.false ? localizedStrings.PersonalInfo?.false : "False", disabled: props.disabled}
                ];
                return (      
                    <ChoiceGroup 
                        label={props.question.questionText}
                        options={options} 
                        onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) =>
                            props.onChange(props.question, option?.text)
                        } 
                        onBlur={props.onBlur}
                        required={props.question.isRequired}
                        className={props.className}
                    />
                );
            case CustomQuestionType.MultipleChoice: 
                const stackTokens = { childrenGap: 10 };
                return (
                    <Stack tokens={stackTokens} className={props.className} onBlur={props.onBlur}>
                        <Label required={props.question.isRequired}>
                            {props.question.questionText}
                        </Label>
                        {
                            props.question.choices?.map((choice) => 
                                <Checkbox 
                                    key={choice}
                                    onRenderLabel={ () => 
                                        <div className="ms-Checkbox-text c-option-text">
                                            { ReactHtmlParser(choice) }
                                        </div>}
                                    name={props.question.customQuestionId}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => 
                                        props.onChange(props.question, choice, checked)
                                    }
                                    disabled={props.disabled}
                                />
                            )
                        }
                    </Stack>
                ); 
            case CustomQuestionType.SingleChoice: 
                return (
                    <ChoiceGroup
                        label={props.question.questionText}
                        options={getChoiceGroupOptions()} 
                        onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) =>
                            props.onChange(props.question, option?.text)
                        } 
                        onBlur={props.onBlur}
                        required={props.question.isRequired}
                        defaultSelectedKey={getCurrentValueKey()}
                        className={props.className}
                    />
                );
            case CustomQuestionType.MultilineText:
                return (
                    <TextField 
                        label={props.question.questionText} 
                        value={props.currentValue}
                        maxLength={Constants.MultilineTextFieldCharacterLimit}
                        validateOnLoad={false}
                        validateOnFocusOut
                        onGetErrorMessage={(value: string): string => {
                            return props.question.isRequired && (!value || value.length === 0) && localizedStrings.PersonalInfo?.requiredFieldError ? localizedStrings.PersonalInfo.requiredFieldError : '';
                        }}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                            props.onChange(props.question, newValue)
                        }
                        onBlur={props.onBlur}
                        multiline 
                        autoAdjustHeight
                        required={props.question.isRequired}
                        aria-required={props.question.isRequired}
                        className={props.className}
                        disabled={props.disabled}
                    />
                );  
            case CustomQuestionType.DropdownSingleChoice:
                let dropdownOptions: IDropdownOption[] = [];
                props.question.choices?.forEach((choice, index) => dropdownOptions.push({
                    key: index.toString(),
                    text: choice
                }));

                return (
                    <Dropdown 
                        label={props.question.questionText} 
                        placeholder={localizedStrings.PersonalInfo?.select}
                        options={dropdownOptions} 
                        onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IDropdownOption | undefined) =>
                            props.onChange(props.question, option?.text)
                        } 
                        onBlur={props.onBlur}
                        required={props.question.isRequired}
                        aria-required={props.question.isRequired}
                        className={props.className}
                        disabled={props.disabled}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <div className="c-custom-question" id={props.question.customQuestionId}>
            {getContent()}
        </div>
    );
};
