import React, { FC } from "react";
import { Text } from "@fluentui/react";

import "./MaintenanceScreen.scss";
import { localizedStrings } from "../../localization/LocalizedStrings";

export interface IMaintenanceScreenProps {

}

export const MaintenanceScreen: FC<IMaintenanceScreenProps> = (props) => {
    return (
        <div className="c-maintenanceContainer">
            <Text variant={"xLarge"}>{localizedStrings.Maintenance?.message}</Text>
        </div>
    )
}