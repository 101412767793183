import React from "react";
import { render } from "react-dom";
import { unregister } from "./registerServiceWorker";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { App } from "./App";
import { loadTheme } from "@fluentui/react";
import "./styling/_base.scss";

initializeIcons(/* optional base url */);

//Initialize Fabric UI Theme
if (document.body.classList.contains("theme-default")) {
    loadTheme({
        palette: {
            themePrimary: "#0067b8",
            themeLighterAlt: '#eff6fc',
            themeLighter: '#deecf9',
            themeLight: '#c7e0f4',
            themeTertiary: '#71afe5',
            themeSecondary: '#2b88d8',
            themeDarkAlt: '#106ebe',
            themeDark: '#005a9e',
            themeDarker: '#004578',
            neutralLighterAlt: '#f8f8f8',
            neutralLighter: '#f4f4f4',
            neutralLight: '#eaeaea',
            neutralQuaternaryAlt: '#dadada',
            neutralQuaternary: '#d0d0d0',
            neutralTertiaryAlt: '#c8c8c8',
            neutralTertiary: '#c2c2c2',
            neutralSecondary: '#858585',
            neutralPrimaryAlt: '#4b4b4b',
            neutralPrimary: '#333333',
            neutralDark: '#272727',
            black: '#1d1d1d',
            white: '#ffffff'
        }
    });
}

render(<App/>, document.getElementById("root"));

unregister();
