import React, { createElement } from "react";

import ErrorBoundary from "./../ErrorBoundary/ErrorBoundary";
import { IPageDefinition } from "./PageDefinitionTypes";

import { announce } from "./../../../core/util/Accessibility";
import { css } from "./../../../core/util/Util";
import { RapComponent } from "../../../Layout";
import AppointmentScheduler from "../../../../views/AppointmentsView/components/AppointmentScheduler/AppointmentScheduler";

interface IPage {
    page: IPageDefinition;
    key: number | string;
    routeOverride?: string;
    params?: any;
}

class Page extends RapComponent<IPage> {
    public render() {
        return (
            <div className={css(this.props.page.containerClassName, "flex-grow")}>
                <ErrorBoundary>
                    {createElement(
                        this.props.page.component,
                        {...this.props.page.componentProps, params: this.props.params}
                    )}
                </ErrorBoundary>
            </div>
        );
    }

    public componentDidMount() {
        // React router doesn't trigger navigation so we will need to set the document title and announce when the page has loaded.
        announce(`Loaded ${this.props.page.name} page`);
    }

    public componentWillMount() {
        document.title = this.props.page.name;
    }
}

export default Page;
