import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Spinner, SpinnerSize, TextField } from "@fluentui/react";

import { IRapComponentProperties } from "./../../../../platform/Layout";
import { IDashboardViewState } from "../../../../pages/Dashboard/Contracts";
import { AppointmentsActions } from "./../../redux/AppointmentsActions";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { IAppointment } from "../../../../contracts/swagger/_generated";
import { AppointmentsFeature } from "../../../../common/Constants";
import { getAppointmentEditError, isAppointmentEditable } from "../../../../common/Util";

import  ManagerSummary from "../ManagerSummary/ManagerSummary";

import * as AppointmentSelectors from "../../redux/AppointmentsSelectors";

import "./ManageAppointment.scss";
import { FeatureManagementActions } from "../../../FeatureManagement/redux/FeatureManagementActions";


interface IManageAppointmentState {
   textFieldValue: string;
}

//Props passed by parent component
interface IManageAppointmentProvidedProps extends IRapComponentProperties {
    confirmationNumber?: string;
}

//Props mapped from state object
interface IManageAppointmentInitializerOwnProps {
    selectedAppointment: IAppointment;
}

// eslint-disable-next-line
export type IManageAppointmentInitializerProps = ConnectedProps<typeof connector> & IManageAppointmentProvidedProps;

const ManageAppointmentInitializer: FC<IManageAppointmentInitializerProps> = (props) => {
    const [textFieldValue, setTextFieldValue] = useState(props.confirmationNumber ? props.confirmationNumber : "");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        props.logTelemetry(AppointmentsFeature.ManagePage, "Rendered manage page", undefined, {confirmationNumber: props.confirmationNumber});
        if(props.confirmationNumber) {
            fetchAppointment(props.confirmationNumber);
        }
    }, []) // component did mount

    const fetchAppointment = (confirmationNumber: string) => {
        setIsLoading(true);
        props.fetchAppointmentByConfirmationNumber(confirmationNumber).then(appt => {
            props.resetErrorMessage();
            if(isAppointmentEditable(appt)) {
                props.fetchFeatureFlags(appt.storeNumber as number);
            } else {
                props.setErrorMessage(getAppointmentEditError(appt, props.logTelemetry));
            }   
        }).catch(e => {
            props.setErrorMessage(localizedStrings.formatString(
                localizedStrings.AppointmentManager?.unableToFindAppointment as string, { confirmationNumber: textFieldValue }) as string);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const onTextChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(newValue || newValue === "") {
            setTextFieldValue(newValue);
            props.resetErrorMessage();
        }
    }

    const onSearch = () => {
        if (textFieldValue && textFieldValue.length > 0) {
            props.logTelemetry(AppointmentsFeature.SearchAppointment, "Searching for appointment: " + textFieldValue);
            fetchAppointment(textFieldValue);
        } 
        else if (localizedStrings.ManageAppointment?.confirmationNumberMissing) {
            props.setErrorMessage(localizedStrings.ManageAppointment.confirmationNumberMissing);
        }
    }

    return (
        <div className="c-manage-container">
            <div className="c-info-text">
                {localizedStrings.ManageAppointment?.provideConfirmationNumber}
            </div>
            <div className="c-input-container">
                <TextField 
                    label={localizedStrings.ManageAppointment?.confirmationNumber} 
                    aria-required="true" 
                    className="c-input" 
                    value={textFieldValue} 
                    onChange={onTextChange}
                    inputClassName="c-input-field"
                    styles={{ fieldGroup: { height: "40px" } }}
                />
                <PrimaryButton 
                    className="c-submit-button" 
                    onClick={onSearch}
                >
                    {isLoading ? (
                        <Spinner size={SpinnerSize.medium}/>
                    ) : (
                        localizedStrings.ManageAppointment?.searchAppointment
                    )}
                </PrimaryButton>
            </div>
            <div className="c-required">
                {`* ${localizedStrings.ManageAppointment?.required}`}
            </div>
            <div className="c-summary">
                <span aria-live="assertive" className="screen-reader-only">
                    {props.selectedAppointment && localizedStrings.ManageAppointment?.appointmentFound 
                        ? localizedStrings.formatString(localizedStrings.ManageAppointment.appointmentFound, { confirmno: textFieldValue }) 
                        : ""}
                </span>
                {props.selectedAppointment && (      
                    <ManagerSummary 
                        appointment={props.selectedAppointment} 
                        showAdditionalInfo={true}
                    />
                )}  
            </div>              
        </div>
    )
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IManageAppointmentProvidedProps): Partial<IManageAppointmentInitializerOwnProps> {
    return {
        ...providedProps,
        selectedAppointment: AppointmentSelectors.getSelectedAppointment(state),
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: AppointmentsActions.logTelemetry,
    fetchAppointmentByConfirmationNumber: AppointmentsActions.fetchAppointmentByConfirmationNumber,
    setErrorMessage: AppointmentsActions.setErrorMessage,
    resetErrorMessage: AppointmentsActions.resetErrorMessage,
    fetchFeatureFlags: FeatureManagementActions.fetchFeatureFlags,
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(ManageAppointmentInitializer);
