import { AppointmentStages, IAccordionState, IAppointmentsAwareState, IModals, ManagementStages } from "../Contracts";
import { AppointmentTopicCustomQuestion, IAppointment, IAppointmentForCreationDto, IAppointmentForUpdateDto, IAppointmentServiceType, IAppointmentTopic, IOptionSetAttribute, ITimeSlotDto, Redirects, StoreDto, StoreRedirect } from "../../../contracts/swagger/_generated";

export function getAppointmentServiceTypes(state: IAppointmentsAwareState): IAppointmentServiceType[] | undefined {
    if (!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.serviceTypes;
}

export function getSelectedAppointmentServiceType(state: IAppointmentsAwareState): IAppointmentServiceType | undefined {
    if (!state || !state.viewState || !state.viewState.serviceTypes || !state.viewState.scheduler || !state.viewState.scheduler.appointment) {
        return undefined;
    }
    
    return state.viewState.serviceTypes.find(x => x.typeId === state.viewState.scheduler.appointment.appointmentTypeId);
}

export function getAppointmentServiceTopics(state: IAppointmentsAwareState): IAppointmentTopic[] | undefined {
    if (!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.serviceTopics;
}

export function getSelectedAppointment(state: IAppointmentsAwareState): IAppointment | undefined {
    if (!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.selectedAppointment;
}

export function getAppointmentTimeslots(state: IAppointmentsAwareState): Date[] | ITimeSlotDto[] | undefined {
    if (!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.timeslots;
}

export function getAreTimeslotsLoading(state: IAppointmentsAwareState): boolean {
    if (!state || !state.viewState) {
        return false;
    }

    return state.viewState.areTimeslotsLoading;
}

export function getSelectedTimezone(state: IAppointmentsAwareState): string | undefined {
    if (!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.selectedTimezone;
}

export function getCreatedAppointment(state: IAppointmentsAwareState): IAppointment | undefined {
    if (!state || !state.viewState) {
        return undefined;
    }
    return state.viewState.createdAppointment;
}

export function getAppointmentStatuses(state: IAppointmentsAwareState): IOptionSetAttribute[] | undefined {
    if (!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.appointmentStatuses;
}

export function getAppointmentStatusReasons(state: IAppointmentsAwareState): IOptionSetAttribute[] | undefined {
    if (!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.appointmentStatusReasons;
}

export function getTopicCustomQuestions(state: IAppointmentsAwareState): AppointmentTopicCustomQuestion[] | undefined {
    if (!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.customQuestions;
}

export function getErrorMessage(state: IAppointmentsAwareState): string | undefined {
    if (!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.error;
}

export function getStoreSearchResults(state: IAppointmentsAwareState): StoreDto[] | undefined {
    if(!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.storeSearchResults;
}

export function getStores(state: IAppointmentsAwareState): StoreDto[] | undefined {
    if(!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.stores;
}


export function getCaptchaSiteKey(state: IAppointmentsAwareState): string | undefined {
    if(!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.captchaSiteKey;
}

export function getIsSubmitInProgress(state: IAppointmentsAwareState): boolean | undefined {
    if(!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.isSubmitInProgress;
}

export function getValidatedStoreId(state: IAppointmentsAwareState): number | undefined {
    if(!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.validatedStoreId;
}

export function getSchedulerStage(state: IAppointmentsAwareState): AppointmentStages | undefined {
    if(!state || !state.viewState || !state.viewState.scheduler) {
        return undefined;
    }

    return state.viewState.scheduler.stage;
}

export function getSelectedTypeName(state: IAppointmentsAwareState): string | undefined {
    if(!state || !state.viewState || !state.viewState.scheduler) {
        return undefined;
    }

    return state.viewState.scheduler.selectedTypeName;
}

export function getSelectedTopicName(state: IAppointmentsAwareState): string | undefined {
    if(!state || !state.viewState || !state.viewState.scheduler) {
        return undefined;
    }

    return state.viewState.scheduler.selectedTopicName;
}

export function getSchedulerSelectedDate(state: IAppointmentsAwareState): string | undefined {
    if(!state || !state.viewState || !state.viewState.scheduler) {
        return undefined;
    }

    return state.viewState.scheduler.selectedDate;
}

export function getSchedulerSelectedTime(state: IAppointmentsAwareState): string | undefined {
    if(!state || !state.viewState || !state.viewState.scheduler) {
        return undefined;
    }

    return state.viewState.scheduler.selectedTime;
}

export function getConfirmationNumber(state: IAppointmentsAwareState): string | undefined {
    if(!state || !state.viewState || !state.viewState.manager) {
        return undefined;
    }

    return state.viewState.manager.confirmationNumber;
}

export function getManagerSelectedDate(state: IAppointmentsAwareState): string | undefined {
    if(!state || !state.viewState || !state.viewState.manager) {
        return undefined;
    }

    return state.viewState.manager.selectedDate;
}

export function getManagerSelectedTime(state: IAppointmentsAwareState): string | undefined {
    if(!state || !state.viewState || !state.viewState.manager) {
        return undefined;
    }

    return state.viewState.manager.selectedTime;
}

export function getManagerStage(state: IAppointmentsAwareState): ManagementStages | undefined {
    if(!state || !state.viewState || !state.viewState.manager) {
        return undefined;
    }

    return state.viewState.manager.stage;
}

export function getRedirects(state: IAppointmentsAwareState): Redirects | undefined {
    if(!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.redirects;
}

export function getSchedulerAppointment(state: IAppointmentsAwareState): IAppointmentForCreationDto | undefined {
    if(!state || !state.viewState || !state.viewState.scheduler) {
        return undefined;
    }

    return state.viewState.scheduler.appointment;
}

export function getSchedulerIsDateValid(state: IAppointmentsAwareState): boolean  {
    if(!state || !state.viewState || !state.viewState.scheduler) {
        return false;
    }

    return state.viewState.scheduler.isDateValid;
}

export function getSchedulerAccordions(state: IAppointmentsAwareState): IAccordionState | undefined  {
    if(!state || !state.viewState || !state.viewState.scheduler) {
        return undefined;
    }

    return state.viewState.scheduler.accordions;
}

export function getModals(state: IAppointmentsAwareState): IModals | undefined  {
    if(!state || !state.viewState) {
        return undefined;
    }

    return state.viewState.modals;
}

export function getManagerAppointment(state: IAppointmentsAwareState): IAppointmentForUpdateDto | undefined  {
    if(!state || !state.viewState || !state.viewState.manager) {
        return undefined;
    }

    return state.viewState.manager.appointment;
}