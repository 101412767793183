import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";
import enUS from "./locales/en-us";
import enCA from "./locales/en-ca";
import enAU from "./locales/en-au";
import enGB from "./locales/en-gb";

export interface ILocalizedStrings extends LocalizedStringsMethods {
    AppointmentScheduler?: {
        name?: string;
        title?: string;
        summary?: string;
        legal?: string;
        next?: string;
        back?: string;
        reviewAndConfirm?: string;
        noStore?: string;
        done?: string;
        confirmationName?: string;
        youreAllSet?: string;
        closeWindow?: string;
        calendarTitle?: string;
        teamsMeeting?: string;
        service?: string;
        topic?: string;
        noTopicSelected?: string;
        confirmationNumber?: string;
        calendarInfo?: string;
        invalidStore?: string;
        error?: string;
        timeNotAvailable?: string;
        unableToLoadTypes?: string;
        unableToLoadTopics?: string;
        unableToLoadDates?: string;
        unableToLoadQuestions?: string;
        showMore?: string;
        showLess?: string;
        requiredText?: string;
        unableToCreateAppointment?: string;
        unableToFindStore?: string;
        sidebarAltText?: string;
        sidebarBusinessAltText?: string;
        sidebarMecAltText?: string;
        duplicateTimeAppointment?: string;
        duplicateServiceAppointment?: string;
    };
    AppointmentManager?: {
        name?: string;
        unableToFindAppointment?: string;
        manageTitle?: string;
        rescheduleTitle?: string;
        cancelTitle?: string;
        rescheduleAppointment?: string;
        cancelAppointment?: string;
        back?: string;
        submit?: string;
        submitInProgress?: string;
        close?: string;
        modalHeader?: string;
        modalDescription?: string;
        thanksForRescheduling?: string;
        pleaseShareWhy?: string;
        cancelOption1?: string;
        cancelOption2?: string;
        cancelOption3?: string;
        cancelOption4?: string;
        cancelOption5?: string;
        cancelOption6?: string;
        cancelOption7?: string;
        reasonForCancel?: string;
        tellUsMore?: string;
        cancelErrorMessage?: string;
        updateError?: string;
    };
    Cancellation?: {
        text?: string;
        learnAboutAppointments?: string;
        learnAboutAppointmentsOnline?: string;
        appointmentCanceled?: string;
    };
    DateAndTime?: {
        chooseDate?: string;
        dateFormat?: string;
        daysInAdvance?: string;
        timezone?: string;
        noAvailableAppointments?: string;
        availableTimes?: string;
        availableTimeLabel?: string;
        datePickerLabel?: string;
        datePickerAriaLabel?: string;
        timezoneLabel?: string;
        requiredLabel?: string;
        selectedLabel?: string;
    };
    ManageAppointment?: {
        provideConfirmationNumber?: string;
        confirmationNumberMissing?: string;
        appointmentSummary?: string;
        confirmationNumber?: string;
        searchAppointment?: string;
        required?: string;
        topic?: string;
        appointmentCanceled?: string;
        appointmentCompleted?: string;
        appointmentAlreadyStarted?: string;
        appointmentFound?: string;
    };
    Confirmation?: {
        confirmText?: string;
        instructionsTextOnline?: string;
        instructionsText?: string;
        audioCall?: string;
        confirmationNumber?: string;
        manageAppointment?: string;
        addToCalendar?: string;
        bookAnotherAppointment?: string;
        unableToFindAppointment?: string;
        confirmImageAltText?: string;
    }
    PersonalInfo?: {
        personalInformation?: string;
        provideInfo?: string;
        firstName?: string;
        firstNamePlaceholder?: string;
        lastName?: string;
        lastNamePlaceholder?: string;
        email?: string;
        emailPlaceholder?: string;
        emailError?: string;
        confirmEmail?: string;
        confirmEmailTooltip?: string;
        confirmEmailError?: string;
        phone?: string;
        phonePlaceholder?: string;
        phoneError?: string;
        whatCanWeHelpWith?: string;
        whatCanWeHelpWithPlaceholder?: string;
        whatCanWeHelpWithOnlinePlaceholder?: string;
        agreeToTerms?: string;
        serviceAgreement?: string;
        agreeToAge?: string;
        nextSteps?: string;
        nextStepsOnline1?: string;
        nextStepsOnline2?: string;
        nextStepsOnline3?: string;
        nextStepsOnline4?: string;
        nextStepsOnline5?: string;
        communicateDetails?: string;
        privacyAndCookies?: string;
        requiredFields?: string;
        requiredFieldError?: string;
        true?: string;
        false?: string;
        select?: string;
        bslInterpreter?: string;
        bslDescription?: string;
        disabilityAccommodation?: string;
        describeAssistance?: string;
        smsCustomerOptIn?: string;
        microsoftSupport?: string;
        supportText?: string;
    };
    TypeTopic?: {
        chooseTopic?: string;
        chooseType?: string;
        noServices?: string;
        typeLabels?: {
            hardwareSupport?: string;
            personalShopping?: string;
            personalTraining?: string;
            personalSetup?: string;
            personalSetupTraining?: string;
            personalTrainingBusiness?: string;
            businessConsultation?: string;
            educationConsultation?: string;
            lenovo?: string;
        }
        topicLabels?: {
            hardwareSupport?: string;
            personalShopping?: string;
            personalTraining?: string;
            personalSetup?: string;
            personalSetupTraining?: string;
            personalTrainingBusiness?: string;
            businessConsultation?: string;
            educationConsultation?: string;
            lenovo?: string;
        }
        buttonLabels?: {
            hardwareSupport?: string;
            personalShopping?: string;
            personalTraining?: string;
            personalSetup?: string;
            personalSetupTraining?: string;
            personalTrainingBusiness?: string;
            businessConsultation?: string;
            educationConsultation?: string;
            lenovo?: string;
        }
        friendlyLabels?: {
            hardwareSupport?: string;
            personalShopping?: string;
            personalTraining?: string;
            personalSetup?: string;
            personalSetupTraining?: string;
            personalTrainingBusiness?: string;
            businessConsultation?: string;
            educationConsultation?: string;
            lenovo?: string;
        }
    };
    Review?: {
        title?: string;
        edit?: string;
        editType?: string;
        editTopic?: string;
        editDateTime?: string;
        editPersonalInfo?: string;
        confirm?: string;
        customResponsesLabel?: string;
    }
    Store?: {
        chooseStore?: string;
    };
    Maintenance?: {
        message?: string;
    }
}

export const localizedStrings: ILocalizedStrings = new LocalizedStrings({
    "en-us": enUS,
    "en-ca": enCA,
    "en-au": enAU,
    "en-gb": enGB
});
