import { IDashboardViewState } from "../../../pages/Dashboard/Contracts";

export function isFeatureFlagEnabled(state: IDashboardViewState, featureFlagName: string, defaultValue?: boolean): boolean | undefined {
    if (state && state.features && state.features.featureFlags) {
        const flag = state.features.featureFlags.filter(x => x.name === featureFlagName);
        if (flag.length > 0) {
            return flag[0].value;
        }
    }
    
    return defaultValue;
}
