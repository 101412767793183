import { AppointmentTopicCustomQuestion, CustomQuestionType, IAppointment, IAppointmentForCreationDto, IAppointmentForUpdateDto, IAppointmentServiceType, IAppointmentTopic, IOptionSetAttribute, ITimeSlotDto, StoreDto, Redirects } from "../../contracts/swagger/_generated";
import { AppointmentsViewModel } from "./../../contracts/Appointments";

// Appointments view contracts here
export interface IDashboardViewState extends IAppointmentsAwareState {}

export interface IAppointmentsAwareState {
    viewState: IAppointmentsViewState;
}

export interface AppointmentsViewModels extends Array<AppointmentsViewModel> {}

export interface IAppointmentsViewState {
    serviceTypes?: IAppointmentServiceType[];
    serviceTopics?: IAppointmentTopic[];
    selectedAppointment?: IAppointment;
    timeslots?: Date[] | ITimeSlotDto[];
    areTimeslotsLoading: boolean;
    createdAppointment?: IAppointment;
    error?: string;
    appointmentStatuses?: IOptionSetAttribute[];
    appointmentStatusReasons?: IOptionSetAttribute[];
    customQuestions?: AppointmentTopicCustomQuestion[];
    selectedTimezone: string;
    storeSearchResults?: StoreDto[];
    stores?: StoreDto[];
    captchaSiteKey?: string;
    isSubmitInProgress?: boolean;
    validatedStoreId?: number;
    scheduler: ISchedulerState;
    manager: IManagerState;
    redirects?: Redirects;

    modals?: IModals;
}

export interface IModals {
    displayCancellationModal?: boolean;
}

export interface ISchedulerState {
    stage?: AppointmentStages;
    selectedTypeName?: string;
    selectedTopicName?: string;
    selectedDate?: string;
    selectedTime?: string;
    appointment: IAppointmentForCreationDto;
    accordions: IAccordionState;
    isDateValid: boolean;
}

export interface IManagerState {
    confirmationNumber?: string;
    selectedDate?: string;
    selectedTime?: string;
    appointment: IAppointmentForUpdateDto;
    stage?: ManagementStages;
}

export interface IAccordionState {
    isTypeOpen: boolean;
    isTopicOpen: boolean;
    isDateOpen: boolean;
    isInfoOpen: boolean;
}

export interface ITypeObject extends IAppointmentServiceType {
    details: ITypeObjectDetails;
}

export interface ITypeObjectDetails {
    label: string;
    iconName?: string;
    svg?: any;
    buttonLabel: string;
    friendlyLabel: string;
}

export class Area {
    public static Dashboard = "Dashboard";
}

export class TelemetryProperties {
    public static Action = "Action";
    public static ErrorMessage = "ErrorMessage";
}

export interface ITelemetryAttributes {
    dataBiBhvr?: number;
    dataBiScn?: string;
    dataBiScnstp?: string;
    dataBiStpnum?: number;
    dataBiField1?: string;
    dataBiField2?: string;
    dataBiField3?: string;
    dataBiField4?: string;
}

export enum AppointmentStages {
    STORE = 0,
    TYPE = 1,
    TOPIC = 2,
    DATE_TIME = 3,
    INFO = 4,
    REVIEW = 5,
    CONFIRM = 6
}

export enum ManagementStages {
    CONFIRMATION_NUMBER = 0,
    DATE_TIME = 1,
    CANCEL = 2,
    CONFIRM_UPDATE = 3
}

export interface ICustomResponse { 
    customQuestionId: string | undefined,
    questionText: string | undefined,
    responseType: CustomQuestionType | undefined,
    response: any | undefined,
    sortOrder: number | undefined
}

export interface IActiveAccount { 
    authenticatedState?: string;
    displayName?: string;
    memberName?: string;
    showAuthApp?: string;
    type?: string;
    puid?: string;
}
