
import React, { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { Icon, Link } from "@fluentui/react";

import { IRapComponentProperties } from "../../../../../../platform/Layout";
import { IDashboardViewState } from "../../../../../../pages/Dashboard/Contracts";
import { IAppointmentForCreationDto, IAppointmentServiceType } from "../../../../../../contracts/swagger/_generated";
import { localizedStrings } from "../../../../../../common/localization/LocalizedStrings";
import { AppointmentStages } from "../../../../Contracts";
import { getTypeObjectDetails, formatCustomerPhone } from "../../../../AppointmentsHelper";
import { SvgIcon } from "../../../../../../common/components/SvgIcon/SvgIcon";

import * as AppointmentSelectors from "../../../../redux/AppointmentsSelectors";


import "./MobileSummary.scss";


//Props passed by parent component
interface IMobileSummaryProvidedProps extends IRapComponentProperties {
    onEditClick: (stage: AppointmentStages) => void;
}

//Props mapped from state object
interface IMobileSummaryInitializerOwnProps extends IMobileSummaryProvidedProps {
    stage: AppointmentStages;
    errorMessage?: string;
    selectedTypeName?: string;
    selectedTopicName?: string;
    selectedDate?: string;
    selectedTime?: string;
    serviceTypes: IAppointmentServiceType[];
    selectedTimeZone: string;
    isSubmitInProgress?: boolean;
    appointment: IAppointmentForCreationDto;
}

// eslint-disable-next-line
export type IMobileSummaryInitializerProps = IMobileSummaryInitializerOwnProps & typeof ActionsToDispatch;

const MobileSummaryInitializer: FC<IMobileSummaryInitializerProps> = (props) => {
    const contentEl = useRef<HTMLDivElement>(null);
    const containerEl = useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        document.body.style.paddingTop = (containerEl.current && window.innerWidth <= 480) ? document.body.style.paddingTop = `${containerEl.current.offsetHeight}px` : "0px";

        return () => {
            document.body.style.paddingTop = "0px";
        };
      });
    
    const getSelectedTypeDuration = () => {
        if(props.serviceTypes && props.appointment.appointmentTypeId) {
            return props.serviceTypes.find(type => type.typeId === props.appointment.appointmentTypeId)!.duration;
        }
        return 0;
    }

    const isTimeNoLongerAvailable = () => {
        return props.errorMessage && props.errorMessage === localizedStrings.AppointmentScheduler?.timeNotAvailable;
    }
    
    const appointmentServiceType = props.serviceTypes?.find(x => x.typeId === props.appointment?.appointmentTypeId);
    const typeObjectDetails = getTypeObjectDetails(appointmentServiceType && appointmentServiceType.typeName ? appointmentServiceType.typeName : "");

    if(!props.appointment.appointmentTypeId) {
        return <></>
    }

    return (
        <>
            {props.stage !== AppointmentStages.REVIEW && (
                <div 
                    className={`c-summary-container-mobile ${isExpanded ? "open" : ""}`}
                    ref={containerEl}
                >
                    <div 
                        className="c-info-container"
                        ref={contentEl}
                        style={ isExpanded ? { minHeight: contentEl.current?.scrollHeight } : { maxHeight: 55 } }
                    >
                        <h4>{localizedStrings.AppointmentScheduler?.summary}</h4>
                        <div className="c-summary-mobile">
                            {props.appointment.appointmentTypeId && (
                                <span>{typeObjectDetails.label}</span>
                            )}
                            {props.selectedTopicName && 
                                <div className="c-topic-label">
                                    <span className="c-semibold">{localizedStrings.AppointmentScheduler?.topic}:</span> <span>{props.selectedTopicName}</span>
                                </div>
                            }

                            {props.selectedDate && props.selectedTime && props.appointment.appointmentDate && 
                                <div className="c-summary-date-container"> 
                                    <div className="c-date">
                                        <span>
                                            {moment.tz(props.appointment.appointmentDate, props.selectedTimeZone).format(localizedStrings.DateAndTime?.dateFormat)}
                                        </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="c-button-container">
                        <div className="c-icon-container">   
                            <Icon iconName={isExpanded ? "CalculatorSubtract" : "Add"} className={`c-icon ${isExpanded ? "open" : ""}`} />
                        </div>
                        <Link 
                            className="c-show"
                            underline={false}
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            {isExpanded ? localizedStrings.AppointmentScheduler?.showLess : localizedStrings.AppointmentScheduler?.showMore}
                        </Link>
                    </div>
                </div>
            )}

            {props.stage === AppointmentStages.REVIEW && (
                <div className="c-review-container-mobile">
                    <div className="c-summary-mobile">
                        <div className="c-icon-col">
                            {props.selectedTypeName && props.appointment.appointmentTypeId && typeObjectDetails.iconName &&
                                <Icon iconName={typeObjectDetails.iconName} className="c-icon"/>
                            }
                            {props.selectedTypeName && props.appointment.appointmentTypeId && typeObjectDetails.svg &&
                                <SvgIcon svg={typeObjectDetails.svg} className="c-icon"/>
                            }
                        </div>
                        <div className="c-content-col">
                            {props.selectedTypeName && props.appointment.appointmentTypeId ? (
                                <div className="c-summary-item">
                                    <div className="c-type-container">                         
                                        <h5 className="c-type-label">{typeObjectDetails.label}</h5>
                                        <span className="c-duration">
                                            <Icon iconName={"Clock"} className="c-icon-clock"/>
                                            <span>{`${getSelectedTypeDuration()} minutes`}</span>
                                        </span>
                                    </div>
                                    <div className="c-edit">
                                        <Link 
                                            disabled={props.isSubmitInProgress} 
                                            onClick={() => props.onEditClick(AppointmentStages.TYPE)} 
                                            underline 
                                            aria-label={localizedStrings.Review?.editType}
                                            data-bi-name="Edit type"
                                        >
                                            {localizedStrings.Review?.edit}
                                        </Link>
                                    </div>
                                </div>) : (<div className="c-no-type-message">{localizedStrings.AppointmentScheduler?.noTopicSelected}</div>)
                            }
                            {props.selectedTopicName && 
                                <div className="c-summary-item">
                                    <div className="c-topic-label">
                                        <span className="c-semibold">{localizedStrings.AppointmentScheduler?.topic}:</span> {props.selectedTopicName}
                                    </div>
                                    <div className="c-edit">
                                        <Link 
                                            disabled={props.isSubmitInProgress} 
                                            onClick={() => props.onEditClick(AppointmentStages.TOPIC)} 
                                            underline 
                                            aria-label={localizedStrings.Review?.editTopic}
                                            data-bi-name="Edit topic"
                                        >
                                            {localizedStrings.Review?.edit}
                                        </Link>
                                    </div>
                                </div>
                            }
                            {props.selectedDate && props.selectedTime && props.appointment.appointmentDate && 
                                <div className="c-summary-item">
                                    <div className={"c-date-container" + (isTimeNoLongerAvailable() ? " c-error-text" : "")}> 
                                        <div className="c-date">
                                            {moment.tz(props.appointment.appointmentDate, props.selectedTimeZone).format(localizedStrings.DateAndTime?.dateFormat)}
                                        </div>
                                        <div className="c-time">
                                            {`${props.selectedTime} (${moment(props.appointment.appointmentDate).tz(
                                                props.selectedTimeZone ? props.selectedTimeZone : moment.tz.guess()).zoneAbbr()})`}
                                        </div>
                                    </div>
                                    <div className="c-edit">
                                        <Link 
                                            disabled={props.isSubmitInProgress} 
                                            onClick={() => props.onEditClick(AppointmentStages.DATE_TIME)} 
                                            underline 
                                            aria-label={localizedStrings.Review?.editDateTime}
                                            data-bi-name="Edit date"
                                        >
                                            {localizedStrings.Review?.edit}
                                        </Link>
                                    </div>
                                </div>
                            }
                            {props.stage === AppointmentStages.REVIEW && props.appointment.customerFirstName && props.appointment.customerLastName &&
                                props.appointment.customerEmail && props.appointment.descriptionOfIssue &&
                                <div className="c-summary-item">
                                    <div className="c-personal-info-container">
                                        <div className="c-contact-info">
                                            <div>{props.appointment.customerFirstName} {props.appointment.customerLastName}</div>
                                            <div>{props.appointment.customerEmail}</div>
                                            {props.appointment.customerPhone && (
                                                <div>{formatCustomerPhone(props.appointment.customerPhone)}</div>
                                            )}
                                        </div>
                                        <div className="c-description">{props.appointment.descriptionOfIssue}</div>
                                        {(props.appointment.bslInterpreterRequested || props.appointment.accessibilityRequested) && <div className="c-accessibility">
                                            {props.appointment.bslInterpreterRequested && props.appointment.bslInterpreterRequested === true && 
                                                <div>{localizedStrings.PersonalInfo?.bslInterpreter}</div>
                                            }
                                            {props.appointment.accessibilityRequested && props.appointment.accessibilityRequested === true && <>
                                                <div>{localizedStrings.PersonalInfo?.disabilityAccommodation}</div>
                                                <div>{props.appointment.accessibilityDescription}</div>
                                            </>}
                                        </div>}
                                    </div>
                                    <div className="c-edit">
                                        <Link 
                                            disabled={props.isSubmitInProgress} 
                                            onClick={() => props.onEditClick(AppointmentStages.INFO)} 
                                            underline 
                                            aria-label={localizedStrings.Review?.editPersonalInfo}
                                            data-bi-name="Edit personal information"
                                        >
                                            {localizedStrings.Review?.edit}
                                        </Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IMobileSummaryProvidedProps): Partial<IMobileSummaryInitializerOwnProps> {
    return {
        ...providedProps,
        stage: AppointmentSelectors.getSchedulerStage(state),
        errorMessage: AppointmentSelectors.getErrorMessage(state),
        selectedTimeZone: AppointmentSelectors.getSelectedTimezone(state),
        selectedTopicName: AppointmentSelectors.getSelectedTopicName(state),
        selectedTypeName: AppointmentSelectors.getSelectedTypeName(state),
        selectedDate: AppointmentSelectors.getSchedulerSelectedDate(state),
        selectedTime: AppointmentSelectors.getSchedulerSelectedTime(state),
        serviceTypes: AppointmentSelectors.getAppointmentServiceTypes(state),
        isSubmitInProgress: AppointmentSelectors.getIsSubmitInProgress(state),
        appointment: AppointmentSelectors.getSchedulerAppointment(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(MobileSummaryInitializer);