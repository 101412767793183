import { IRapTelemetryService } from "../platform/services/Telemetry";
import { IRapPageContext } from "../platform/Context";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

export interface ITelemetryLogger {
    sendTelemetry(area: string, feature: string, action: string, storeId?: number, errorMessage?: string): void;
    sendComplexTelemetry(area: string, feature: string, properties: { [key: string]: any }, storeId?: number): void;
}

export class TelemetryLogger implements ITelemetryLogger {
    private context: IRapPageContext;

    public constructor(context: IRapPageContext) {
        this.context = context;
    }

    sendTelemetry = (area: string, feature: string, action: string, storeId?: number, errorMessage?: string): void => {
        this.sendComplexTelemetry(area, feature, {
            Action: action,
            ErrorMessage: errorMessage
        }, storeId);
    };

    sendComplexTelemetry = (area: string, feature: string, properties: { [key: string]: any }, storeId?: number): void => {
        const telemetryService = this.context.getService<IRapTelemetryService>("IRapTelemetryService");
        telemetryService.publishEvent(area, feature, properties, storeId);
    };

    sendTrace = (traceId: string, message: string, severity: SeverityLevel): void => {
        this.sendComplexTrace(traceId, message, severity, {});
    };

    sendComplexTrace = (traceId: string, message: string, severity: SeverityLevel, properties: { [key: string]: any }): void => {
        const telemetryService = this.context.getService<IRapTelemetryService>("IRapTelemetryService");
        telemetryService.publishTrace(traceId, message, severity, properties);
    };
}
