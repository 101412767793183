/// <amd-module name="VSS/Platform/Telemetry" />
import { IRapObservableService, IRapPageContext, ServiceOptions, Services, RapObservableService } from "../Context";
import { CustomerIntelligenceEvent } from "../../contracts/Telemetry";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { DigitalReservationsRestClientName } from "../RestClientBase";
import { IDigitalReservationsApiClient, InstrumentationKeyResponse } from "../../contracts/swagger/_generated";

/**
 * The telemetry service is used to publish telemetry events back to Azure appInsights.
 */
 export interface IRapTelemetryService extends IRapObservableService<CustomerIntelligenceEvent, "publish"> {
    /**
     * publishEvent is used to create and send events to the app insights.
     *
     * Events are made up of two primary keys, the area and feature that describe the
     * event that has occurred. Each event generator can then add an object that has
     * any properties it cares to add. On the receiving side of the service the data
     * will be stored and future telemetry queries can parse and utuilize the values
     * sent as name value pairs.
     *
     * @param area - The area defines a large scope of features under a unique name.
     *
     * @param feature - The feature defines a fine grained scope of a single feature.
     *  It should be unique within the area, and the properties should be used to
     *  distinguish how the feature was used.
     *
     * @param storeId - The selected storeId
     *
     * @param properties - An dictionary of name/value pairs with details about the
     *  event. This MAY contain simple or complex values.
     */
     publishEvent(area: string, feature: string, properties: { [key: string]: any }, storeId?: number): void;

    /**
     * publishTrace is used to create and send traces to app insights.
     *
     * Traces are made up of two primary keys, the traceId and message that describe the
     * trace that has occurred. Each trace generator can then add an object that has
     * any properties it cares to add. On the receiving side of the service the data
     * will be stored and future telemetry queries can parse and utuilize the values
     * sent as name value pairs.
     *
     * @param traceId - The unique traceId to be logged with the trace.
     *
     * @param message - The message to be logged with the trace.
     *
     * @param severity - The severity level of the trace.
     *
     * @param properties - An dictionary of name/value pairs with details about the
     *  event. This MAY contain simple or complex values.
     */
    publishTrace(traceId: string, message: string, severity: SeverityLevel, properties: { [key: string]: any }): void;

    /**
     * startNavigationEvent is used to start tracking time for a screen navigation event.
     *
     * It will start tracking time until endNavigationEvent is called for the same screen name.
     *
     * @param screenName - The unique screen name to track load time for.
     *
     */
    startNavigationEvent(screenName: string): void;

    /**
     * endNavigationEvent is used to finish tracking time for a screen navigation event.
     *
     * @param screenName - The unique screen name to track load time for.
     *
     */
    endNavigationEvent(screenName: string): void;

    /**
     * startApiFetch is used to start tracking time for an API fetch.
     *
     * It will start tracking time until endNavigationEvent is called for the same screen name.
     *
     * @param screenName - The unique API name to track load time for.
     *
     */
    startApiFetch(apiName: string): void;

    /**
     * endApiFetch is used to finish tracking time for an API fetch.
     *
     * @param screenName - The unique API name to track load time for.
     *
     */
    endApiFetch(apiName: string): void;
}

export class RapTelemetryService extends RapObservableService<CustomerIntelligenceEvent, "publish"> implements RapTelemetryService {
    private appInsights: ApplicationInsights;
    private intialQueue: {name: string, properties: any}[] = [];

    public _serviceStart(appContext: IRapPageContext) {
        super._serviceStart(appContext);
        this._initializeService();
    }

    public _serviceRestart(appContext: IRapPageContext) {
        super._serviceRestart(appContext);
        this._initializeService();
    }

    public _serviceEnd(appContext: IRapPageContext) {
        super._serviceEnd(appContext);
    }

    public publishEvent(area: string, feature: string, properties: { [key: string]: any }, storeId?: number): void {
        // We are going to add general properties to the event so we will make a copy first.
        const eventProperties = { ...properties };

        // Add common properties
        eventProperties["EventTimestamp"] = new Date().getTime();

        // Telemetry events require both an area and feature
        if (!area) {
            console.warn(`Publishing telemetry event with unknown area. Feature: ${feature}. Properties: ${JSON.stringify(eventProperties)}`);
            area = "Unknown";
        }

        if (!feature) {
            console.warn(`Publishing telemetry event with unknown feature. Area: ${area}. Properties: ${JSON.stringify(eventProperties)}`);
            feature = "Unknown";
        }
        eventProperties["Area"] = area;
        eventProperties["Feature"] = feature;
        eventProperties["StoreId"] = storeId;
        
        // Publish the event
        try {
            if(!this.appInsights) {
                this.intialQueue.push({ name: "Client CI Event", properties: eventProperties });
            }
            else {
                this.appInsights.trackEvent({ name: "Client CI Event" }, eventProperties);
            }
        } catch (exception) {
            console.error(exception);
        }
    }

    public publishTrace(traceId: string, message: string, severityLevel: SeverityLevel, properties: { [key: string]: any }): void {
        // We are going to add general properties to the trace so we will make a copy first.
        const traceProperties = { ...properties };

        // Add common properties
        traceProperties["TraceTimestamp"] = new Date().getTime();

        // Traces require both traceId and message
        if (!traceId) {
            console.warn(`Publishing telemetry trace with unknown traceId. Message: ${message}. Properties: ${JSON.stringify(traceProperties)}`);
            traceId = "Unknown";
        }

        if (!message) {
            console.warn(`Publishing telemetry trace with unknown message. TraceId: ${traceId}. Properties: ${JSON.stringify(traceProperties)}`);
            message = "Unknown";
        }

        // Publish the trace
        try {
            this.appInsights.trackTrace({ message: `${traceId}: ${message}`, severityLevel: severityLevel }, traceProperties);
        } catch (exception) {
            console.error(exception);
        }
    }

    public startNavigationEvent(screenName: string) {
        try {
            this.appInsights.startTrackEvent(screenName);
        } catch (exception) {
            console.error(exception);
        }
    }

    public endNavigationEvent(screenName: string) {
        try {
            this.appInsights.stopTrackEvent(screenName, { Type: "Screen Load Time" });
        } catch (exception) {
            console.error(exception);
        }
    }

    public startApiFetch(apiName: string) {
        try {
            this.appInsights.startTrackEvent(apiName);
        } catch (exception) {
            console.error(exception);
        }
    }

    public endApiFetch(apiName: string) {
        try {
            this.appInsights.stopTrackEvent(apiName, { Type: "API Fetch Time" });
        } catch (exception) {
            console.error(exception);
        }
    }

    private _initializeService(): void {
        this.pageContext.getRestClient<IDigitalReservationsApiClient>(DigitalReservationsRestClientName).getInstrumentationKey().then((response: InstrumentationKeyResponse) => {
            this.appInsights = new ApplicationInsights({
                config: {
                    connectionString: response.key
                    /* ...Other Configuration Options... */
                }
            });
            this.appInsights.loadAppInsights();

            // Flush initial queue
            this.intialQueue.forEach((event) => {
                this.appInsights.trackEvent(event);
            });
        });

    }
}

Services.add("IRapTelemetryService", { serviceFactory: RapTelemetryService, options: ServiceOptions.Persistant });
