
import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { Icon, Link } from "@fluentui/react";
import ReactHtmlParser from 'html-react-parser';

import { RapComponent, IRapComponentProperties } from "../../../../../../platform/Layout";
import { IDashboardViewState } from "../../../../../../pages/Dashboard/Contracts";
import { AppointmentCustomResponseDto, IAppointmentForCreationDto, IAppointmentServiceType, RegistrationQuestionType } from "../../../../../../contracts/swagger/_generated";
import { localizedStrings } from "../../../../../../common/localization/LocalizedStrings";
import { AppointmentStages, IAccordionState, ITypeObjectDetails } from "../../../../Contracts";
import { getTypeObjectDetails, getServiceNameCategory, formatCustomerPhone, getSidebarImage } from "../../../../AppointmentsHelper";
import { AppointmentsActions } from "../../../../redux/AppointmentsActions";

import * as AppointmentSelectors from "../../../../redux/AppointmentsSelectors";
import * as Constants from "../../../../../../common/Constants";

import "./DesktopSummary.scss";
import { CompanyInfoFields } from "../../../../../../common/Constants";
import { SvgIcon } from "../../../../../../common/components/SvgIcon/SvgIcon";



interface IDesktopSummaryState {

}

//Props passed by parent component
interface IDesktopSummaryProvidedProps extends IRapComponentProperties {
    onEditClick: (stage: AppointmentStages) => void;
}

//Props mapped from state object
interface IDesktopSummaryInitializerOwnProps extends IDesktopSummaryProvidedProps {
    stage: AppointmentStages;
    errorMessage?: string;
    selectedTypeName?: string;
    selectedTopicName?: string;
    selectedDate?: string;
    selectedTime?: string;
    serviceTypes: IAppointmentServiceType[];
    selectedTimeZone: string;
    validatedStoreId: number;
    isSubmitInProgress?: boolean;
    appointment: IAppointmentForCreationDto;
    accordions: IAccordionState;
}

// eslint-disable-next-line
export type IDesktopSummaryInitializerProps = IDesktopSummaryInitializerOwnProps & typeof ActionsToDispatch;

class DesktopSummaryInitializer extends RapComponent<IDesktopSummaryInitializerProps, IDesktopSummaryState> {

    
    private getSelectedTypeDuration = () => {
        if(this.props.serviceTypes && this.props.appointment.appointmentTypeId) {
            return this.props.serviceTypes.find(type => type.typeId === this.props.appointment.appointmentTypeId)!.duration;
        }
        return 0;
    }

    private getCustomResponseContent = () => {
        if (this.props.appointment.customResponses && this.props.appointment.customResponses.length > 0) {
            return this.props.appointment.customResponses.filter((response) => !this.isCompanyInfoField(response.questionText) );
        }
    }

    private getCompanyInfoContent = () => {
        if (this.props.appointment.customResponses && this.props.appointment.customResponses.length > 0) {
            return this.props.appointment.customResponses.filter((response) => this.isCompanyInfoField(response.questionText) );
        }
    }

    private isCompanyInfoField = (questionText: string | undefined) => {
        return questionText ? CompanyInfoFields.includes(questionText.toLowerCase()) : false;
    }

    private renderCustomContent = (filteredResponses: AppointmentCustomResponseDto[] | undefined) => {
        return filteredResponses && filteredResponses.length > 0 ? 
            (<div>
                {filteredResponses.map((responseObj) => {
                    if (responseObj.response) {
                        return responseObj.responseType !== RegistrationQuestionType.MultipleChoice 
                            ? <div>{ ReactHtmlParser(responseObj.response) }</div> 
                            : responseObj.response?.split(',').map((checkboxVal) => (<div>{ ReactHtmlParser(checkboxVal) }</div>)
                        );
                    }
                })}
            </div>) : (<></>);
    }

    private isTimeNoLongerAvailable = () => {
        return this.props.errorMessage && this.props.errorMessage === localizedStrings.AppointmentScheduler?.timeNotAvailable;
    }

    private getSummaryContent = (type: ITypeObjectDetails) => {
        return (
            <>
                {this.props.selectedTypeName && this.props.appointment.appointmentTypeId ? (
                    <div className="c-summary-item">
                        <div className="c-type-container">                         
                            <h5 className="c-type-label">{type.label}</h5>
                            <span className="c-duration">
                                <Icon iconName={"Clock"} className="c-icon-clock"/>
                                <span>{`${this.getSelectedTypeDuration()} minutes`}</span>
                            </span>
                        </div>
                        <div className="c-edit">
                            <Link 
                                disabled={this.props.isSubmitInProgress} 
                                onClick={() => this.props.onEditClick(AppointmentStages.TYPE)} 
                                underline 
                                aria-label={localizedStrings.Review?.editType}
                                data-bi-name="Edit type"
                            >
                                {localizedStrings.Review?.edit}
                            </Link>
                        </div>
                    </div>) : (<div className="c-no-type-message">{localizedStrings.AppointmentScheduler?.noTopicSelected}</div>)
                }
                {this.props.selectedTopicName && 
                    <div className="c-summary-item">
                        <div className="c-topic-label">
                            <span className="c-semibold">{localizedStrings.AppointmentScheduler?.topic}:</span> {this.props.selectedTopicName}
                        </div>
                        <div className="c-edit">
                            <Link 
                                disabled={this.props.isSubmitInProgress} 
                                onClick={() => this.props.onEditClick(AppointmentStages.TOPIC)} 
                                underline 
                                aria-label={localizedStrings.Review?.editTopic}
                                data-bi-name="Edit topic"
                            >
                                {localizedStrings.Review?.edit}
                            </Link>
                        </div>
                    </div>
                }
                {this.props.selectedDate && this.props.selectedTime && this.props.appointment.appointmentDate && 
                    <div className="c-summary-item">
                        <div className={"c-date-container" + (this.isTimeNoLongerAvailable() ? " c-error-text" : "")}> 
                            <div className="c-date">
                                {moment.tz(this.props.appointment.appointmentDate, this.props.selectedTimeZone).format(localizedStrings.DateAndTime?.dateFormat)}
                            </div>
                            <div className="c-time">
                                {`${this.props.selectedTime} (${moment(this.props.appointment.appointmentDate).tz(
                                    this.props.selectedTimeZone ? this.props.selectedTimeZone : moment.tz.guess()).zoneAbbr()})`}
                            </div>
                        </div>
                        <div className="c-edit">
                            <Link 
                                disabled={this.props.isSubmitInProgress} 
                                onClick={() => this.props.onEditClick(AppointmentStages.DATE_TIME)} 
                                underline 
                                aria-label={localizedStrings.Review?.editDateTime}
                                data-bi-name="Edit date"
                            >
                                {localizedStrings.Review?.edit}
                            </Link>
                        </div>
                    </div>
                }
            </>
        );
    }
    
    public render() {
        const appointmentServiceType = this.props.serviceTypes?.find(x => x.typeId === this.props.appointment?.appointmentTypeId);
        const typeObjectDetails = getTypeObjectDetails(appointmentServiceType && appointmentServiceType.typeName ? appointmentServiceType.typeName : "");

        // service type details
        const serviceTypeName = appointmentServiceType && appointmentServiceType.typeName ? appointmentServiceType.typeName : "";
        const serviceTypeCategory = getServiceNameCategory(serviceTypeName);

        // custom question details for review page
        const companyInfoContent = this.getCompanyInfoContent();
        const customResponseContent = this.getCustomResponseContent();


        const sideBarDetails = getSidebarImage(this.props.validatedStoreId);
        return (
            <>
                {this.props.stage !== AppointmentStages.REVIEW && (
                    <div className={"c-summary-container-desktop"}>
                        <img 
                            className="c-image"
                            src={sideBarDetails.image} 
                            alt={sideBarDetails.altText}
                        />
                        <h4 className="c-cummary-header">{localizedStrings.AppointmentScheduler?.summary}</h4>
                        <div className="c-summary">
                            <div className="c-icon-col">
                                {this.props.selectedTypeName && this.props.appointment.appointmentTypeId && typeObjectDetails.iconName &&
                                    <Icon iconName={typeObjectDetails.iconName} className="c-icon"/>
                                }
                                {this.props.selectedTypeName && this.props.appointment.appointmentTypeId && typeObjectDetails.svg &&
                                    <SvgIcon svg={typeObjectDetails.svg} className="c-icon"/>
                                }
                            </div>
                            <div className="c-content-col">
                                {this.getSummaryContent(typeObjectDetails)}
                            </div>
                        </div>
                    </div>
                )}

                {this.props.stage === AppointmentStages.REVIEW && (
                    <div className={"c-review-container-desktop"}>
                        <div className="c-summary">
                            <div className="c-icon-col">
                                {this.props.selectedTypeName && this.props.appointment.appointmentTypeId && typeObjectDetails.iconName &&
                                    <Icon iconName={typeObjectDetails.iconName} className="c-icon"/>
                                }
                                {this.props.selectedTypeName && this.props.appointment.appointmentTypeId && typeObjectDetails.svg &&
                                    <SvgIcon svg={typeObjectDetails.svg} className="c-icon"/>
                                }
                            </div>
                            <div className="c-content-col">
                                {this.getSummaryContent(typeObjectDetails)}
                                {this.props.appointment.customerFirstName && this.props.appointment.customerLastName &&
                                    this.props.appointment.customerEmail && this.props.appointment.descriptionOfIssue &&
                                    <div className="c-summary-item">
                                        <div className="c-personal-info-container">
                                            <div className="c-contact-info">
                                                <div>{this.props.appointment.customerFirstName} {this.props.appointment.customerLastName}</div>
                                                <div>{this.props.appointment.customerEmail}</div>
                                                {this.props.appointment.customerPhone && (
                                                    <div>{formatCustomerPhone(this.props.appointment.customerPhone)}</div>
                                                )}
                                            </div>
                                            {(serviceTypeCategory === Constants.BusinessAppointmentTypes || serviceTypeCategory === Constants.BusinessTrainingAppointmentTypes || serviceTypeCategory === Constants.EducationAppointmentTypes) && 
                                                companyInfoContent && companyInfoContent.length > 0 && 
                                                <div className="c-custom">{this.renderCustomContent(companyInfoContent)}</div>
                                            }
                                            {customResponseContent && customResponseContent.length > 0 && 
                                                <div className="c-custom">
                                                    <span className="c-semibold">{localizedStrings.Review?.customResponsesLabel}</span>
                                                    {this.renderCustomContent(customResponseContent)}
                                                </div>
                                            }
                                            {(this.props.appointment.bslInterpreterRequested || this.props.appointment.accessibilityRequested) && <div className="c-accessibility">
                                                {this.props.appointment.bslInterpreterRequested && this.props.appointment.bslInterpreterRequested === true && 
                                                    <div>{localizedStrings.PersonalInfo?.bslInterpreter}</div>
                                                }
                                                {this.props.appointment.accessibilityRequested && this.props.appointment.accessibilityRequested === true && <>
                                                    <div>{localizedStrings.PersonalInfo?.disabilityAccommodation}</div>
                                                    <div>{this.props.appointment.accessibilityDescription}</div>
                                                </>}
                                            </div>}
                                            <div className="c-description">{this.props.appointment.descriptionOfIssue}</div>
                                        </div>
                                        <div className="c-edit">
                                            <Link 
                                                disabled={this.props.isSubmitInProgress} 
                                                onClick={() => this.props.onEditClick(AppointmentStages.INFO)} 
                                                underline 
                                                aria-label={localizedStrings.Review?.editPersonalInfo}
                                                data-bi-name="Edit personal information"
                                            >
                                                {localizedStrings.Review?.edit}
                                            </Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div> 
                )}   
            </>
        );
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IDesktopSummaryProvidedProps): Partial<IDesktopSummaryInitializerOwnProps> {
    return {
        ...providedProps,
        errorMessage: AppointmentSelectors.getErrorMessage(state),
        stage: AppointmentSelectors.getSchedulerStage(state),
        selectedTimeZone: AppointmentSelectors.getSelectedTimezone(state),
        selectedTopicName: AppointmentSelectors.getSelectedTopicName(state),
        selectedTypeName: AppointmentSelectors.getSelectedTypeName(state),
        selectedDate: AppointmentSelectors.getSchedulerSelectedDate(state),
        selectedTime: AppointmentSelectors.getSchedulerSelectedTime(state),
        serviceTypes: AppointmentSelectors.getAppointmentServiceTypes(state),
        validatedStoreId: AppointmentSelectors.getValidatedStoreId(state),
        isSubmitInProgress: AppointmentSelectors.getIsSubmitInProgress(state),
        appointment: AppointmentSelectors.getSchedulerAppointment(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateAppointment: AppointmentsActions.updateSchedulerAppointment
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(DesktopSummaryInitializer);
