import { ActionsUnion, createAction } from "../../../platform/redux/ActionHelper";
import { PageFeature } from "../../../common/Constants";
import { Page } from "../../../contracts/Page";
import { UserRole } from "../Contracts";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

/**
 * Actions for Page view
 */
export enum PageActionTypes {
    UpdateSelectedNavItem = "Page/UpdatedSelectedNavItem",
    ToggleSideDrawer = "Page/ToggleSideDrawer",
    SettingsMenuItemClicked = "Page/SettingsMenuItemClicked",
    ToggleSettingsMenu = "Page/ToggleSettingsMenu",
    InitPage = "Page/InitPage",
    LogTelemetry = "Page/LogTelemetry",
    LogTrace = "Page/LogTrace",
    FetchPageData = "Page/FetchPageData",
    FetchPageDataSuccess = "Page/FetchPageDataSuccess",
    FetchPageDataFailure = "Page/FetchPageDataFailure",
    OnCloseAdminSettingsPanel = "Page/OnCloseAdminSettingsPanel",
    OnCloseUserSettingsPanel = "Page/OnCloseUserSettingsPanel",
    OverrideUserRole = "Page/OverrideUserRole",
    DismissMaintenanceMessageBar = "Page/DismissMaintenanceMessageBar"
}

export const PageActions = {
    updateSelectedNavItem: (navItem: number) => createAction(PageActionTypes.UpdateSelectedNavItem, navItem),
    toggleSideDrawer: () => createAction(PageActionTypes.ToggleSideDrawer),
    settingsMenuItemClicked: (navItem: number) => createAction(PageActionTypes.SettingsMenuItemClicked, navItem),
    toggleSettingsMenu: () => createAction(PageActionTypes.ToggleSettingsMenu),
    initPage: () => createAction(PageActionTypes.InitPage),
    logTelemetry: (feature: PageFeature, action: string, properties?: { [key: string]: any }, errorMessage?: string) =>
        createAction(PageActionTypes.LogTelemetry, { feature, action, properties, errorMessage }),
    logTrace: (traceId: string, message: string, severity: SeverityLevel, properties?: { [key: string]: any }) =>
        createAction(PageActionTypes.LogTrace, { traceId, message, severity, properties }),
    fetchPageData: () => createAction(PageActionTypes.FetchPageData),
    fetchPageDataSuccess: (pageData: Page) => createAction(PageActionTypes.FetchPageDataSuccess, pageData),
    fetchPageDataFailure: (error: string) => createAction(PageActionTypes.FetchPageDataFailure, error),
    onCloseAdminSettingsPanel: () => createAction(PageActionTypes.OnCloseAdminSettingsPanel),
    onCloseUserSettingsPanel: () => createAction(PageActionTypes.OnCloseUserSettingsPanel),
    overrideUserRole: (userRole: UserRole) => createAction(PageActionTypes.OverrideUserRole, userRole),
    dismissMaintenanceMessageBar: () => createAction(PageActionTypes.DismissMaintenanceMessageBar),
};

export type PageActionsType = ActionsUnion<typeof PageActions>;
