import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { MessageBar, MessageBarType } from "@fluentui/react";

import AppointmentManager from "../../AppointmentManager/AppointmentManager";
import * as AppointmentsSelectors from "../../../redux/AppointmentsSelectors";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../../../../platform/Layout";
import { IDashboardViewState } from "../../../Contracts";
import { AppointmentsActions } from "../../../redux/AppointmentsActions";
import { IAppointment, StoreDto } from "../../../../../contracts/swagger/_generated";
import { FeatureManagementActions } from "../../../../FeatureManagement/redux/FeatureManagementActions";

interface IManagerGatewayState {
}

//Props passed by parent component
interface IManagerGatewayProvidedProps extends IRapComponentProperties {
    params: any;
}

//Props mapped from state object
interface IManagerGatewayOwnProps {
    storeSearchResults: StoreDto[];
    errorMessage: string;
    selectedAppointment: IAppointment;
}

// eslint-disable-next-line
export type IManagerGatewayProps = ConnectedProps<typeof connector> & IManagerGatewayProvidedProps;

class ManagerGateway extends RapComponent<IManagerGatewayProps, IManagerGatewayState> {
    constructor(props: IManagerGatewayProps) {
        super(props);

        this.props.initManager();

        if(this.props.params.confirmno) {
            this.props.updateConfirmationNumber(this.props.params.confirmno);
        }

        this.props.fetchStores();
        this.props.fetchAppointmentStatuses();
        this.props.fetchAppointmentStatusReasons();
    }

    public componentDidUpdate(prevProps: Readonly<IManagerGatewayProps>): void {
        if(this.props.selectedAppointment && prevProps.selectedAppointment?.storeNumber != this.props.selectedAppointment.storeNumber) {
            this.props.fetchFeatureFlags(this.props.selectedAppointment.storeNumber as number);
        }
    }

    render() {
        return (
            <>
                {this.props.errorMessage && (
                    <MessageBar 
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        onDismiss={() => this.props.resetErrorMessage()}
                        dismissButtonAriaLabel={"Close"}
                    >
                        {this.props.errorMessage}
                    </MessageBar>
                )}
                <AppointmentManager/>
            </>
        )
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IManagerGatewayProvidedProps): Partial<IManagerGatewayOwnProps> {
    return {
        ...providedProps,
        storeSearchResults: AppointmentsSelectors.getStoreSearchResults(state),
        errorMessage: AppointmentsSelectors.getErrorMessage(state),
        selectedAppointment: AppointmentsSelectors.getSelectedAppointment(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    initManager: AppointmentsActions.initManager,
    updateConfirmationNumber: AppointmentsActions.updateConfirmationNumber,
    fetchStores: AppointmentsActions.fetchStores,
    fetchAppointmentStatuses: AppointmentsActions.fetchAppointmentStatuses,
    fetchAppointmentStatusReasons: AppointmentsActions.fetchAppointmentStatusReasons,
    fetchFeatureFlags: FeatureManagementActions.fetchFeatureFlags,
    resetErrorMessage: AppointmentsActions.resetErrorMessage
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(ManagerGateway);