import React, { FC, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";

import { IRapComponentProperties } from "../../../../../../platform/Layout";
import { IDashboardViewState } from "../../../../../../pages/Dashboard/Contracts";
import { RecaptchaButton } from "../../../../../../common/components/RecaptchaButton/RecaptchaButton";
import { localizedStrings } from "../../../../../../common/localization/LocalizedStrings";
import { cleanAppointmentDataForTelemetry, getAppointmentEditError, isAppointmentEditable } from "../../../../../../common/Util";
import { AppointmentForUpdateDto, IAppointment, IOptionSetAttribute, OptionSetAttribute } from "../../../../../../contracts/swagger/_generated";
import { AppointmentsActions } from "../../../../redux/AppointmentsActions";
import { ICancellationState } from "../CancelModal/CancelModal";
import { ITelemetryAttributes, ManagementStages } from "../../../../Contracts";
import { announce } from "../../../../../../platform/core/util/Accessibility";

import * as FeatureManagementSelectors from "../../../../../FeatureManagement/redux/FeatureManagementSelectors";
import * as AppointmentSelectors from "../../../../redux/AppointmentsSelectors";
import * as Constants from "../../../../../../common/Constants";

const cancelButtonAttributes: ITelemetryAttributes = {
    dataBiBhvr: Constants.CompleteProcessBehavior1DS,
    dataBiScn: Constants.ManageAppointmentScenario,
    dataBiScnstp: Constants.ConfirmCancelStep,
    dataBiStpnum: 5
}

interface ICancelButtonProvidedProps extends IRapComponentProperties {
    cancellationState: ICancellationState;
}

interface ICancelButtonOwnProps {
    isCaptchaEnabled: boolean;
    selectedAppointment: IAppointment;
    appointmentStatuses: IOptionSetAttribute[];
    appointmentStatusReasons: IOptionSetAttribute[];
}

// eslint-disable-next-line
export type ICancelButtonProps = ConnectedProps<typeof connector> & ICancelButtonProvidedProps;

const CancelButtonInitializer: FC<ICancelButtonProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    const getTrimmedCancellationReasons = () => {
        // 1DS has a 255 character limit for any given field so we need to trim the
        // cancellation reasons in the case that all of them are selected
        let cancellationReasonList = props.cancellationState.cancellationReasonList ? props.cancellationState.cancellationReasonList : [];
        cancellationReasonList = cancellationReasonList.map(reason => {
            return reason.substring(0, Constants.CancellationReasonMaxCharacters);
        });
        if (props.cancellationState.customReasonChecked) {
            cancellationReasonList.push("Other reason");
        }
        return cancellationReasonList.join(", ");
    }

    const cancelAppointment = (token?: string) => {
        setIsLoading(true);
        props.fetchAppointmentById(props.selectedAppointment?.storeNumber as number, 
            props.selectedAppointment?.appointmentId as string).then(appt => {
                //check that the appointment is still editable
                if(isAppointmentEditable(appt)) {
                    props.logTelemetry(Constants.AppointmentsFeature.AppointmentManager, 
                        "Attempting to cancel appointment", 
                        props.selectedAppointment?.storeNumber, 
                        cleanAppointmentDataForTelemetry(props.selectedAppointment as IAppointment)
                    );
                    let status = props.appointmentStatuses?.find((x: OptionSetAttribute) => x.label === Constants.AppointmentStatuses.Canceled);
                    let statusReason = props.appointmentStatusReasons?.find((x: OptionSetAttribute) => x.label === Constants.AppointmentStatusReasons.Canceled);
            
                    // If there is a custom cancellation reason inputted by the user, we need to add it to the list
                    let cancellationReasonList = props.cancellationState.cancellationReasonList ? props.cancellationState.cancellationReasonList : [];
                    if (props.cancellationState.customReasonChecked) {
                        if (props.cancellationState.customCancellationReason && props.cancellationState.customCancellationReason.length > 0) {
                            cancellationReasonList.push(props.cancellationState.customCancellationReason);
                        }
                    }
                    const cancellationReasonStr = cancellationReasonList.join(", ");
                    
                    if(props.selectedAppointment?.storeNumber && props.selectedAppointment?.appointmentId) {
                        props.updateAppointment(
                            props.selectedAppointment.storeNumber,
                            props.selectedAppointment.appointmentId,
                            {
                                statusId: status?.value,
                                statusReasonId: statusReason?.value,
                                isCancelled: true,
                                cancellationReason: cancellationReasonStr
                            } as AppointmentForUpdateDto,
                            Constants.AppointmentUpdateType.Cancel,
                            token
                        ).then(appt => {
                            props.logTelemetry(Constants.AppointmentsFeature.AppointmentManager, "Appointment canceled...redirecting", undefined);
                            props.updateModals({ displayCancellationModal: false })
                            props.updateManagerStage(ManagementStages.CANCEL);

                            if(appt.confirmationNumber && localizedStrings.Cancellation?.appointmentCanceled) {
                                announce(localizedStrings.formatString(localizedStrings.Cancellation.appointmentCanceled, { 
                                    confirmno: appt.confirmationNumber}).toString(), true);
                            }
                        }).catch(e => {
                            console.log(e);
                        }).finally(() => {
                        });
                        props.logOneDSAction({
                            pageName: Constants.ManageAppointmentScenario,
                            behavior: Constants.CompleteProcessBehavior1DS,
                            contentTags: {
                                cN: Constants.SubmitContentName,
                                scn: Constants.ManageAppointmentScenario,
                                scnstp: Constants.ConfirmCancelStep,
                                stpnum: "5",
                                field3: getTrimmedCancellationReasons()
                            }
                        });
                    }
                }
                else {
                    setIsLoading(false);
                    props.updateModals({ displayCancellationModal: false });
                    props.setErrorMessage(getAppointmentEditError(appt, props.logTelemetry));
                }
            }
        );
    }
    
    
    return (
        <>
            {props.isCaptchaEnabled ? (
                <RecaptchaButton 
                    text={isLoading ? "" : localizedStrings.AppointmentManager?.submit} 
                    action={Constants.CaptchaActions.UpdateAppointment}
                    disabled={isLoading}
                    onClick={cancelAppointment} 
                    allowDisabledFocus 
                    className={"c-next-button"}
                >
                    {isLoading && <Spinner size={SpinnerSize.small} />}
                </RecaptchaButton>
            ) : (
                <PrimaryButton 
                    text={isLoading ? "" : localizedStrings.AppointmentManager?.submit} 
                    disabled={isLoading}
                    onClick={() => cancelAppointment()} 
                    allowDisabledFocus 
                    className={"c-next-button"}
                >
                    {isLoading && <Spinner size={SpinnerSize.small} />}
                </PrimaryButton>
            )}
        </>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: ICancelButtonProvidedProps): Partial<ICancelButtonOwnProps> {
    return {
        ...providedProps,
        isCaptchaEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableCaptcha", false),
        selectedAppointment: AppointmentSelectors.getSelectedAppointment(state),
        appointmentStatuses: AppointmentSelectors.getAppointmentStatuses(state),
        appointmentStatusReasons: AppointmentSelectors.getAppointmentStatusReasons(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logOneDSAction: AppointmentsActions.logOneDSAction,
    fetchAppointmentById: AppointmentsActions.fetchAppointmentById,
    updateAppointment: AppointmentsActions.updateAppointment,
    logTelemetry: AppointmentsActions.logTelemetry,
    setErrorMessage: AppointmentsActions.setErrorMessage,
    updateModals: AppointmentsActions.updateModals,
    updateManagerStage: AppointmentsActions.updateManagerStage
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const CancelButton = connector(CancelButtonInitializer);