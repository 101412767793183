import React, { FC } from "react";

interface ISvgIconProps {
    svg: any;
    className?: string;
}

export const SvgIcon: FC<ISvgIconProps> = props => {
    return <img src={props.svg} className={props.className} role="none" />;
};
