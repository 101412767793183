import { IRapPageContext, IRapService, Services, RapService } from "../Context";
/**
 * This is the basic data that describes the state of the page being executed.
 *  It has basic information about the service and the entities accessed.
 */
export interface IAuthenticationData {
    /**
     * Authentication token.
     */
    idToken: string;
    /**
     * GroupId (GUID) for the team you are in.
     */
    groupId: string;
    /**
     * ChannelId (GUID) for the general channel you are in.
     */
    channelId: string;
}

export interface IRapAuthenticationService extends IRapService {
    setIdToken(idToken: string): void;
    setGroupId(groupId: string): void;
    setChannelId(channelId: string): void;
    getData(): IAuthenticationData;
}

export class RapAuthenticationService extends RapService implements IRapAuthenticationService {
    private authData: IAuthenticationData;

    public _serviceStart(pageContext: IRapPageContext) {
        super._serviceStart(pageContext);

        // Fetch authorization token for page
        this._initializeService();
    }

    private _initializeService(): void {
        this.authData = { idToken: "", groupId: "", channelId: "" };
    }

    public setIdToken(idToken: string): void {
        this.authData.idToken = idToken;
    }

    public setGroupId(groupId: string): void {
        this.authData.groupId = groupId;
    }

    public setChannelId(channelId: string): void {
        this.authData.channelId = channelId;
    }

    public getData(): IAuthenticationData {
        return this.authData;
    }
}

Services.add("IRapAuthenticationService", { serviceFactory: RapAuthenticationService });
