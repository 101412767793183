
import React, {FC} from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "@fluentui/react";
import { getCaptchaSiteKey } from "../../../views/AppointmentsView/redux/AppointmentsSelectors";
import { AppointmentsActionTypes } from "../../../views/AppointmentsView/redux/AppointmentsActions";
import { ITelemetryAttributes } from "../../../views/AppointmentsView/Contracts";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import "./RecaptchaButton.scss";

interface IRecaptchaButtonProps {
    text?: string;
    action: string;
    onClick(captchaToken: string): void;
    allowDisabledFocus?: boolean;
    disabled?: boolean;
    className?: string;
    telemetryAttributes?: ITelemetryAttributes;
    children?: React.ReactNode;
}

export const RecaptchaButton: FC<IRecaptchaButtonProps> = (props) => {

    const dispatch = useDispatch();
    const key = useSelector(getCaptchaSiteKey)

    if(key == undefined){
        dispatch({type: AppointmentsActionTypes.FetchCaptchaSiteKey});
        return <></>;
    }

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={key}
            useRecaptchaNet={true}
            useEnterprise={true}
            scriptProps={{
                async: false, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                nonce: undefined // optional, default undefined
            }}
        >
            <RecaptchaButtonInitializer
                text={props.text}
                action={props.action}
                onClick={props.onClick} 
                allowDisabledFocus={props.allowDisabledFocus}
                disabled={props.disabled} 
                className={props.className}
                telemetryAttributes={props.telemetryAttributes}
            >
                {props.children}
            </RecaptchaButtonInitializer>
        </GoogleReCaptchaProvider>
    )
}

const RecaptchaButtonInitializer: FC<IRecaptchaButtonProps> = (props) => {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const onClick = () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
      
        executeRecaptcha(props.action).then((token: string) => {
            props.onClick(token);    
        }, (error: any) => {
            console.error(error);
        });
    }

    return (
        <PrimaryButton 
            text={props.text} 
            onClick={onClick} 
            allowDisabledFocus={props.allowDisabledFocus}
            disabled={props.disabled} 
            className={props.className}
            data-bi-bhvr={props.telemetryAttributes?.dataBiBhvr}
            data-bi-scn={props.telemetryAttributes?.dataBiScn}
            data-bi-scnstp={props.telemetryAttributes?.dataBiScnstp}
            data-bi-stpnum={props.telemetryAttributes?.dataBiStpnum}
            data-bi-field1={props.telemetryAttributes?.dataBiField1}
            data-bi-field2={props.telemetryAttributes?.dataBiField2}
            data-bi-field3={props.telemetryAttributes?.dataBiField3}
            data-bi-field4={props.telemetryAttributes?.dataBiField4}
        >
            {props.children}
        </PrimaryButton>
    ) 
}