import React from "react";
import { RapComponent } from "../../../../platform/Layout";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import "./ManagerSummary.scss";
import { Icon } from "@fluentui/react";
import * as Constants from "../../../../common/Constants";
import { IAppointment } from "../../../../contracts/swagger/_generated";
import moment from "moment-timezone";
import { getTypeObjectDetails, formatCustomerPhone } from "../../AppointmentsHelper";
import { SvgIcon } from "../../../../common/components/SvgIcon/SvgIcon";

export interface IManagerSummaryProps {
    appointment: IAppointment;
    showAdditionalInfo?: boolean;
}

export default class ManagerSummary extends RapComponent<IManagerSummaryProps> {
    public render() {
        let serviceDetails = getTypeObjectDetails(this.props.appointment.appointmentType as string);

        return (
            <div className="c-summary-container">
                <div className="c-summary-header c-bold-text" aria-live="assertive">
                    {localizedStrings.ManageAppointment?.appointmentSummary}
                </div>
                <div className="c-summary-info c-flex">
                    <div className="c-type-icon">
                        {serviceDetails.iconName ? (
                            <Icon iconName={serviceDetails?.iconName} />
                        ) : (
                            <SvgIcon svg={serviceDetails.svg} />
                        )}
                    </div>
                    <div>
                        <div className="c-summary-field c-flex">
                            <span className="c-bold-text c-type-label">{serviceDetails?.label}</span>
                            <div className="c-time-container c-flex">
                                <Icon iconName={"Clock"} className="c-icon-clock"/>
                                <span>{`${this.getAppointmentDuration()} minutes`}</span>
                            </div>
                        </div>
                        <div className="c-summary-field">
                            <span className="c-bold-text">{`${localizedStrings.ManageAppointment?.topic}: `}</span>
                            {this.formatAppointmentTopic()}
                        </div>
                        <div className="c-summary-field">
                            <div>{this.formatAppointmentDate()}</div>
                            <div>{this.formatAppointmentTime()}</div>
                        </div>
                        {this.props.showAdditionalInfo && this.props.appointment && (
                            <>
                                <div className="c-summary-field">
                                    <div>{`${this.props.appointment.customerFirstName} ${this.props.appointment.customerLastName}`}</div>
                                    <div>{this.props.appointment.customerEmail}</div>
                                    {this.props.appointment.customerPhone && (
                                        <div>{formatCustomerPhone(this.props.appointment.customerPhone)}</div>
                                    )}
                                </div>
                                <div className="c-summary-field">
                                    {this.props.appointment.descriptionOfIssue}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private formatAppointmentDate = () => {
        return this.props.appointment ? moment(this.props.appointment.appointmentDate).format(localizedStrings.DateAndTime?.dateFormat) : "";
    }

    private formatAppointmentTime = () => {
        if (!this.props.appointment) {
            return "";
        }
        // Appointment time will be shown in user's current local timezone, since we don't know the original timezone that the user selected
        const tz = moment.tz(moment.tz.guess()).zoneAbbr();
        return `${moment(this.props.appointment.appointmentDate).format(Constants.timeFormat)} (${tz})`;
    }

    private formatAppointmentTopic = () => {
        if (!this.props.appointment || !this.props.appointment.subject || !this.props.appointment.appointmentType) {
            return "";
        }

        // Appointment 'subject' includes both type name & topic name. We just want to show topic name
        return this.props.appointment.subject.replace(this.props.appointment.appointmentType, "").trim();
    }

    private getAppointmentDuration = () => {
        if (!this.props.appointment) {
            return 0;
        }

        const start = moment(this.props.appointment.appointmentDate);
        const end = moment(this.props.appointment.scheduledEndDate);
        return moment.duration(end.diff(start)).asMinutes();
    }
}
