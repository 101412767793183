export enum AppointmentsFeature {
    SelectStore,
    SelectDate,
    SelectTime,
    SelectTopic,
    SelectType,
    SearchAppointment,
    SubmitAppointment,
    SubmitAppointmentFailure,
    RenderPersonalnformationPage,
    ConfirmationPage,
    DateTimePage,
    ManagePage,
    AppointmentManager,
    AppointmentScheduler,
    AppointmentType,
    AppointmentSaga
}

export enum PageFeature {
    None,
    BackPane,
    NavBar,
    ToolBar,
    Authentication,
    NavMenu,
    SettingsMenu,
    AdminSettings,
    UserSettings,
    MaintenanceMessageBar
}

export enum StoreIdErrors {
    NoIdProvided = -1,
    IdIsGuid = -2
}

export enum ErrorCode {
    NoUserFound = 1,
    UnAuthorizedUser = 2,
    GenericError = 3,
    StoreMetricsError = 4
}

export enum AppointmentStatuses {
    Canceled = "Canceled",
    Closed = "Closed"
}

export enum AppointmentStatusReasons {
    Canceled = "Canceled",
    Completed = "Completed",
    InProgress = "In Progress"
}

export enum AppointmentUpdateType {
    Update,
    Cancel
}

export enum ConfirmationPageErrors {
    NoActivityId,
    AppointmentNotFound,
    NoEmail,
    NoStoreId,
    NoTimeZone
}

export enum BusinessAppointmentTypes {
    BusinessConsultation = "Business Consultation",
    Australia = "Business Consultation - Australia",
    Canada = "Business Consultation - Canada",
    BM = "Business Consultation B&M",
    UK = "Business Consultation - UK"
}

export enum EducationAppointmentTypes {
    EducationConsultation = "Education consultation"
}

export enum BusinessTrainingAppointmentTypes {
    PersonalTrainingForBusiness = "Personal Training for Business",
    PersonalTrainingForBusinessSpace = "Personal  Training for Business"
}

export enum PersonalAppointmentTypes {
    PersonalShopping = "Personal Shopping",
    PersonalShoppingAssistance = "Personal shopping assistance",
    PersonalShoppingAssistanceCanada = "Personal shopping assistance - Canada",
    PersonalShoppingAssistanceAustralia = "Personal shopping assistance - Australia",
    PersonalShoppingAustralia = "Personal shopping - Australia"
}

export enum PersonalSetupAppointmentTypes {
    PersonalSetup = "Personal Setup"
}

export enum PersonalTrainingAppointmentTypes {
    PersonalTraining = "Personal Training"
}

export enum PersonalSetupTrainingAppointmentTypes {
    PersonalSetupTraining = "Personal setup & training"
}

export enum HardwareAppointmentTypes {
    HardwareAppointmentType = "Microsoft Service & Hardware Support"
}

export enum LenovoAppointmentTypes {
    Lenovo = "Lenovo"
}

export enum CaptchaActions {
    CreateAppointment = "CreateAppointment",
    UpdateAppointment = "UpdateAppointment"
}

export const HardwareAppointmentType = "Microsoft Service & Hardware Support";

export const CompanyInfoFields = [
    "city",
    "company name",
    "company size",
    "county/region",
    "job title",
    "school name",
    "school size",
    "state",
    "province or territory",
    "state or territory",
    "state / province / territory",
    "town/city"
]

export const SurfaceProductLiveDemonstrationTopic = "Surface Product Live Demonstration";
export const SurfaceLiveDemoTopic = "Surface product live demo";
export const HoloLensTopic = "HoloLens 2";
export const TradeinTopic = "Device trade-in";

export const Theme_Default = "theme-default";
export const Theme_Dark = "theme-dark";
export const Theme_HighContrast = "theme-highcontrast";

export const Theme_Default_Key = "default";
export const Theme_Dark_Key = "dark";
export const Theme_HighContrast_Key = "contrast";

export const Dev = "dev";
export const Ppe = "ppe";

export const DefaultLocale = "en-us";
export const CanadaLocale = "en-ca";
export const AustraliaLocale = "en-au";
export const UnitedStatesLocale = "en-us";
export const UnitedKingdomLocale = "en-gb";

export const ServiceAgreementUrl = "https://support.microsoft.com/help/4203112/microsoft-customer-support-service-agreement";
export const PrivacyUrl = "https://go.microsoft.com/fwlink/?LinkId=521839";
export const SupportUrl = "https://support.microsoft.com/";

export const NACountryCode = "+1";
export const AustraliaCountryCode = "+61";
export const UnitedKingdomCountryCode = "+44";
export const NAPhoneMask = "(999) 999-9999";
export const AustraliaPhoneMask = "999 999 999";
export const UnitedKingdomPhoneMask = "9999 999999"
export const NAPhoneRegex = /^(\+1|)?(\d{3})(\d{3})(\d{4})$/;
export const AustraliaPhoneRegex = /^(\+61|)?(\d{3})(\d{3})(\d{3})/;
export const UnitedKingdomPhoneRegex = /^(\+44|)?(\d{4})(\d{6})/;
export const EmailRegex = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PhoneFieldRegex = /^[\d() -]{11,}$/;
export const localeRegex = /\/[a-zA-Z]{2}-[a-zA-Z]{2}\//;
export const guidRegex = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;
export const LetterNumberRegex = /[a-zA-Z0-9]/g;
export const NonNumbersRegex = /[^\d]/g;
export const storeIdRegex = /^[0-9]{4}$/;
export const DeprecatedOrganizationalUnits = [
    "990af04f-e7ad-eb11-8236-000d3a1e961e", // Personal Setup (9995)
    "b2cbd870-aff2-ea11-a815-000d3a54663d", // Personal Shopping Assistance (9997)
    "120125a3-edce-eb11-bacc-000d3a56ed5d" // Personal Training (9994)
];
export const NewOrganizationalUnitId = "35e66797-a536-ec11-b6e6-000d3a102525"; // WSS - US (1111)

export const RouteRegex_Change = /^.*change.*$/
export const RouteRegex_Confirmation = /^.*confirmation.*$/
export const RouteRegex_Create = /^.*create.*$/
export const RouteRegex_Cancel = /^.*cancel.*$/
export const RouteRegex_Reschedule = /^.*reschedule.*$/

export const Business = "business";
export const Education = "education";
export const Consultation = "consultation";
export const BaseUrl = "https://www.microsoft.com/";
export const BusinessUrlExt = "/store/b/business-consultation";
export const PersonalUrlExt = "/store/b/online-computer-shopping-appointments";
export const AppointmentCreateExtAndStoreParam = "appointment/create/?storeid="; // no leading slash here (locale will add slash)
export const ManageAppointmentExtAndConfirmNo = "appointment/change/?confirmno=";

export const MaxDescriptionLength = 2000; // Comes from 'description' field (Service activity entity) in Dynamics

export const VirtualStoreDaysInAdvance = 6;
export const MecDaysInAdvance = 14;
export const timeFormat = "h:mm A";
export const dateFormat = "YYYY-MM-DD";
export const dateTimeFormat = "YYYY-MM-DD hh:mm";
export const TimeslotBuffer = 60;
export const TimeDiffRequirement = 10;

export const PersonalShoppingAppointmentType = "personal shopping";

export const PageRouteCreate = "create";
export const PageRouteChange = "change";

export const ConfirmationPageExpectedParameters = ["storeid", "activityid", "email", "serviceid", "timezone"];
export const StoreRedirectExpectedParameters = ["storeid"]

export const NameTextFieldCharacterLimit = 79; // first + ' ' + last name must be < 160 char
export const SingleLineTextFieldCharacterLimit = 100;
export const MultilineTextFieldCharacterLimit = 2000;

export const LocalStorageAccountKey = "activeAccount";

// 1DS (telemetry) related constants
export const StartProcessBehavior = 20;
export const CheckpointBehavior1DS = 21;
export const CompleteProcessBehavior1DS = 22;
export const BookAppointmentScenario = "Book An Appointment";
export const TypeStep = "Choose Type";
export const TopicStep = "Choose Topic"
export const DateTimeStep = "Choose Date and Time";
export const ReviewStep = "Review and Confirm";
export const ConfirmStep = "Confirmed";
export const ManageAppointmentScenario = "Manage Appointment";
export const RescheduleStep = "Reschedule Appointment";
export const NewDateTimeStep = "Select New Date and Time";
export const CancelStep = "Cancel Appointment";
export const ConfirmCancelStep = "Confirm Cancelation";
export const SubmitContentName = "Submit";
export const BookStartStep = "Book An Appointment Start";
export const ManageStartStep = "Manage Appointment Start";
export const DefaultOwnerId = "e6d8e851-fd75-ea11-a811-000d3a5466d8";
export const CancellationReasonMaxCharacters = 25;