export default {
    AppointmentScheduler: {
        name: "Schedule an appointment",
        title: "Book an appointment",
        summary: "Your appointment summary",
        legal: "By clicking Next you confirm you are over the age of 18.",
        next: "Next",
        back: "Back",
        reviewAndConfirm: "Review and confirm",
        noStore: "No appointment type selected, please navigate back to try again",
        done: "Done",
        confirmationName: "Appointment Confirmation",
        youreAllSet: "You're all set!",
        closeWindow: "You may now close this window",
        calendarTitle: "Microsoft Store Answer Desk Appointment",
        teamsMeeting: "Microsoft Teams Meeting",
        service: "Service",
        topic: "Topic",
        noTopicSelected: "Please pick an appointment type",
        confirmationNumber: "Confirmation number",
        error: "Something went wrong",
        calendarInfo:
            "Your email address will only be used by Microsoft Store to communicate with you the details and steps you’ll need to take for your online appointment with a product expert.",
        invalidStore: "Unable to find store with given storeid",
        timeNotAvailable: 'The appointment time you chose is no longer available. Please click the "Edit" link below and choose a different time.',
        unableToLoadTypes: "Unable to load service types in requested amount of time.",
        unableToLoadTopics: "Unable to load service topics in requested amount of time.",
        unableToLoadDates: "Unable to load dates/times in requested amount of time.",
        unableToLoadQuestions: "Unable to load custom questions in requested amount of time.",
        showMore: "Show more",
        showLess: "Show less",
        requiredText: "* Required",
        unableToCreateAppointment: "Unable to create appointment, please try again",
        duplicateTimeAppointment:
            "Looks like you’ve already booked an appointment for this time slot with confirmation #{confirmationNumber}. Please check your email for more details.",
        duplicateServiceAppointment: 
            "Looks like you’ve already booked an appointment for this day with confirmation #{confirmationNumber}. Please refer to your original confirmation email to review your appointment details or to reschedule your appointment. If you need a follow-up appointment, you can choose another day to reserve your time slot.",
        sidebarAltText: "A person uses their phone and laptop to join an online appointment with a Microsoft product expert.",
        sidebarBusinessAltText: "A person uses their phone and laptop to join an online appointment with a Microsoft product expert.",
        sidebarMecAltText: "A person talks with a Microsoft product expert during a servicing appointment."
    },
    AppointmentManager: {
        name: "Manage appointments",
        unableToFindAppointment: "Unable to find appointment with confirmation number: {confirmationNumber}",
        manageTitle: "Manage your appointment",
        rescheduleTitle: "Reschedule your appointment",
        cancelTitle: "Appointment canceled",
        rescheduleAppointment: "Reschedule appointment",
        cancelAppointment: "Cancel appointment",
        back: "Back",
        submit: "Submit",
        submitInProgress: "Submission in progress",
        close: "Close cancel appointment dialog",
        modalHeader: "Cancel your appointment",
        modalDescription: "Are you sure you want to cancel the apointment? This action cannot be undone.",
        thanksForRescheduling: "Thanks for rescheduling your appointment",
        pleaseShareWhy: "Would you like to share why you’re canceling this appointment?",
        cancelOption1: "I have a conflict with the appointment time",
        cancelOption2: "I no longer need assistance",
        cancelOption3: "I need help with a different topic",
        cancelOption4: "I did not mean to book this appointment",
        cancelOption5: "I accidentally created a duplicate appointment",
        cancelOption6: "I am not comfortable using Microsoft Teams",
        cancelOption7: "Other reason",
        reasonForCancel: "Reason for canceling",
        tellUsMore: "Tell us more about why you're canceling this appointment.",
        cancelErrorMessage: "Please enter your reason for canceling",
        updateError: "There was an error updating the appointment, please try again"
    },
    Cancellation: {
        text: "We're here to help when you need it. Feel free to schedule another appointment with one of our product experts when you're ready.",
        learnAboutAppointments: "Learn about the Microsoft Experience Center",
        learnAboutAppointmentsOnline: "Learn about online appointments",
        appointmentCanceled: "Appointment with confirmation number {confirmno} was canceled."
    },
    DateAndTime: {
        chooseDate: "Step 3: Choose a day and time",
        dateFormat: "dddd, MMMM D, YYYY",
        daysInAdvance: "Appointments can only be booked 14 days in advance",
        timezone: "Time zone",
        noAvailableAppointments:
            "Sorry, but we currently have no further appointments available on this day. Please choose a different day or check back for possible cancellations.",
        availableTimes: "Available times",
        availableTimeLabel: "Available time ",
        datePickerLabel: "Choose a day",
        datePickerAriaLabel: "Choose a day required calendar picker",
        timezoneLabel: "Your time zone",
        requiredLabel: "required",
        selectedLabel: "selected"
    },
    ManageAppointment: {
        provideConfirmationNumber:
            "Please provide the confirmation number to find your existing appointment. The confirmation number is in the email we sent after you scheduled your appointment.",
        confirmationNumberMissing: "Please enter a confirmation number.",
        appointmentSummary: "Your appointment summary",
        confirmationNumber: "Confirmation number *",
        searchAppointment: "Search for appointment",
        required: "Required",
        topic: "Topic",
        appointmentCanceled: "This appointment has been canceled and can no longer be modified.",
        appointmentCompleted: "This appointment is completed and can no longer be modified.",
        appointmentAlreadyStarted: "This appointment has already started and can no longer be modified.",
        appointmentFound: "Appointment with confirmation number {confirmno} was found."
    },
    Confirmation: {
        confirmText: "We’ve sent a confirmation email to {email}. If you don’t see it yet, please wait a few minutes or check your spam folder.",
        instructionsTextOnline: 'Click the "Join your appointment" link in your confirmation email when it’s time to join.',
        instructionsText:
            "The email explains what to expect from your in-person appointment and includes the time and location. We look forward to helping you.",
        audioCall:
            "The link opens your appointment in Microsoft Teams. You can join via desktop browser or smartphone. Feel free to turn off your camera if you’d prefer an audio call. We look forward to helping you.",
        confirmationNumber: "Confirmation number",
        manageAppointment: "Manage appointment",
        addToCalendar: "Add to calendar",
        bookAnotherAppointment: "Book another appointment",
        unableToFindAppointment: "Unable to find appointment with given id, please try again",
        confirmImageAltText: "A team of Microsoft product experts."
    },
    PersonalInfo: {
        personalInformation: "Step 4: Enter your information",
        provideInfo: "Please provide the requested information below to confirm your appointment.",
        firstName: "First name",
        firstNamePlaceholder: "First name",
        lastName: "Last name",
        lastNamePlaceholder: "Last name",
        email: "Email",
        emailPlaceholder: "email@email.com",
        emailError: "Please enter a valid email address.",
        confirmEmail: "Confirm email",
        confirmEmailTooltip:
            "Please type the email address you provided again to confirm that it has been entered correctly. Microsoft will use this address to send a meeting link to join your appointment.",
        confirmEmailError: "The email addresses do not match. Please check your entry and retype the email address.",
        phone: "Phone",
        phonePlaceholder: "555-555-5555",
        phoneError: "Please enter a valid phone number.",
        whatCanWeHelpWith: "Anything else we should know?",
        whatCanWeHelpWithPlaceholder: "Tell us more about what you need from this appointment.",
        whatCanWeHelpWithOnlinePlaceholder: "Tell us more about what you need from this online appointment.",
        agreeToTerms: "I agree to the {serviceAgreement} terms for this appointment and confirm I am over the age of 18",
        serviceAgreement: "Microsoft Customer Support Service Agreement",
        nextStepsOnline1: "Microsoft will use the email address you provided to send you a confirmation email with a ",
        nextStepsOnline2: "Microsoft Teams video link",
        nextStepsOnline3: ", as well as reminder emails. ",
        nextStepsOnline4: "We will not call you; this is a video appointment. ",
        nextStepsOnline5: "You’ll also get a request to fill out a survey after your appointment. Learn more in ",
        nextSteps:
            "Microsoft will use the email address you provided to send you a confirmation email with details about your appointment, as well as reminder emails. You’ll also get a request to fill out a survey after your appointment. Learn more in ",
        communicateDetails: "communicate the details and steps you’ll need to take for your online appointment with a product expert",
        privacyAndCookies: "Privacy and Cookies",
        requiredFields: "Required fields",
        requiredFieldError: "This field is required.",
        true: "True",
        false: "False",
        select: "Select",
        disabilityAccommodation: "I require disability-related accommodation",
        describeAssistance:
            "Please describe the assistance you require, and our team will reach out to discuss how we can best accommodate your needs",
        smsCustomerOptIn: "Send me appointment reminders via SMS (US/CAN numbers only). Message and Data rates may apply.",
        supportText:
            "This appointment is for {appointmentType} only. If you need technical support or have questions related to the activation errors for a product, please visit {microsoftSupport} for help.",
        microsoftSupport: "Microsoft Support"
    },
    TypeTopic: {
        chooseTopic: "Step 2: Choose a primary topic",
        chooseType: "Step 1: Pick an appointment type",
        noServices: "There are no services available for the provided storeid.",
        typeLabels: {
            hardwareSupport: "Servicing and device support",
            personalShopping: "Shopping",
            personalTraining: "Training",
            personalSetupTraining: "Setup & training",
            personalSetup: "Setup",
            personalTrainingBusiness: "Business training",
            businessConsultation: "Business consultation",
            educationConsultation: "Education consultation",
            lenovo: "Lenovo"
        },
        topicLabels: {
            hardwareSupport: "What kind of support do you need?",
            personalShopping: "What can we help you shop for?",
            personalTraining: "What kind of training do you need?",
            personalSetup: "What can we help you set up?",
            personalSetupTraining: "What kind of setup help or training do you need?",
            personalTrainingBusiness: "What kind of training do you need?",
            businessConsultation: "How can we help?",
            educationConsultation: "How can we help?",
            lenovo: "What can we help you with?"
        },
        buttonLabels: {
            hardwareSupport: "Book an appointment",
            personalShopping: "Get shopping advice",
            personalTraining: "Get training",
            personalSetup: "Get setup help",
            personalSetupTraining: "Get setup help or training",
            personalTrainingBusiness: "Get business product training",
            businessConsultation: "Book a business consultation",
            educationConsultation: "Book an education consultation",
            lenovo: "Get Windows 8.1 end of support help"
        },
        friendlyLabels: {
            hardwareSupport: "servicing and device support",
            personalShopping: "shopping help",
            personalTraining: "essential training",
            personalSetup: "setup help",
            personalSetupTraining: "setup or training",
            personalTrainingBusiness: "business training",
            businessConsultation: "a business consultation",
            educationConsultation: "an education consultation",
            lenovo: "Windows end of support"
        }
    },
    Review: {
        title: "Review and confirm your appointment",
        edit: "Edit",
        editType: "Edit the appointment type",
        editTopic: "Edit the topic",
        editDateTime: "Edit the day and time",
        editPersonalInfo: "Edit your information",
        confirm: "Confirm",
        customResponsesLabel: "This appointment will cover:"
    },
    Store: {
        chooseStore: "Choose store"
    },
    Maintenance: {
        message: "Our appointment reservations system is temporarily unavailable. Please try again later."
    }
};
