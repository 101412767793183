import React, { FC, FormEvent, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Checkbox, DefaultButton, IconButton, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";

import { IRapComponentProperties } from "../../../../../../platform/Layout";
import { IDashboardViewState } from "../../../../../../pages/Dashboard/Contracts";
import { CancelButton } from "../CancelButton/CancelButton";
import { RecaptchaButton } from "../../../../../../common/components/RecaptchaButton/RecaptchaButton";
import { localizedStrings } from "../../../../../../common/localization/LocalizedStrings";
import { cleanAppointmentDataForTelemetry } from "../../../../../../common/Util";
import { AppointmentForUpdateDto, IAppointment, OptionSetAttribute } from "../../../../../../contracts/swagger/_generated";
import { AppointmentsActions } from "../../../../redux/AppointmentsActions";

import * as FeatureManagementSelectors from "../../../../../FeatureManagement/redux/FeatureManagementSelectors";
import * as AppointmentSelectors from "../../../../redux/AppointmentsSelectors";
import * as Constants from "../../../../../../common/Constants";


export interface ICancellationState {
    cancellationReasonList?: string[];
    customCancellationReason?: string;
    customReasonChecked?: boolean;
    customReasonError?: string;
}

interface ICancelModalProvidedProps extends IRapComponentProperties {

}

interface ICancelModalOwnProps {
    displayCancellationModal?: boolean;
    isCancellationReasonEnabled?: boolean;
}

// eslint-disable-next-line
export type ICancelModalProps = ConnectedProps<typeof connector> & ICancelModalProvidedProps;

const CancelModalInitializer: FC<ICancelModalProps> = (props) => {
    const [ cancellationState, setCancellationState ] = useState<ICancellationState>({});

    const onChangeCancellationReason = (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        const reason = ev ? ev.currentTarget.title : "";
        const newList = cancellationState.cancellationReasonList ? cancellationState.cancellationReasonList : [];
        if (checked) {
            newList.push(reason);
        } else {
            const index = newList.indexOf(reason);
            newList.splice(index, 1);
        }

        setCancellationState({ ...cancellationState, cancellationReasonList: newList });
    }

    const toggleCustomReason = (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setCancellationState({ ...cancellationState, customReasonChecked: checked });
    }

    const onChangeCancellationReasonInput = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const isNewValueValid = newValue && newValue.length > 0;
        if (cancellationState.customReasonError && cancellationState.customReasonError.length > 0 && isNewValueValid) {
            setCancellationState({ ...cancellationState, customCancellationReason: newValue, customReasonError: ""});
        } else {
            setCancellationState({ ...cancellationState, customCancellationReason: newValue });
        }
    }

    const getCancellationReasons = () => {
        return (
            <Stack tokens={{childrenGap: 12}}>
                <Checkbox label={localizedStrings.AppointmentManager?.cancelOption1} title={localizedStrings.AppointmentManager?.cancelOption1} onChange={onChangeCancellationReason} />
                <Checkbox label={localizedStrings.AppointmentManager?.cancelOption2} title={localizedStrings.AppointmentManager?.cancelOption2} onChange={onChangeCancellationReason} />
                <Checkbox label={localizedStrings.AppointmentManager?.cancelOption3} title={localizedStrings.AppointmentManager?.cancelOption3} onChange={onChangeCancellationReason} />
                <Checkbox label={localizedStrings.AppointmentManager?.cancelOption4} title={localizedStrings.AppointmentManager?.cancelOption4} onChange={onChangeCancellationReason} />
                <Checkbox label={localizedStrings.AppointmentManager?.cancelOption5} title={localizedStrings.AppointmentManager?.cancelOption5} onChange={onChangeCancellationReason} />
                <Checkbox label={localizedStrings.AppointmentManager?.cancelOption6} title={localizedStrings.AppointmentManager?.cancelOption6} onChange={onChangeCancellationReason} />
                <Checkbox label={localizedStrings.AppointmentManager?.cancelOption7} onChange={toggleCustomReason} />
                <div className={"c-cancel-input-container"}>
                    <div id="c-cancel-input-label">{localizedStrings.AppointmentManager?.reasonForCancel}</div>
                    <TextField 
                        multiline
                        autoAdjustHeight
                        resizable={false}
                        rows={3} 
                        value={cancellationState.customCancellationReason}
                        maxLength={Constants.MultilineTextFieldCharacterLimit}
                        onChange={onChangeCancellationReasonInput}
                        disabled={!cancellationState.customReasonChecked}
                        className={"c-cancel-input"}
                        placeholder={localizedStrings.AppointmentManager?.tellUsMore}
                        aria-labelledby={"c-cancel-input-label"}
                    />
                </div>
            </Stack>
        )
    }
    
    return (
        <Modal
            isOpen={props.displayCancellationModal}
            containerClassName={"c-modal-container"}
            titleAriaId={"modalHeader"}
        >
            <div>
                <div className="c-modal-header-container">
                    <span id="modalHeader">{localizedStrings.AppointmentManager?.modalHeader}</span>
                    <IconButton
                        className={"c-modal-icon"}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel={localizedStrings.AppointmentManager?.close}
                        onClick={() => props.updateModals({ displayCancellationModal: false })}
                    />
                </div>
                <p id="modalDescription">{localizedStrings.AppointmentManager?.modalDescription}</p>
                {props.isCancellationReasonEnabled && (
                    <div className={"c-cancellation-reasons"}>
                        <div className="c-reasons-header">
                            {localizedStrings.AppointmentManager?.pleaseShareWhy}
                        </div>
                        {getCancellationReasons()}
                    </div>
                )}
                <div className={"c-modal-buttons"}>
                    <DefaultButton 
                        text={localizedStrings.AppointmentManager?.back}
                        onClick={() => props.updateModals({ displayCancellationModal: false })} 
                        className={"c-back-button"} 
                    />
                    <CancelButton cancellationState={cancellationState}/>
                </div>
            </div>  
        </Modal>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: ICancelModalProvidedProps): Partial<ICancelModalOwnProps> {
    return {
        ...providedProps,
        displayCancellationModal: AppointmentSelectors.getModals(state)?.displayCancellationModal,
        isCancellationReasonEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableCancellationReason", false),
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: AppointmentsActions.updateModals
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const CancelModal = connector(CancelModalInitializer);