import React from "react";
import { connect } from "react-redux";
import { RapComponent, IRapComponentProperties } from "../../../../platform/Layout";
import { IDashboardViewState } from "../../../../pages/Dashboard/Contracts";
import { AppointmentsActions } from "../../redux/AppointmentsActions";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import moment from "moment-timezone";
import { DatePicker, Spinner, SpinnerSize } from "@fluentui/react";
import TimezoneSelect, { allTimezones, ITimezoneOption } from "react-timezone-select";
import { DefaultButtonGroup, IOption } from "../../../../common/components/DefaultButtonGroup/DefaultButtonGroup";
import * as AppointmentsSelectors from "../../redux/AppointmentsSelectors";
import * as FeatureManagementSelectors from "../../../FeatureManagement/redux/FeatureManagementSelectors";
import * as Constants from "../../../../common/Constants";
import { AppointmentsFeature } from "../../../../common/Constants";

import "./AppointmentDateTime.scss";
import { AppointmentTopicCustomQuestion, IAppointment, IAppointmentForCreationDto, IAppointmentServiceType, ITimeSlotDto, TimeSlotDto } from "../../../../contracts/swagger/_generated";
import { Accordion } from "../../../../common/components/Accordion/Accordion";
import { AppointmentStages, IAccordionState, ITelemetryAttributes } from "../../Contracts";
import { timeoutFunction, isStoreMec } from "../../AppointmentsHelper";
import { announce } from "../../../../platform/core/util/Accessibility";

const timeslotButtonAttributes: ITelemetryAttributes = {
    dataBiBhvr: Constants.CheckpointBehavior1DS,
    dataBiScn: Constants.BookAppointmentScenario,
    dataBiScnstp: Constants.DateTimeStep,
    dataBiStpnum: 4
};

interface IAppointmentDateTimeState {
    selectedDate?: string;
    selectedTime?: string;
    selectedTimezone: string;
    selectedBookableResourceId?: string;
    timeOptions?: IOption[];
    restrictedDates?: Date[];
}

// Props passed by parent component
interface IAppointmentDateTimeProvidedProps extends IRapComponentProperties {
    onSelect?: (date: Date, formattedDate: string, bookableResourceId?: string) => void;
    flow: "Schedule" | "Manage";
}

// Props mapped from state object
interface IAppointmentDateTimeInitializerOwnProps extends IAppointmentDateTimeProvidedProps {
    timeslots: Date[] | ITimeSlotDto[];
    areTimeslotsLoading: boolean;
    isTimeZonePickerEnabled: boolean;
    lastSelectedTimezone: string;
    enableAutoScheduling?: boolean;
    storeId: number;
    appointment: IAppointmentForCreationDto | IAppointment;
    accordions: IAccordionState;
    serviceTypes: IAppointmentServiceType[];
    isDateValid: boolean;
    customQuestions: AppointmentTopicCustomQuestion[];
    selectedTime?: string; 
}

export type IAppointmentDateTimeInitializerProps = IAppointmentDateTimeInitializerOwnProps & typeof ActionsToDispatch;

class AppointmentDateTimeInitializer extends RapComponent<IAppointmentDateTimeInitializerProps, IAppointmentDateTimeState> {
    constructor(props: IAppointmentDateTimeInitializerProps) {
        super(props);
        this.props.logTelemetry(AppointmentsFeature.DateTimePage, "Initializing datetime page");
        const initialTimezone = this.props.lastSelectedTimezone ? this.props.lastSelectedTimezone : moment.tz.guess();
        this.state = { selectedTimezone: initialTimezone }
        this.props.updateSelectedTimezone(this.state.selectedTimezone);
        this.props.logTelemetry(AppointmentsFeature.DateTimePage, "Rendered datetime page ", 
            this.props.storeId,
            {
                state: this.state, 
                props: { 
                    appointmentTypeId: this.props.appointment.appointmentTypeId, 
                    duration: this.props.appointmentTypeDuration, 
                    initialDateTime: this.props.appointment?.appointmentDate
                }
            } 
        );
    }

    componentDidUpdate(prevProps: IAppointmentDateTimeInitializerProps) {
        //fetch timeslots when typeId updates
        if (this.props.appointment.appointmentTypeId && this.props.appointment.appointmentTypeId !== prevProps.appointment.appointmentTypeId && this.props.enableAutoScheduling !== undefined) {
            this.props.fetchAppointmentTimeslots(this.props.storeId, this.props.appointment.appointmentTypeId);
        }

        //fetch timeslots when feature flag updates
        if(prevProps.enableAutoScheduling !== this.props.enableAutoScheduling && this.props.appointment.appointmentTypeId) {
            this.props.fetchAppointmentTimeslots(this.props.storeId, this.props.appointment.appointmentTypeId);
        }

        if (this.props.timeslots && this.props.timeslots !== prevProps.timeslots) {
            this.getRestrictedDates();

            if(!this.state.selectedDate) {
                this.getDefaultDate();
            } else {
                // If user selected a date before the timeslots were fetched
                this.getTimes();
            }
        }

        if(this.props.isDateValid && !this.props.selectedTime) {
            this.props.updateIsDateValid(false);
            this.props.updateAccordions({...this.props.accordions, isInfoOpen: true});
        }
    }

    public componentDidMount(): void {
        if(!this.props.timeslots && this.props.appointment.appointmentTypeId && this.props.enableAutoScheduling !== undefined) {
            this.props.fetchAppointmentTimeslots(this.props.storeId, this.props.appointment.appointmentTypeId);
        }
    }


    private getTimes = () => {
        const { timeslots } = this.props;
        const { selectedDate, selectedTime, selectedTimezone } = this.state;
        let times: IOption[] = [];

        if (!selectedDate) {
            this.setState({ timeOptions: undefined });
            return;
        }

        let startOfSelectedDay = moment(selectedDate).tz(selectedTimezone).startOf("day");
        if (moment().tz(selectedTimezone).startOf("day").isSame(startOfSelectedDay)) {
            // If the selected day is the current day, we need to use a buffered time
            startOfSelectedDay = moment().tz(selectedTimezone).add(Constants.TimeslotBuffer, "minutes");
        }
        const endOfSelectedDay = moment(selectedDate).tz(selectedTimezone).endOf("day");
        if (timeslots) {
            let loggedFirstTimeslot = false; //flag to keep track of whether we've already logged the first timeslot;
            timeslots.forEach((timeslot: Date | ITimeSlotDto) => {
                if(timeslot instanceof Date && 
                    moment(timeslot).tz(selectedTimezone) >= startOfSelectedDay && 
                    moment(timeslot).tz(selectedTimezone) < endOfSelectedDay
                ) {
                    if(!loggedFirstTimeslot) {
                        this.props.logTelemetry(AppointmentsFeature.DateTimePage, "First available slot rendered", 
                            this.props.storeId, 
                            { firstAvailableSlot: timeslot.toUTCString() }
                        );
                        loggedFirstTimeslot = true;
                    }
                    let value = moment(timeslot).tz(selectedTimezone).format(Constants.timeFormat)
                    times.push(
                        {
                            value: value,
                            optionalValue2: timeslot.toUTCString(),
                            ariaLabel: `${localizedStrings.DateAndTime?.availableTimeLabel} ${value}`
                        }
                    ); 
                }
                else if(timeslot instanceof TimeSlotDto && 
                    timeslot.startTime && 
                    moment(timeslot.startTime).tz(selectedTimezone) >= startOfSelectedDay && 
                    moment(timeslot.startTime).tz(selectedTimezone) < endOfSelectedDay
                ) {
                    if(!loggedFirstTimeslot) {
                        this.props.logTelemetry(AppointmentsFeature.DateTimePage, "First available slot rendered", 
                            this.props.storeId, 
                            { firstAvailableSlot: timeslot.startTime?.toUTCString() }
                        );
                        loggedFirstTimeslot = true;
                    }
                    let value = moment(timeslot.startTime).tz(selectedTimezone).format(Constants.timeFormat);
                    if(timeslot.users && timeslot.users.length > 0) {
                        times.push(
                            {
                                value: value,
                                optionalValue1: timeslot.users[0].bookableResourceId,
                                optionalValue2: timeslot.startTime.toUTCString(),
                                ariaLabel: `${localizedStrings.DateAndTime?.availableTimeLabel} ${value}`
                            }
                        ); 
                    }
                    else {
                        times.push(
                            {
                                value: value,
                                optionalValue2: timeslot.startTime.toUTCString(),
                                ariaLabel: `${localizedStrings.DateAndTime?.availableTimeLabel} ${value}`
                            }
                        ); 
                    }
                }                 
            });
        }
        if (selectedTime) {
            let found = false;
            times.forEach((time: IOption) => {
                if(time.value === selectedTime) {
                    found = true;
                }
            });

            if(!found) {
                if(times.length > 0) {
                    this.setState({ selectedTime: times[0].value});
                } else {
                    this.setState({ selectedTime: undefined });
                }
            }
        }
        this.setState({ timeOptions: times });
    }

    private onTimezoneChange = (timezone: ITimezoneOption) => {
        this.props.logTelemetry(AppointmentsFeature.DateTimePage, "Timezone changed: " + timezone.value, this.props.storeId);
        const oldDate = moment.tz(this.state.selectedDate, this.state.selectedTimezone).format(Constants.dateFormat);        
        this.setState({
            selectedTimezone: timezone.value,
            selectedDate: moment.tz(oldDate, timezone.value).format(),
            selectedTime: undefined
        }, () => {
            this.getTimes();
            this.getRestrictedDates();
        });
        this.props.updateSelectedTimezone(timezone.value);
        this.props.resetSelectedTime();
    }

    private onDatePress = (date?: Date) => {
        const dateStr = moment(date).format(Constants.dateFormat);
        if(date !== this.state.selectedDate) {
            this.props.logTelemetry(AppointmentsFeature.SelectDate, "Appointment date clicked: " + date?.toDateString(), this.props.storeId);
            this.setState({
                selectedDate: moment.tz(dateStr, this.state.selectedTimezone).format(),
                selectedTime: undefined
            }, () => {
                this.getTimes();
            });
        }
    }

    private onTimePress = (val: IOption) => {
        if(val.value !== this.state.selectedTime) {
            this.props.logTelemetry(AppointmentsFeature.SelectTime, "Appointment time clicked: " + val.value, this.props.storeId);
            this.setState({
                selectedTime: val.value, 
                selectedBookableResourceId: val.optionalValue1
            }, () => this.schedulerCallback(val.optionalValue2 as string));
        }
    }

    private getAppointmentTypeDuration = () => {
        if(this.props.flow === "Schedule") {
            if(this.props.serviceTypes && this.props.appointment.appointmentTypeId) {
                return this.props.serviceTypes.find(type => type.typeId === this.props.appointment.appointmentTypeId)!.duration;
            }
            return 0;
        }
        else {
            const { appointmentDate, scheduledEndDate } = this.props.appointment as IAppointment;
            return Math.floor(((Math.abs(scheduledEndDate!.valueOf() - appointmentDate!.valueOf()))/1000)/60);
        }
    }

    private updateScheduleFlow = (date: Date, formattedDate: string, bookableResourceId?: string) => {
        this.props.updateAppointment(AppointmentStages.DATE_TIME, {
            ...this.props.appointment as IAppointmentForCreationDto,
            appointmentDate: date, 
            formattedAppointmentDate: formattedDate,
            bookableResourceId: bookableResourceId
        });

        // Pause for 500ms to allow the user to see the timeslot has been selected before collapsing the accordion
        setTimeout(() => {
            this.props.updateAccordions({...this.props.accordions, isDateOpen: false });
        }, 1000);

        this.props.updateIsDateValid(true);

        timeoutFunction(
            () => {
                if(this.props.customQuestions) {
                    return true;
                }
                return false;
            },
            () => {
                this.props.updateAccordions({ ...this.props.accordions, isInfoOpen: true });
            }, 
            1000, 
            100,
            10, 
            localizedStrings.AppointmentScheduler?.unableToLoadQuestions as string
        )
    }

    private schedulerCallback = (dateTime: string) => {
        const dateFormat = localizedStrings.DateAndTime?.dateFormat;
        const { selectedDate, selectedTime, selectedTimezone } = this.state;
        if (selectedDate && selectedTime) {
            const start = moment.tz(dateTime, selectedTimezone);
            const end = moment.tz(dateTime, selectedTimezone).add(this.getAppointmentTypeDuration(), "minutes");

            // Build formatted time
            const timezoneOffset = `(GMT${start.format("Z")})`;
            const timezoneName = allTimezones[selectedTimezone] ? allTimezones[selectedTimezone] : start.zoneAbbr();
            const formattedDate = `${start.format(dateFormat)} ${start.format(Constants.timeFormat)} - ${end.format(Constants.timeFormat)} ${timezoneOffset} ${timezoneName}`;
            
            this.props.logTelemetry(AppointmentsFeature.DateTimePage, "Setting new time: " + formattedDate, this.props.storeId);
            if(this.props.flow === "Schedule") {
                this.updateScheduleFlow(start.toDate(), formattedDate, this.state.selectedBookableResourceId)
            }
            else if(this.props.flow === "Manage" && this.props.onSelect) {
                this.props.onSelect(start.toDate(), formattedDate, this.state.selectedBookableResourceId);
            }
            this.props.updateSelectedDate(selectedDate);
            this.props.updateSelectedTime(selectedTime);
        }
    }

    private getMinDate = () => {
        return new Date(moment().tz(this.state.selectedTimezone).startOf("day").format(Constants.dateTimeFormat));
    }

    private getAppointmentDuration = () => {
        return isStoreMec(this.props.storeId) ? Constants.MecDaysInAdvance : Constants.VirtualStoreDaysInAdvance;
    }

    private getMaxDate = () => {        
        const duration = this.getAppointmentDuration();
        return new Date(moment().tz(this.state.selectedTimezone).startOf("day").add(duration, "days").format(Constants.dateTimeFormat));
    }

    private getDateValueFromTimeslot = (timeslot: Date|ITimeSlotDto) => {
        const { selectedTimezone } = this.state;
        
        if (timeslot instanceof Date) {
            return moment(timeslot).tz(selectedTimezone).startOf("day").format(Constants.dateFormat);
        } else if (timeslot instanceof TimeSlotDto) {
            return moment(timeslot.startTime).tz(selectedTimezone).startOf("day").format(Constants.dateFormat);
        }

        return "";
    }

    private getDefaultDate = () => {
        if(!this.props.timeslots || this.props.timeslots.length < 1)
            return;
        
        // If no date has been selected yet, we will show the first date with an available time slot by default
        const firstAvailableSlot = moment(this.getDateValueFromTimeslot(this.props.timeslots[0])).tz(this.state.selectedTimezone).startOf("day");
        const dateStr = moment.tz(firstAvailableSlot, this.state.selectedTimezone).format(Constants.dateFormat);
        this.setState({
            selectedDate: moment.tz(dateStr, this.state.selectedTimezone).format(),
            selectedTime: undefined
        }, () => {
            this.getTimes();
        });
    }

    private getRestrictedDates = () => {
        const { selectedTimezone } = this.state;
        if (!this.props.timeslots) {
            this.setState({ restrictedDates: undefined });
            return;
        }
        let restrictedDates = [];

        // Get date value from timeslots
        const timeslotDates = (this.props.timeslots as Array<Date|ITimeSlotDto>).map(this.getDateValueFromTimeslot);
        const uniqueTimeslotDates = new Set(timeslotDates);

        const today = moment().tz(selectedTimezone).startOf("day");
        const duration = this.getAppointmentDuration();
        for (let i = 0; i <= duration; i++) {
            const dateMoment = moment(today).tz(selectedTimezone).add(i, "days").startOf("day");
            const dateStr = dateMoment.format(Constants.dateFormat);
            if (!uniqueTimeslotDates.has(dateStr)) {
                restrictedDates.push(new Date(dateMoment.format(Constants.dateTimeFormat)));
            }            
        }

        this.setState({ restrictedDates: restrictedDates});
    }

    private onRenderTimeslots = () => {
        if (this.props.areTimeslotsLoading && this.state.selectedDate) {
            return <div className="c-spinner-container"><Spinner size={SpinnerSize.large}/></div>;
        } else {
            if (this.props.timeslots && this.state.selectedDate) {
                return this.state.timeOptions && this.state.timeOptions.length > 0 
                    ?  (
                        <div className="c-timeslot-container">
                            <div className="c-timeslot-label">{`${localizedStrings.DateAndTime?.availableTimes} *`}</div>
                            <DefaultButtonGroup
                                options={this.state.timeOptions}
                                onPress={this.onTimePress}
                                defaultSelected={this.state.selectedTime}
                                telemetryAttributes={this.props.flow === "Schedule" ? {
                                    ...timeslotButtonAttributes,
                                    dataBiField3: moment(this.state.selectedDate).format("MMM DD, YYYY")
                                } : undefined}
                            />
                       </div>
                    ) : (
                        <></>
                    )
            } else {
                return <></>;
            }
        }
    }

    private getContent = () => {
             return (
            <div className="c-date-container">
                <div className="c-date-container-buttons">
                    <div className="c-datepicker-container">
                        <label htmlFor="datepicker">{`${localizedStrings.DateAndTime?.datePickerLabel} *`}</label>
                        <DatePicker
                            id={"datepicker"}
                            placeholder={localizedStrings.DateAndTime?.datePickerLabel}
                            ariaLabel={localizedStrings.DateAndTime?.datePickerAriaLabel}
                            value={this.state.selectedDate ? new Date(this.state.selectedDate) : undefined}
                            onSelectDate={this.onDatePress}
                            onAfterMenuDismiss={() => 
                                announce(`${this.state.selectedDate ? new Date(this.state.selectedDate).toDateString() : ""} ${localizedStrings.DateAndTime?.selectedLabel}`)
                            }
                            isMonthPickerVisible={false}
                            minDate={this.getMinDate()}
                            maxDate={this.getMaxDate()}
                            calendarProps={{ restrictedDates: this.state.restrictedDates }}
                            className="c-datepicker"
                        />
                    </div>
                    <div className="c-timezone-dropdown-container">
                        <label htmlFor="timezonepicker">{localizedStrings.DateAndTime?.timezoneLabel}</label>
                        <TimezoneSelect
                            id="timezonepicker"
                            value={this.state.selectedTimezone}
                            onChange={this.onTimezoneChange}
                            timezones={allTimezones}
                            className={"c-timezone-dropdown"}
                            aria-label={localizedStrings.DateAndTime?.timezone}
                        />
                    </div>
                </div>
                <div aria-live="polite">{this.onRenderTimeslots()}</div>
                <div className="c-date-time-required">{localizedStrings.AppointmentScheduler?.requiredText}</div>
            </div> 
        )
    }

    public render() {
        return (
            <>
                {this.props.flow === "Schedule" ? (
                    <Accordion 
                        title={localizedStrings.DateAndTime?.chooseDate} 
                        isOpen={this.props.accordions.isDateOpen} 
                        onClick={() => this.props.updateAccordions({...this.props.accordions, isDateOpen: !this.props.accordions.isDateOpen})}
                        disabled={(this.props.appointment as IAppointmentForCreationDto).appointmentCategoryId === ""}
                        isValid={this.props.isDateValid}
                    >
                        {this.props.accordions.isDateOpen && this.props.accordions.isDateOpen === true && (
                            <div className="c-accordian-date">
                                {this.getContent()}
                            </div>  
                        )}
                    </Accordion>
                ) : (
                    <div className="c-standard-date">
                        {this.getContent()}
                    </div>
                )}
            </>
        )
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IAppointmentDateTimeProvidedProps): Partial<IAppointmentDateTimeInitializerOwnProps> {
    return {
        ...providedProps,
        timeslots: AppointmentsSelectors.getAppointmentTimeslots(state),
        areTimeslotsLoading: AppointmentsSelectors.getAreTimeslotsLoading(state),
        isTimezonePickerEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableTimezonePicker", false),
        lastSelectedTimezone: AppointmentsSelectors.getSelectedTimezone(state),
        enableAutoScheduling: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableScheduleAutoAssignment"),
        storeId: providedProps.flow === "Schedule" ? 
            AppointmentsSelectors.getValidatedStoreId(state) : 
            AppointmentsSelectors.getSelectedAppointment(state)?.storeNumber,
        appointment: providedProps.flow === "Schedule" ? 
            AppointmentsSelectors.getSchedulerAppointment(state) : 
            AppointmentsSelectors.getSelectedAppointment(state),
        accordions: AppointmentsSelectors.getSchedulerAccordions(state),
        serviceTypes: AppointmentsSelectors.getAppointmentServiceTypes(state),
        isDateValid: AppointmentsSelectors.getSchedulerIsDateValid(state),
        customQuestions: AppointmentsSelectors.getTopicCustomQuestions(state),
        selectedTime: AppointmentsSelectors.getSchedulerSelectedTime(state)
    };
}

const ActionsToDispatch = {
    logTelemetry: AppointmentsActions.logTelemetry,
    fetchAppointmentTimeslots: AppointmentsActions.fetchAppointmentTimeslots,
    updateSelectedTimezone: AppointmentsActions.updateSelectedTimezone,
    updateSelectedDate: AppointmentsActions.updateManagerSelectedDate,
    updateSelectedTime: AppointmentsActions.updateManagerSelectedTime,
    resetSelectedTime: AppointmentsActions.resetSchedulerSelectedTime,
    updateAppointment: AppointmentsActions.updateSchedulerAppointment,
    updateAccordions: AppointmentsActions.updateSchedulerAccordions,
    updateIsDateValid: AppointmentsActions.updateSchedulerIsDateValid
}

const mapDispatchToProps = (dispatch: any, props: IAppointmentDateTimeInitializerProps) => {
    return {
        logTelemetry: (
            feature: Constants.AppointmentsFeature, 
            action: string, 
            storeId?: number | undefined, 
            properties?: {
                [key: string]: any;
            } | undefined, 
            errorMessage?: string | undefined) => 
                dispatch(AppointmentsActions.logTelemetry(feature, action, storeId, properties, errorMessage)),

        fetchAppointmentTimeslots: (storeId: number, serviceTypeId: string) => 
            dispatch(AppointmentsActions.fetchAppointmentTimeslots(storeId, serviceTypeId)),

        updateSelectedTimezone: (timezone: string) => dispatch(AppointmentsActions.updateSelectedTimezone(timezone)),
        updateSelectedDate: props.flow === "Schedule" ? 
            (date: string) => dispatch(AppointmentsActions.updateSchedulerSelectedDate(date)) :
            (date: string) => dispatch(AppointmentsActions.updateManagerSelectedDate(date)),

        updateSelectedTime: props.flow === "Schedule" ?
            (time: string) => dispatch(AppointmentsActions.updateSchedulerSelectedTime(time)) :
            (time: string) => dispatch(AppointmentsActions.updateManagerSelectedTime(time)),
        resetSelectedTime: () => dispatch(AppointmentsActions.resetSchedulerSelectedTime()),
        updateAccordions: (acc: IAccordionState) => dispatch(AppointmentsActions.updateSchedulerAccordions(acc)),
        updateAppointment: (stage: AppointmentStages, appt: IAppointmentForCreationDto) => dispatch(AppointmentsActions.updateSchedulerAppointment(stage, appt)),
        updateIsDateValid: (val: boolean) => dispatch(AppointmentsActions.updateSchedulerIsDateValid(val))
    }
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(AppointmentDateTimeInitializer);