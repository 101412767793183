import produce from "immer";

import { IPageViewState, NavMenuItem, UserRole } from "../Contracts";
import { PageActionsType, PageActionTypes } from "./PageActions";

export function pageReducer(state: IPageViewState, action: PageActionsType): IPageViewState {
    return produce(state || {}, draft => {
        switch (action.type) {
            case PageActionTypes.InitPage: {
                draft.navMenuItems = createNavMenuItems();
                draft.settingsMenuItems = createSettingsMenuItems();
                addMaintenanceMessage(draft);
                break;
            }
            case PageActionTypes.UpdateSelectedNavItem: {
                draft.selectedNavMenuItemId = action.payload;
                resetSettingsPanels(draft);
                if (draft.sideDrawerOpen) {
                    draft.sideDrawerOpen = false;
                }
                break;
            }
            case PageActionTypes.SettingsMenuItemClicked: {
                const menuId = action.payload;
                if (draft.settingsMenuOpen) {
                    draft.settingsMenuOpen = false;
                }

                resetSettingsPanels(draft);

                switch (menuId) {
                    case 0:
                        draft.displayAdminSettingsPanel = true;
                        break;
                    case 1:
                        draft.displayUserSettingsPanel = true;
                        break;
                }

                break;
            }
            case PageActionTypes.ToggleSideDrawer: {
                draft.sideDrawerOpen = !draft.sideDrawerOpen;
                break;
            }
            case PageActionTypes.ToggleSettingsMenu: {
                draft.settingsMenuOpen = !draft.settingsMenuOpen;
                break;
            }
            case PageActionTypes.FetchPageDataSuccess: {
                const pageData = action.payload;
                draft.pageData = pageData;
                draft.userRole = pageData.isOwner ? UserRole.Owner : UserRole.Member;
                if (pageData.isAdmin) {
                    draft.userRole = UserRole.Admin;
                }
                break;
            }
            case PageActionTypes.FetchPageDataFailure: {
                draft.errorMessage = action.payload;
                break;
            }
            case PageActionTypes.OnCloseAdminSettingsPanel: {
                draft.displayAdminSettingsPanel = false;
                break;
            }
            case PageActionTypes.OnCloseUserSettingsPanel: {
                draft.displayUserSettingsPanel = false;
                break;
            }
            case PageActionTypes.OverrideUserRole: {
                draft.userRole = action.payload;
                break;
            }
            case PageActionTypes.DismissMaintenanceMessageBar: {
                draft.isMaintenanceMessageBarOpen = false;
                break;
            }
            default:
                draft = state;
        }
    });
}

function createNavMenuItems(): NavMenuItem[] {
    let menuItems: NavMenuItem[] = [];

    menuItems.push({
        id: 0,
        title: "Activities",
        iconName: "Teamwork",
        minimumRequiredUserRole: UserRole.Member
    });

    return menuItems;
}

function createSettingsMenuItems(): NavMenuItem[] {
    let menuItems: NavMenuItem[] = [];

    menuItems.push({
        id: 0,
        title: "Admin Settings",
        iconName: "Admin",
        minimumRequiredUserRole: UserRole.Admin
    });

    //TODO: Enable this when we have user settings
    // menuItems.push({
    //     id: 1,
    //     title: "User Settings",
    //     iconName: "PlayerSettings",
    //     minimumRequiredUserRole: UserRole.Owner
    // });

    return menuItems;
}

function resetSettingsPanels(draft: IPageViewState) {
    draft.displayAdminSettingsPanel = false;
    draft.displayUserSettingsPanel = false;
}

//TODO: This could be moved to server side so that deployment is not required to change it
function addMaintenanceMessage(draft: IPageViewState) {
    draft.isMaintenanceMessageBarOpen = true;
}
