
import React from "react";
import { connect } from "react-redux";
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";

import { RapComponent, IRapComponentProperties, IRapComponentContext } from "../../../../platform/Layout";
import { IDashboardViewState } from "../../../../pages/Dashboard/Contracts";
import { AppointmentForCreationDto, IAppointment, IAppointmentForCreationDto, ITimeSlotDto } from "../../../../contracts/swagger/_generated";
import { AppointmentStages, IAccordionState, ITelemetryAttributes } from "../../Contracts";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { AppointmentsFeature, CaptchaActions } from "../../../../common/Constants";
import { AppointmentsActions } from "../../redux/AppointmentsActions";
import { RecaptchaButton } from "../../../../common/components/RecaptchaButton/RecaptchaButton";
import { cleanAppointmentForCreationForTelemetry } from "../../../../common/Util";

import DesktopSummary from "./components/DesktopSummary/DesktopSummary";
import MobileSummary from "./components/MobileSummary/MobileSummary";

import * as AppointmentSelectors from "../../redux/AppointmentsSelectors";
import * as FeatureManagementSelectors from "../../../FeatureManagement/redux/FeatureManagementSelectors";
import * as Constants from "../../../../common/Constants";

import "./AppointmentSummary.scss";
import { getSidebarImage } from "../../AppointmentsHelper";

const submitButtonAttributes: ITelemetryAttributes = {
    dataBiBhvr: Constants.CompleteProcessBehavior1DS,
    dataBiScn: Constants.BookAppointmentScenario,
    dataBiScnstp: Constants.ConfirmStep,
    dataBiStpnum: 6
};


interface IAppointmentSummaryState {
}

//Props passed by parent component
interface IAppointmentSummaryProvidedProps extends IRapComponentProperties {
}

//Props mapped from state object
interface IAppointmentSummaryInitializerOwnProps extends IAppointmentSummaryProvidedProps {
    stage: AppointmentStages;
    validatedStoreId: number;
    isCaptchaEnabled: boolean;
    selectedTimezone: string;
    areTimeslotsLoading: boolean;
    createdAppointment: IAppointment;
    timeslots: Date[] | ITimeSlotDto[];
    isSubmitInProgress: boolean;
    accordions: IAccordionState;
    appointment: IAppointmentForCreationDto;
}

// eslint-disable-next-line
export type IAppointmentSummaryInitializerProps = IAppointmentSummaryInitializerOwnProps & typeof ActionsToDispatch;

class AppointmentSummaryInitializer extends RapComponent<IAppointmentSummaryInitializerProps, IAppointmentSummaryState> {
    private submitToken: string;

    constructor(props: IAppointmentSummaryInitializerProps) {
        super(props);
    }

    public componentDidUpdate(prevProps: Readonly<IAppointmentSummaryInitializerProps>): void {
        if (prevProps.stage !== AppointmentStages.REVIEW && this.props.stage === AppointmentStages.REVIEW) {
            window.scrollTo(0, 0);
        }
        // Create appointment
        if (this.props.isSubmitInProgress === true && !this.props.areTimeslotsLoading && !this.props.createdAppointment) {
            this.submitAppointment();
        }
    }

    private triggerAppointmentSubmit = (token?: string) => {
        // Get captcha token
        if(token) this.submitToken = token;

        // Pull latest timeslot availability
        this.props.fetchAppointmentTimeslots(
            this.props.validatedStoreId, 
            this.props.appointment.appointmentTypeId
        );

        this.props.setIsSubmitInProgress(true);
    }

    private submitAppointment = () => {
        if (this.isValidTimeslot()) {
            this.props.logTelemetry(AppointmentsFeature.SubmitAppointment, "Attempting to submit appointment", this.props.validatedStoreId, cleanAppointmentForCreationForTelemetry(this.props.appointment));
            this.props.createAppointment(this.props.validatedStoreId, this.props.appointment as AppointmentForCreationDto, this.submitToken);
        } 
        else {
            this.props.logTelemetry(AppointmentsFeature.SubmitAppointmentFailure, "Appointment failed to submit due to invalid timeslot", this.props.validatedStoreId);
            this.props.setErrorMessage(localizedStrings.AppointmentScheduler?.timeNotAvailable as string);
            this.props.setIsSubmitInProgress(false);
        }
    }

    private isValidTimeslot = () => {
        // Generate list of available times
        let times: number[] = [];
        let cutOffTime = moment().add(Constants.TimeslotBuffer, "minutes");
        this.props.timeslots?.forEach((timeslot: Date | ITimeSlotDto) => {
            let time = timeslot instanceof Date ? timeslot.getTime() : timeslot.startTime?.getTime();
            if (time && moment(time) > cutOffTime) {
                times.push(time);
            }
        });

        // Return true if timeslot is found in list of available times
        return (times.find(timeslot => timeslot === this.props.appointment.appointmentDate.getTime()) !== undefined); 
    }

    private toggleDropdown = (stage: AppointmentStages) => {
        this.props.updateAppointment(stage, this.props.appointment);
        this.setTimeout(() => {
            switch(stage) {
                case AppointmentStages.TYPE: {
                    let test = this.props.updateAccordions({...this.props.accordions, isTypeOpen: true});
                    console.log(test);
                    break;
                }
                case AppointmentStages.TOPIC: {
                    this.props.updateAccordions({...this.props.accordions, isTopicOpen: true});
                    break;
                }
                case AppointmentStages.DATE_TIME: {
                    this.props.updateAccordions({...this.props.accordions, isDateOpen: true});
                    break;
                }
                case AppointmentStages.INFO: {
                    this.props.updateAccordions({...this.props.accordions, isInfoOpen: true});
                    break;
                }
            }
        }, 500);
    }

    private removeErrorMessage = () => {
        // If there is an error message showing, editing the appointment should remove it
        if(this.props.errorMessage && this.props.errorMessage.length > 0) {
            this.props.resetErrorMessage();
        }
    }

    private onEditClick = (stage: AppointmentStages) => {
        this.removeErrorMessage();
        this.toggleDropdown(stage);
    }
    
    public render() {
        const sideBarDetails = getSidebarImage(this.props.validatedStoreId);
        return (
            <>
                {this.props.stage === AppointmentStages.REVIEW && (
                    <div className="c-review-image-container">
                        <div className="c-review-container">
                            <DesktopSummary 
                                onEditClick={this.onEditClick}
                            />
                            <MobileSummary 
                                onEditClick={this.onEditClick}
                            />
                            <div>
                                {this.props.isCaptchaEnabled ? (
                                    <RecaptchaButton
                                        text={this.props.isSubmitInProgress ? "" : localizedStrings.Review?.confirm}
                                        disabled={this.props.isSubmitInProgress}
                                        onClick={this.triggerAppointmentSubmit}
                                        action={CaptchaActions.CreateAppointment}
                                        telemetryAttributes={submitButtonAttributes}
                                    >
                                        {this.props.isSubmitInProgress && <Spinner size={SpinnerSize.small} />}  
                                    </RecaptchaButton>
                                ) : (
                                    <PrimaryButton
                                        text={this.props.isSubmitInProgress ? "" : localizedStrings.Review?.confirm}
                                        disabled={this.props.isSubmitInProgress}
                                        onClick={() => this.triggerAppointmentSubmit()}
                                        data-bi-bhvr={Constants.CompleteProcessBehavior1DS}
                                        data-bi-scn={Constants.BookAppointmentScenario}
                                        data-bi-scnstp={Constants.ConfirmStep}
                                        data-bi-stpnum={6}
                                    >
                                        {this.props.isSubmitInProgress && <Spinner size={SpinnerSize.small} />}
                                    </PrimaryButton>
                                )}
                            </div>
                            
                        </div>
                        {this.props.stage === AppointmentStages.REVIEW && (
                            <div className="c-image-container">
                                <img 
                                    className="c-image"
                                    src={sideBarDetails.image} 
                                    alt={sideBarDetails.altText} 
                                />
                            </div>
                        )}
                    </div>
                )}
                {this.props.stage !== AppointmentStages.REVIEW && (
                    <>
                        <DesktopSummary 
                            onEditClick={this.onEditClick}
                        />
                        <MobileSummary 
                            onEditClick={this.onEditClick}
                        />
                    </>
                )}
            </>
        )
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IAppointmentSummaryProvidedProps): Partial<IAppointmentSummaryInitializerOwnProps> {
    return {
        ...providedProps,
        stage: AppointmentSelectors.getSchedulerStage(state),
        errorMessage: AppointmentSelectors.getErrorMessage(state),
        validatedStoreId: AppointmentSelectors.getValidatedStoreId(state),
        isCaptchaEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableCaptcha", false),
        selectedTimezone: AppointmentSelectors.getSelectedTimezone(state),
        areTimeslotsLoading: AppointmentSelectors.getAreTimeslotsLoading(state),
        createdAppointment: AppointmentSelectors.getCreatedAppointment(state),
        timeslots: AppointmentSelectors.getAppointmentTimeslots(state),
        isSubmitInProgress: AppointmentSelectors.getIsSubmitInProgress(state),
        accordions: AppointmentSelectors.getSchedulerAccordions(state),
        appointment: AppointmentSelectors.getSchedulerAppointment(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: AppointmentsActions.logTelemetry,
    createAppointment: AppointmentsActions.createAppointment,
    fetchAppointmentTimeslots: AppointmentsActions.fetchAppointmentTimeslots,
    setErrorMessage: AppointmentsActions.setErrorMessage,
    resetErrorMessage: AppointmentsActions.resetErrorMessage,
    setIsSubmitInProgress: AppointmentsActions.setIsSubmitInProgress,
    updateAccordions: AppointmentsActions.updateSchedulerAccordions,
    updateAppointment: AppointmentsActions.updateSchedulerAppointment
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentSummaryInitializer);