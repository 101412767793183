import produce from "immer";

import { IFeatureManagementState } from "./../../Page/Contracts";
import { FeatureManagementActionsType, FeatureManagementActionTypes } from "./FeatureManagementActions";

export function featureManagementReducer(state: IFeatureManagementState, action: FeatureManagementActionsType): IFeatureManagementState {
    return produce(state || {}, draft => {
        switch (action.type) {
            case FeatureManagementActionTypes.FetchFeatureFlags: {
                draft.featureFlags = [];
                break;
            }
            case FeatureManagementActionTypes.FetchFeatureFlagsSuccess: {
                draft.featureFlags = action.payload;
                break;
            }

            case FeatureManagementActionTypes.FetchFeatureFlagsFailure: {
                draft.featureFlags = [];
                break;
            }
        }
    });
}
