export default {
    AppointmentManager: {
        cancelTitle: "Appointment cancelled",
        pleaseShareWhy: "Would you like to share why you’re cancelling this appointment?",
        tellUsMore: "Tell us more about why you're cancelling this appointment.",
        cancelErrorMessage: "Please enter your reason for cancelling"
    },
    Cancellation: {
        learnAboutAppointments: "Learn about the Microsoft Experience Centre"
    },
    PersonalInfo: {
        phonePlaceholder: "",
        agreeToTerms: "I agree to the {serviceAgreement} terms for this appointment.",
        agreeToAge: "I confirm I am over the age of 18.",
        lastName: "Your surname",
        lastNamePlaceholder: "Surname"
    },
    ManageAppointment: {
        //Since Dynamics status reason is "Canceled", the text should match regardless of locale
        appointmentCanceled: "This appointment has been canceled and can no longer be modified."
    },
    TypeTopic: {
        typeLabels: {
            personalSetup: "Set-up"
        },
        buttonLabels: {
            personalSetup: "Get set-up help"
        },
        friendlyLabels: {
            personalSetup: "set-up help"
        }
    }
};
