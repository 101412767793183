import moment from "moment";
import { IAppointment, IAppointmentForCreationDto } from "../contracts/swagger/_generated";
import { AppointmentsActions } from "../views/AppointmentsView/redux/AppointmentsActions";
import { AppointmentsFeature, AppointmentStatuses, AppointmentStatusReasons, LetterNumberRegex } from "./Constants";
import { localizedStrings } from "./localization/LocalizedStrings";

export const cleanAppointmentDataForTelemetry = (appointment: IAppointment) => {
    return appointment ? {
        ...appointment,
        customerFirstName: appointment.customerFirstName?.replace(LetterNumberRegex, "x"),
        customerEmail: appointment.customerEmail?.replace(LetterNumberRegex, "x"),
        customerLastName: appointment.customerLastName?.replace(LetterNumberRegex, "x"),
        customerPhone: appointment.customerPhone?.replace(LetterNumberRegex, "x")
    } as IAppointment : undefined;
}

export const cleanAppointmentForCreationForTelemetry = (appointment: IAppointmentForCreationDto) => {
    return appointment ? {
        ...appointment,
        customerFirstName: appointment.customerFirstName?.replace(LetterNumberRegex, "x"),
        customerEmail: appointment.customerEmail?.replace(LetterNumberRegex, "x"),
        customerLastName: appointment.customerLastName?.replace(LetterNumberRegex, "x"),
        customerPhone: appointment.customerPhone?.replace(LetterNumberRegex, "x")
    } as IAppointmentForCreationDto : undefined;
}

export const isAppointmentEditable = (appt: IAppointment) => {
    return appt.status !== AppointmentStatuses.Canceled &&
        appt.status !== AppointmentStatuses.Closed &&
        appt.statusReason !== AppointmentStatusReasons.InProgress &&
        moment().diff(moment(appt.appointmentDate)) <= 0;
}

export const getAppointmentEditError = (appt: IAppointment, logTelemetry: typeof AppointmentsActions.logTelemetry) => {
    if(appt.status === AppointmentStatuses.Canceled) {
        logTelemetry(AppointmentsFeature.ManagePage, "User selected canceled appointment", undefined, 
            cleanAppointmentDataForTelemetry(appt));

        return localizedStrings.ManageAppointment?.appointmentCanceled as string;
    } else if(appt.status === AppointmentStatuses.Closed && appt.statusReason === AppointmentStatusReasons.Completed) {
        logTelemetry(AppointmentsFeature.ManagePage, "User selected completed appointment", undefined, 
            cleanAppointmentDataForTelemetry(appt));

        return localizedStrings.ManageAppointment?.appointmentCompleted as string;
    } else if((appt.statusReason === AppointmentStatusReasons.InProgress || moment().diff(moment(appt.appointmentDate)) > 0)) {
        // This should cover the remaining edge cases where a customer decides last minute to 
        // reschedule, or the associate forgot to Complete the appointment.
        logTelemetry(AppointmentsFeature.ManagePage, "User selected appointment in the past", undefined, 
            cleanAppointmentDataForTelemetry(appt));

        return localizedStrings.ManageAppointment?.appointmentAlreadyStarted as string;
    }
    return "Unexpected error"
}