import { SagaIterator } from "redux-saga";
import { takeEvery, call, put, getContext } from "redux-saga/effects";
import { FeatureFlag } from "../../../contracts/FeatureFlag";
import { ActionsOfType } from "../../../platform/redux/ActionHelper";
import { FeatureManagementActions, FeatureManagementActionsType, FeatureManagementActionTypes } from "./FeatureManagementActions";
import { IRapPageContext } from "./../../../platform/Context";
import { IDigitalReservationsApiClient } from "../../../contracts/swagger/_generated";

export function* featureManagementSaga(): SagaIterator {
    yield takeEvery(FeatureManagementActionTypes.FetchFeatureFlags, fetchFeatureFlags);
}

export function* fetchFeatureFlags(
    action: ActionsOfType<FeatureManagementActionsType, FeatureManagementActionTypes.FetchFeatureFlags>
): SagaIterator {
    try {
        const storeId = action.payload;
        const pageContext: any = yield getContext("pageContext");
        if (pageContext) {
            const reservationsClient: IDigitalReservationsApiClient = (pageContext as IRapPageContext).getRestClient<IDigitalReservationsApiClient>(
                "IDigitalReservationsApiClient"
            );
            const featureFlags: FeatureFlag[] = ((yield call(
                [reservationsClient, reservationsClient.getFlags],
                storeId
            )) as unknown) as FeatureFlag[];
            action.resolve(featureFlags);
            yield put(FeatureManagementActions.fetchFeatureFlagsSuccess(featureFlags));
        }
    } catch (error) {
        action.reject(error);
        yield put(FeatureManagementActions.fetchFeatureFlagsFailure((error as any).message || error));
    }
}
