export default {
    AppointmentManager: {
        cancelTitle: "Appointment cancelled",
        pleaseShareWhy: "Would you like to share why you’re cancelling this appointment?",
        tellUsMore: "Tell us more about why you're cancelling this appointment.",
        cancelErrorMessage: "Please enter your reason for cancelling",
        reasonForCancel: "Reason for cancelling"
    },
    Cancellation: {
        learnAboutAppointments: "Learn about the Microsoft Experience Centre"
    },
    DateAndTime: {
        dateFormat: "dddd, D MMMM, YYYY"
    },
    ManageAppointment: {
        //Since Dynamics status reason is "Canceled", the text should match regardless of locale
        appointmentCanceled: "This appointment has been canceled and can no longer be modified."
    },
    PersonalInfo: {
        lastName: "Surname",
        lastNamePlaceholder: "Surname",
        phonePlaceholder: "",
        nextSteps:
            "Microsoft will use the email address you provided to send you a confirmation email with a Microsoft Teams video link, as well as reminder emails. You’ll also get a request to fill in a survey after your appointment. Learn more in ",
        bslInterpreter: "I require a British Sign Language interpreter for this appointment",
        bslDescription: "We can book a British Sign Language interpreter for you and will email you to learn more about your needs.",
        disabilityAccommodation: "I require additional support for this appointment",
        describeAssistance:
            "Please describe the additional support you require, and our team will reach out to discuss how we can best meet your needs."
    },
    TypeTopic: {
        typeLabels: {
            personalSetup: "Set-up"
        },
        buttonLabels: {
            personalSetup: "Get set-up help"
        },
        friendlyLabels: {
            personalSetup: "set-up help"
        }
    }
};
