import React, { FormEvent } from "react";
import { connect } from "react-redux";
import { RapComponent, IRapComponentProperties } from "../../../../platform/Layout";
import { IDashboardViewState } from "../../../../pages/Dashboard/Contracts";
import { AppointmentsActions } from "../../redux/AppointmentsActions";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import * as Constants from "../../../../common/Constants";
import * as AppointmentsSelectors from "../../redux/AppointmentsSelectors"

import "./AppointmentPersonalInfo.scss";
import { Checkbox, Icon, Label, Link, MaskedTextField, PrimaryButton, TextField, TooltipHost } from "@fluentui/react";
import { CustomQuestion } from "../../../../common/components/CustomQuestion/CustomQuestion";
import { AppointmentCustomResponseDto, AppointmentTopicCustomQuestion, CustomQuestionType, IAppointmentForCreationDto, IAppointmentServiceType } from "../../../../contracts/swagger/_generated";
import { AppointmentStages, IAccordionState, ICustomResponse, IActiveAccount } from "../../Contracts";
import { AppointmentsFeature } from "../../../../common/Constants";
import * as FeatureManagementSelectors from "../../../FeatureManagement/redux/FeatureManagementSelectors";
import { Accordion } from "../../../../common/components/Accordion/Accordion";
import { getServiceNameCategory, getTypeObjectDetails, isStoreMec } from "../../AppointmentsHelper";

const phoneCountryCodes = new Map([
    [Constants.UnitedStatesLocale, Constants.NACountryCode],
    [Constants.CanadaLocale, Constants.NACountryCode],
    [Constants.UnitedKingdomLocale, Constants.UnitedKingdomCountryCode],
    [Constants.AustraliaLocale, Constants.AustraliaCountryCode]
]);

const phoneNumberMasks = new Map([
    [Constants.UnitedStatesLocale, Constants.NAPhoneMask],
    [Constants.CanadaLocale, Constants.NAPhoneMask],
    [Constants.UnitedKingdomLocale, Constants.UnitedKingdomPhoneMask],
    [Constants.AustraliaLocale, Constants.AustraliaPhoneMask]
]);

interface IAppointmentPersonalInfoState {
    firstName?: string;
    lastName?: string;
    email?: string;
    confirmEmail?: string;
    phone?: string;
    reason?: string;
    customResponses?: ICustomResponse[];
    agreedToTerms?: boolean;
    agreedToAge?: boolean;
    accessibilityRequested?: boolean;
    accessibilityDescription?: string;
    bslInterpreterRequested?: boolean;
    customerOptIntoSms?: boolean;
    hasFocusedConfirmEmail?: boolean;
    activeAccount?: IActiveAccount;
    puid?: string;
}

// Props passed by parent component
interface IAppointmentPersonalInfoProvidedProps extends IRapComponentProperties {
}

// Props mapped from state object
interface IAppointmentPersonalInfoInitializerOwnProps extends IAppointmentPersonalInfoProvidedProps {
    appointmentType: IAppointmentServiceType;
    customQuestions: AppointmentTopicCustomQuestion[];
    isSMSEnabled: boolean;
    isConditionalPhoneFieldEnabled: boolean;
    isBslInterpreterRequestEnabled: boolean;
    validatedStoreId: number;
    storeId: number;
    appointment: IAppointmentForCreationDto;
    accordions: IAccordionState;
    stage: AppointmentStages;
    isDateValid: boolean;
    serviceTypes: IAppointmentServiceType[];
}

export type IAppointmentPersonalInfoInitializerProps = IAppointmentPersonalInfoInitializerOwnProps & typeof ActionsToDispatch;

class AppointmentPersonalInfoInitializer extends RapComponent<IAppointmentPersonalInfoInitializerProps, IAppointmentPersonalInfoState> {
    constructor(props: IAppointmentPersonalInfoInitializerProps) {
        super(props);
        this.props.logTelemetry(AppointmentsFeature.RenderPersonalnformationPage, "Initializing personal info page");

        this.state = {
            agreedToTerms: false,
            agreedToAge: false,
            firstName: "",
            lastName: "",
            email: "",
            confirmEmail: "",
            phone: "",
            reason: "",
            customResponses: [],
            accessibilityRequested: false,
            accessibilityDescription: "",
            bslInterpreterRequested: false
        };

        this.props.logTelemetry(AppointmentsFeature.RenderPersonalnformationPage, "Rendered personal information page", this.props.storeId, 
        {
            serviceTypeId: this.props.appointment.appointmentTypeId,
            topicId: this.props.appointment.appointmentCategoryId,
            storeId: this.props.storeId
        });
    }

    public componentDidUpdate(prevProps: Readonly<IAppointmentPersonalInfoInitializerProps>): void {
        // reset consent if type, topic, date/time change or if user navigates to review page
        if ((this.props.appointment.appointmentTypeId !== prevProps.appointment.appointmentTypeId) || 
            (this.props.appointment.appointmentCategoryId !== prevProps.appointment.appointmentCategoryId) || 
            (this.props.appointment.appointmentDate !== prevProps.appointment.appointmentDate) || 
            (this.props.stage !== prevProps.stage && this.props.stage === AppointmentStages.REVIEW)) {
                this.setState({ agreedToTerms: false, agreedToAge: false});
        }

        if(this.props.appointment.appointmentTypeId && 
            this.props.appointment.appointmentCategoryId && 
            (this.props.appointment.appointmentTypeId !== prevProps.appointment.appointmentTypeId || this.props.appointment.appointmentCategoryId !== prevProps.appointment.appointmentCategoryId)) {
                this.props.fetchTopicCustomQuestions(this.props.storeId, this.props.appointment.appointmentTypeId, this.props.appointment.appointmentCategoryId);
        }

        // make sure we only show custom questions responses that are associated with this topic
        if (prevProps.customQuestions === undefined && this.props.customQuestions && this.state.customResponses) {
            this.setState({ customResponses: this.state.customResponses.filter(response => this.props.customQuestions.find(question => question.customQuestionId === response.customQuestionId) !== undefined) });
        }

        // When a user navigates to the personal info stage, try to pre-populate their information via MeControl account
        if(this.props.accordions !== prevProps.accordions && this.props.accordions.isInfoOpen) {
            const { email, firstName, lastName } = this.state;
            // Only populate fields if they are all empty/undefined (user hasn't entered anything yet)
            if((!email || email.length == 0) && (!firstName || firstName.length == 0) && (!lastName || lastName.length == 0)) {
                // If a user is logged in to MeControl, we can get their information from localStorage
                const activeAccount = localStorage.getItem(Constants.LocalStorageAccountKey);
                if (activeAccount) {
                    const accountObj = JSON.parse(activeAccount) as IActiveAccount;
                    if(accountObj.displayName && accountObj.memberName) {
                        const accountFirstName = accountObj.displayName.split(' ').slice(0, -1).join(' ');
                        const accountLastName = accountObj.displayName.split(' ').slice(-1).join(' ');
                        const accountEmail = accountObj.memberName;

                        this.setState({ firstName: accountFirstName, lastName: accountLastName, email: accountEmail, puid: accountObj.puid });
                    }           
                }
            }
        }
    }

    private isReviewButtonDisabled = () => {
        const isValidFirstName = this.state.firstName && this.state.firstName.length !== 0;
        const isValidLastName = this.state.lastName && this.state.lastName.length !== 0;
        const isValidEmail = this.isValidEmail(this.state.email);
        const isValidConfirmEmail = this.isEmailConfirmed(this.state.confirmEmail);
        const isValidPhone = this.isValidPhoneNumber(this.state.phone);
        const isValidDescription = this.state.reason && this.state.reason.length !== 0;
        const isValidCustomResponses = this.isValidCustomResponses();
        const isValidAgreedToAge = localizedStrings.getLanguage() === Constants.AustraliaLocale ? this.state.agreedToAge : true;
        const isValidAccessibility = this.state.accessibilityRequested ? 
            (this.state.accessibilityDescription && this.state.accessibilityDescription.length !== 0) :
            true;
        if (!isValidFirstName || !isValidLastName || !isValidEmail || !isValidConfirmEmail || !isValidPhone || !isValidDescription || !isValidCustomResponses || !this.state.agreedToTerms || !isValidAgreedToAge || !isValidAccessibility) {
            return true;
        }

        return false;
    }

    private onRenderLabel = () => {
        if (!localizedStrings.PersonalInfo?.agreeToTerms || !localizedStrings.PersonalInfo?.serviceAgreement) {
            return <></>;
        }
        const linkComponent = <Link href={Constants.ServiceAgreementUrl} target="_blank">
            {localizedStrings.PersonalInfo.serviceAgreement}
        </Link>;
        return (
            <div className="c-agree-label">
                {localizedStrings.formatString(localizedStrings.PersonalInfo.agreeToTerms, { serviceAgreement: linkComponent })}
                <span className="c-required"> *</span>
            </div>
        )
    }

    private renderSupportText = () => {
        if (!localizedStrings.PersonalInfo?.supportText || !localizedStrings.PersonalInfo?.microsoftSupport
            || !this.props.appointmentType || !this.props.appointmentType.typeName) {
            return <></>;
        }
        const linkComponent = <Link href={Constants.SupportUrl} target="_blank" className="c-link">
            {localizedStrings.PersonalInfo.microsoftSupport}
        </Link>;

        const appointmentTypeDetails = getTypeObjectDetails(this.props.appointmentType.typeName);

        return (
            <div className="c-support-text">
                {localizedStrings.formatString(localizedStrings.PersonalInfo.supportText,
                    {
                        appointmentType: appointmentTypeDetails.friendlyLabel,
                        microsoftSupport: linkComponent
                    })}
            </div>
        )
    }

    private shouldRenderAccommodation = ():boolean => {
        // Render the accommodation checkbox/input if the user is in the UK
        return localizedStrings.getLanguage() === Constants.UnitedKingdomLocale;
    }

    // Render the interpreter checkbox if region is UK 
    private shouldRenderBslInterpreter = () : boolean => {
        return (this.props.isBslInterpreterRequestEnabled === true 
            && localizedStrings.getLanguage() === Constants.UnitedKingdomLocale);
    }

    private renderAccommodation = () => {
        if (!this.state.accessibilityRequested) {
            return <></>
        }

        return (
            <TextField 
                label={localizedStrings.PersonalInfo?.describeAssistance} 
                value={this.state.accessibilityDescription}
                maxLength={Constants.MultilineTextFieldCharacterLimit}
                validateOnLoad={false}
                validateOnFocusOut
                onGetErrorMessage={(value: string): string => {
                    return (!value || value.length === 0) && localizedStrings.PersonalInfo?.requiredFieldError ? localizedStrings.PersonalInfo.requiredFieldError : '';
                }}
                onChange={this.onChangeAccessibilityDescription}
                onBlur={this.afterStateUpdates}
                multiline 
                autoAdjustHeight
                required
                className="c-accessibility-field"
                role="alert"
            />
        )
    }

    private renderBslText = () => {
        if (!this.state.bslInterpreterRequested) {
            return <></>
        }

        return (
            <div className="c-accessibility-field" role="alert">
                {localizedStrings.PersonalInfo?.bslDescription}
            </div>
        )
    }

    private isValidEmail = (str?: string) => {
        if (!str || str.length === 0) {
            return false;
        }

        const emailRegex = new RegExp(Constants.EmailRegex);
        return emailRegex.test(str);
    }

    private isEmailConfirmed = (str?: string) => {
        return str?.toLowerCase() === this.state.email?.toLowerCase();
    }

    private getEmailConfirmationError = () => {
        if (!this.state.email || (!this.state.confirmEmail && !this.state.hasFocusedConfirmEmail)) {
            // If email field is empty, we shouldn't show the error yet
            // If confirm email field is empty and the user hasn't focused it yet, we shouldn't show the error
            return undefined;
        }

        return this.state.email.toLowerCase() !== this.state.confirmEmail?.toLowerCase() ? localizedStrings.PersonalInfo?.confirmEmailError : undefined;
    }

    private isValidPhoneNumber = (str?: string) => {
        if (!str || str.replace(Constants.NonNumbersRegex, "").length === 0) {
            // an empty input is valid if the phone number is not required 
            return this.state.customerOptIntoSms === true ? false : true;
        }

        // Phone number is valid once all mask characters ('_') are overwritten in the field
        const phoneRegex = new RegExp(Constants.PhoneFieldRegex);
        return phoneRegex.test(str);
    }

    private isValidCustomResponses = () => {
        if (!this.props.customQuestions) {
            return true;
        }
        let requiredQs = this.props.customQuestions.filter(q => q.isRequired === true);
        if (requiredQs.length === 0) {
            return true;
        }

        if (this.state.customResponses && this.state.customResponses.length !== 0) {
            for (const question of requiredQs) {
                let customResponse = this.state.customResponses.find(q => q.customQuestionId === question.customQuestionId);
                if (!customResponse || customResponse.response?.length === 0) {
                    return false;
                }
            };
            
            return true;
        }

        return false;
    }

    private onChangeFirstName = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ firstName: newValue });
    }

    private onChangeLastName = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ lastName: newValue });
    }

    private onChangeEmail = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ email: newValue });
    }

    private onChangeConfirmEmail = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ confirmEmail: newValue });
    }

    private onChangePhone = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ phone: newValue });
    }

    private onChangeReason = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ reason: newValue });
    }

    private onChangeAgreeToTerms = () => {
        this.setState(prevState => ({ 
            agreedToTerms: !prevState.agreedToTerms
        }), this.afterStateUpdates);
    }

    private onChangeAgreedToAge = () => {
        this.setState(prevState => ({ 
            agreedToAge: !prevState.agreedToAge
        }), this.afterStateUpdates);
    }

    private onChangeAccessibilityRequested = () => {
        this.setState(prevState => ({
            accessibilityRequested: !prevState.accessibilityRequested
        }), this.afterStateUpdates);
    }

    private onChangebslInterpreterRequested = () => {
        this.setState(prevState => ({
            bslInterpreterRequested: !prevState.bslInterpreterRequested
        }), this.afterStateUpdates);
    }

    private onChangecustomerOptIntoSms = () => {
        this.setState(prevState => ({
            customerOptIntoSms: !prevState.customerOptIntoSms
        }), this.afterStateUpdates);
    }

    private onChangeAccessibilityDescription = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ accessibilityDescription: newValue });
    }

    private getLabels = (): { anythingElse: string, nextSteps: React.ReactElement } => {
        if(isStoreMec(this.props.validatedStoreId)) {
            return { 
                anythingElse: localizedStrings.PersonalInfo?.whatCanWeHelpWithPlaceholder as string,
                nextSteps: <span>{localizedStrings.PersonalInfo?.nextSteps as string}</span>
            };
        }
        return {
            anythingElse: localizedStrings.PersonalInfo?.whatCanWeHelpWithOnlinePlaceholder as string,
            nextSteps: (
                <span>
                    {localizedStrings.PersonalInfo?.nextStepsOnline1 as string}
                    <b>{localizedStrings.PersonalInfo?.nextStepsOnline2 as string}</b>
                    {localizedStrings.PersonalInfo?.nextStepsOnline3 as string}
                    <b>{localizedStrings.PersonalInfo?.nextStepsOnline4 as string}</b>
                    {localizedStrings.PersonalInfo?.nextStepsOnline5 as string}
                </span>
            )
        }
    }

    private onChangeCustomQuestion = (question: AppointmentTopicCustomQuestion, newValue?: any) => {
        let customResponses = this.state.customResponses ? this.state.customResponses : []; 
        let index = customResponses.findIndex(q => q.customQuestionId === question.customQuestionId);
        if (index !== -1) {
            customResponses[index].response = newValue;
        } else {
            customResponses.push({
                customQuestionId: question.customQuestionId,
                questionText: question.questionText,
                responseType: question.questionType,
                response: newValue,
                sortOrder: question.sortOrder
            })
        }

        // save the custom resopnses in the order the questions are shown
        this.setState({ customResponses: customResponses.sort((a, b) => a.sortOrder && b.sortOrder ? a.sortOrder - b.sortOrder : Infinity) });
    }
     
    private onChangeMultipleChoiceCustomQuestion = (question: AppointmentTopicCustomQuestion, newValue: string, checked: boolean) => {
        let values = this.getCustomQuestionValue(question);
        if (!values) {
            values = [];
        }
        if (checked) {
            values.push(newValue);
        } else {
            values = values.filter((value: string) => value !== newValue);
        }

        this.onChangeCustomQuestion(question, values); 
    }

    private getCustomQuestionValue = (question: AppointmentTopicCustomQuestion) => {
        return this.state.customResponses?.find(q => q.customQuestionId === question.customQuestionId)?.response;
    }
    
    private getCustomQuestions = () => {

        if(this.props.customQuestions) {
            return (
                this.props.customQuestions.map((question: AppointmentTopicCustomQuestion, index: number) => {
                    return (
                        <div className="c-form-row" key={index}>
                            <CustomQuestion
                                question={question} 
                                className="c-form-field"
                                onChange={question.questionType === CustomQuestionType.MultipleChoice ? this.onChangeMultipleChoiceCustomQuestion : this.onChangeCustomQuestion}
                                onBlur={this.afterStateUpdates}
                                currentValue={this.getCustomQuestionValue(question)}
                            />
                        </div>
                    )
                })
            )
        }
    }

    private afterStateUpdates = () => {
        const unformattedPhone = this.state.phone ? 
            phoneCountryCodes.get(localizedStrings.getLanguage()) + this.state.phone.replace(Constants.NonNumbersRegex, "") : 
            undefined;

        
        let appointmentCustomResponses: AppointmentCustomResponseDto[] = [];
        if(this.state.customResponses) {
            this.state.customResponses.map((customResp: ICustomResponse) => appointmentCustomResponses.push({
                customQuestionId: customResp.customQuestionId ? customResp.customQuestionId : "",
                questionText: customResp.questionText,
                responseType: customResp.responseType,
                response: customResp.response?.toString(),
                sortOrder: customResp.sortOrder
            } as AppointmentCustomResponseDto));
        }
        
        this.props.updateAppointment(AppointmentStages.INFO, {
                ...this.props.appointment, 
                customerFirstName: this.state.firstName as string, 
                customerLastName: this.state.lastName as string, 
                customerEmail: this.state.email?.toLowerCase() as string,
                customerPhone: unformattedPhone,
                descriptionOfIssue: this.state.reason, 
                customResponses: appointmentCustomResponses,
                accessibilityRequested: this.state.accessibilityRequested,
                accessibilityDescription: this.state.accessibilityDescription,
                bslInterpreterRequested: this.state.bslInterpreterRequested,
                customerOptIntoSms: this.state.customerOptIntoSms,
                puid: this.state.puid
            }
        );
    }

    private shouldRenderSMS = (): boolean => {
        let language = localizedStrings.getLanguage();
        const shouldRender = this.props.isSMSEnabled === true && 
            (language === Constants.UnitedStatesLocale || language === Constants.CanadaLocale)  &&
            !isStoreMec(this.props.validatedStoreId);

        // If we are going to render SMS, we should set the state value to 'false' (if it is currently undefined)
        if (shouldRender && this.state.customerOptIntoSms === undefined) {
            this.setState({ customerOptIntoSms: false });
        }

        return shouldRender;
    }

    private shouldRenderPhone = (): boolean => {
        let typeName = this.props.appointmentType && this.props.appointmentType.typeName ? this.props.appointmentType.typeName : "";
        const serviceTypeCategory = getServiceNameCategory(typeName);

        // conditionally render phone field when sms is checked (only for personal appointment types)
        if (this.props.isConditionalPhoneFieldEnabled && this.shouldRenderSMS() && (serviceTypeCategory === Constants.PersonalSetupAppointmentTypes || 
            serviceTypeCategory === Constants.PersonalAppointmentTypes || serviceTypeCategory === Constants.PersonalTrainingAppointmentTypes)) 
        {
            return this.state.customerOptIntoSms === undefined ? false : this.state.customerOptIntoSms;
        }

        return true;
    }

    public render() {
        const strings = localizedStrings.PersonalInfo;
        const labels = this.getLabels();
        return (
            <>
                <Accordion
                    title={localizedStrings.PersonalInfo?.personalInformation}
                    onClick={ () => this.props.updateAccordions({...this.props.accordions, isInfoOpen: !this.props.accordions.isInfoOpen })}
                    isOpen={this.props.accordions.isInfoOpen}
                    disabled={!this.props.isDateValid}
                >
                    {this.props.accordions.isInfoOpen && this.props.accordions.isInfoOpen === true && (
                        <form className="c-personal-info-form">
                            <div className="c-form-row">
                                <TextField 
                                    label={strings?.firstName} 
                                    value={this.state.firstName} 
                                    placeholder={strings?.firstNamePlaceholder}
                                    maxLength={Constants.NameTextFieldCharacterLimit}
                                    validateOnLoad={false}
                                    validateOnFocusOut
                                    onGetErrorMessage={(value: string): string => {
                                        return (!value || value.length === 0) && strings?.requiredFieldError ? strings.requiredFieldError : '';
                                    }}
                                    onChange={this.onChangeFirstName}
                                    onBlur={this.afterStateUpdates}
                                    required 
                                    aria-required="true" 
                                    className="c-form-field left" 
                                />
                                <TextField 
                                    label={strings?.lastName} 
                                    value={this.state.lastName}
                                    placeholder={strings?.lastNamePlaceholder}
                                    maxLength={Constants.NameTextFieldCharacterLimit}
                                    validateOnLoad={false}
                                    validateOnFocusOut
                                    onGetErrorMessage={(value: string): string => {
                                        return (!value || value.length === 0) && strings?.requiredFieldError ? strings.requiredFieldError : '';
                                    }}
                                    onChange={this.onChangeLastName}
                                    onBlur={this.afterStateUpdates}
                                    required 
                                    aria-required="true" 
                                    className="c-form-field right"
                                />
                            </div>
                            <div className="c-form-row">
                                <TextField 
                                    type="email" 
                                    label={strings?.email} 
                                    value={this.state.email} 
                                    placeholder={strings?.emailPlaceholder}
                                    maxLength={Constants.SingleLineTextFieldCharacterLimit}
                                    validateOnLoad={false}
                                    validateOnFocusOut
                                    onGetErrorMessage={(value: string): string => {
                                        return !this.isValidEmail(value) && strings?.emailError ? strings.emailError : '';
                                    }}
                                    onChange={this.onChangeEmail}
                                    onBlur={this.afterStateUpdates}
                                    required 
                                    aria-required="true" 
                                    className="c-form-field left"
                                />
                                <TextField 
                                    type="email"
                                    onRenderLabel={() => {
                                        return (
                                            <Label id="confirm-email" aria-label={`${strings?.confirmEmail} ${strings?.confirmEmailTooltip}`}>
                                                <span id="confirm-email-text">{strings?.confirmEmail}</span>
                                                <TooltipHost content={strings?.confirmEmailTooltip} id="confirm-email-tooltip">
                                                    <Icon id="info" iconName="Info" aria-describedby="confirm-email-tooltip"/>
                                                </TooltipHost>
                                            </Label>
                                        )}}
                                    aria-labelledby="confirm-email"
                                    value={this.state.confirmEmail}
                                    placeholder={strings?.emailPlaceholder}
                                    maxLength={Constants.SingleLineTextFieldCharacterLimit}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                    validateOnLoad={false}
                                    validateOnFocusOut
                                    errorMessage={this.getEmailConfirmationError()}
                                    onChange={this.onChangeConfirmEmail}
                                    onBlur={() => this.setState({ hasFocusedConfirmEmail: true })}
                                    aria-required
                                    autoComplete="new-password" // disables auto complete for this field
                                    className="c-form-field right"
                                />
                            </div>
                            {this.shouldRenderSMS() === true &&
                                <div className="c-sms">
                                    <Checkbox
                                        label={strings?.smsCustomerOptIn}
                                        checked={this.state.customerOptIntoSms}
                                        onChange={this.onChangecustomerOptIntoSms}
                                        className="c-form-field"
                                        styles={{ label: { alignItems: "flex-start", fontWeight: 600 } }}
                                    />
                                </div>
                            }
                            {this.shouldRenderPhone() === true && 
                                <div className="c-form-row c-phone-row">
                                    <MaskedTextField 
                                        label={strings?.phone} 
                                        value={this.state.phone} 
                                        placeholder={strings?.phonePlaceholder}
                                        prefix={phoneCountryCodes.get(localizedStrings.getLanguage())}
                                        mask={phoneNumberMasks.get(localizedStrings.getLanguage())}
                                        autoComplete={"new-password"} // This value ensures that the browser won't try to autocomplete the field
                                        validateOnLoad={false}
                                        validateOnFocusOut
                                        onChange={this.onChangePhone}
                                        onBlur={this.afterStateUpdates}
                                        className="c-form-field left"
                                        required={this.state.customerOptIntoSms === true}
                                        aria-required={this.state.customerOptIntoSms === true ? "true" : "false"}
                                        onGetErrorMessage={(value: string): string => {
                                            return !this.isValidPhoneNumber(value) && strings?.phoneError ? strings.phoneError : '';
                                        }}
                                    />
                                </div>
                            }
                            {this.props.customQuestions && 
                                <div className="c-custom-questions">
                                    {this.getCustomQuestions()}
                                </div>
                            } 
                            {this.shouldRenderBslInterpreter() ? (
                                <div className="c-accessibility-field">
                                    <Checkbox
                                        label={strings?.bslInterpreter}
                                        checked={this.state.bslInterpreterRequested}
                                        onChange={this.onChangebslInterpreterRequested}
                                        styles={{ label: { alignItems: "flext-start" } }}
                                    />
                                    {this.renderBslText()}
                                </div>) : (<></>)
                            }
                            {this.shouldRenderAccommodation() ? (
                                <div className="c-accessibility-field">
                                    <Checkbox
                                        label={strings?.disabilityAccommodation}
                                        checked={this.state.accessibilityRequested}
                                        onChange={this.onChangeAccessibilityRequested}
                                        styles={{ label: { alignItems: "flex-start" } }}
                                    />
                                    {this.renderAccommodation()}
                                </div>) : (<></>)
                            }
                            <div className="c-form-row">
                                <TextField 
                                    label={strings?.whatCanWeHelpWith} 
                                    value={this.state.reason}
                                    placeholder={labels.anythingElse}
                                    maxLength={Constants.MultilineTextFieldCharacterLimit}
                                    validateOnLoad={false}
                                    validateOnFocusOut
                                    onGetErrorMessage={(value: string): string => {
                                        return (!value || value.length === 0) && strings?.requiredFieldError ? strings.requiredFieldError : '';
                                    }}
                                    onChange={this.onChangeReason}
                                    onBlur={this.afterStateUpdates}
                                    multiline 
                                    autoAdjustHeight
                                    required 
                                    aria-required="true"
                                    className="c-form-field c-description"
                                />
                            </div>
                            {this.renderSupportText()}
                            <div className="c-form-row">
                                <Checkbox 
                                    onRenderLabel={this.onRenderLabel}
                                    checked={this.state.agreedToTerms}
                                    onChange={this.onChangeAgreeToTerms}
                                    required 
                                    className="c-form-field" 
                                    styles={{ label: { alignItems: "flex-start", fontWeight: 600 } }}
                                />
                            </div>
                            {localizedStrings.getLanguage() === Constants.AustraliaLocale && (
                                <div className="c-form-row">
                                    <Checkbox 
                                        onRenderLabel={() => 
                                            <div className="c-agree-label">
                                                {strings?.agreeToAge}
                                                <span className="c-required"> *</span>
                                            </div>
                                        }
                                        checked={this.state.agreedToAge}
                                        onChange={this.onChangeAgreedToAge}
                                        required 
                                        className="c-form-field" 
                                        styles={{ label: { alignItems: "flex-start", fontWeight: 600 } }}
                                    />
                                </div>
                            )} 
                            <div className="c-next-steps">
                                {labels.nextSteps}
                                <Link href={Constants.PrivacyUrl} className="c-link" target="_blank">{strings?.privacyAndCookies}</Link>
                            </div>                            
                            <div className="c-personal-info-required">{localizedStrings.AppointmentScheduler?.requiredText}</div>
                            <div className="c-button-container">
                                <PrimaryButton 
                                    text={localizedStrings.AppointmentScheduler?.reviewAndConfirm}
                                    disabled={this.isReviewButtonDisabled()}
                                    onClick={() => this.props.updateSchedulerStage(AppointmentStages.REVIEW)}
                                    data-bi-bhvr={Constants.CheckpointBehavior1DS}
                                    data-bi-scn={Constants.BookAppointmentScenario}
                                    data-bi-scnstp={Constants.ReviewStep}
                                    data-bi-stpnum={5}
                                />
                                    
                            </div>
                        </form>
                    )}
                </Accordion>
            </>
        )
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IAppointmentPersonalInfoProvidedProps): Partial<IAppointmentPersonalInfoInitializerOwnProps> {
    return {
        ...providedProps,
        customQuestions: AppointmentsSelectors.getTopicCustomQuestions(state),
        appointmentType: AppointmentsSelectors.getSelectedAppointmentServiceType(state),
        isSMSEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableSMSFeatures", false),
        isConditionalPhoneFieldEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableConditionalPhoneField", false),
        isBslInterpreterRequestEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableBslInterpreterRequest", false),
        validatedStoreId: AppointmentsSelectors.getValidatedStoreId(state),
        storeId: AppointmentsSelectors.getValidatedStoreId(state),
        appointment: AppointmentsSelectors.getSchedulerAppointment(state),
        accordions: AppointmentsSelectors.getSchedulerAccordions(state),
        stage: AppointmentsSelectors.getSchedulerStage(state),
        isDateValid: AppointmentsSelectors.getSchedulerIsDateValid(state),
        serviceTypes: AppointmentsSelectors.getAppointmentServiceTypes(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: AppointmentsActions.logTelemetry,
    fetchTopicCustomQuestions: AppointmentsActions.fetchTopicCustomQuestions,
    fetchAppointmentServiceTypesByStore: AppointmentsActions.fetchAppointmentServiceTypesByStore,
    updateSchedulerStage: AppointmentsActions.updateSchedulerStage,
    updateAppointment: AppointmentsActions.updateSchedulerAppointment,
    updateAccordions: AppointmentsActions.updateSchedulerAccordions
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentPersonalInfoInitializer);