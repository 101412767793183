import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { MessageBar, MessageBarType } from "@fluentui/react";

import AppointmentScheduler from "../../AppointmentScheduler/AppointmentScheduler";

import { IRapComponentProperties } from "../../../../../platform/Layout";
import { isValidGuid, isValidStoreId } from "../../../AppointmentsHelper";
import { IDashboardViewState } from "../../../../../pages/Dashboard/Contracts";
import { AppointmentsActions } from "../../../redux/AppointmentsActions";
import { IAppointmentServiceType, IAppointmentTopic, Redirects, StoreDto, StoreRedirect } from "../../../../../contracts/swagger/_generated";
import { localizedStrings } from "../../../../../common/localization/LocalizedStrings";
import { FeatureManagementActions } from "../../../../FeatureManagement/redux/FeatureManagementActions";
import { AppointmentsFeature } from "../../../../../common/Constants";
import { MaintenanceScreen } from "../../../../../common/components/MaintenanceScreen/MaintenanceScreen";
import { getAdditionalParametersFromQueryStringAsQueryString } from "../../../../../platform/core/util/Util";

import * as FeatureManagementSelectors from "../../../../FeatureManagement/redux/FeatureManagementSelectors";
import * as AppointmentsSelectors from "../../../redux/AppointmentsSelectors";
import * as Constants from "../../../../../common/Constants";

//Props passed by parent component
interface ISchedulerGatewayProvidedProps extends IRapComponentProperties {
    params: any;
}

//Props mapped from state object
interface ISchedulerGatewayOwnProps {
    storeSearchResults: StoreDto[];
    errorMessage: string;
    validatedStoreId: number;
    redirects: Redirects;
    serviceTypes: IAppointmentServiceType[];
    serviceTopics: IAppointmentTopic[];
    isStoreDisabled: boolean;
}

// eslint-disable-next-line
export type ISchedulerGatewayProps = ConnectedProps<typeof connector> & ISchedulerGatewayProvidedProps;

const SchedulerGateway: FC<ISchedulerGatewayProps> = (props) => {
    const [runServiceTopicValidation, setRunServiceTopicValidation] = useState(true);
    const [serviceId, setServiceId] = useState<string>();
    const [topicId, setTopicId] = useState<string>();
    
    useEffect(() => {
        props.initScheduler();
        props.fetchRedirects();

        if(isValidStoreId(props.params.storeid)) {
            props.updateValidatedStoreId(props.params.storeid as number);
        }
        else if(isValidGuid(props.params.storeid)) {
            props.fetchStoreByStoreId(props.params.storeid).then(stores => {
                if(stores && stores.length > 0) {
                    props.logTelemetry(AppointmentsFeature.AppointmentScheduler, "Setting store number", parseInt(stores[0].storeNumber as string));
                    props.updateValidatedStoreId(parseInt(stores[0].storeNumber as string));
                }
            });
        }
        else {
            props.setErrorMessage(localizedStrings.AppointmentScheduler?.noStore as string);
        }

        if(isValidGuid(props.params.serviceid)) {
            setServiceId(props.params.serviceid);
        }

        if(isValidGuid(props.params.topicid)) {
            setTopicId(props.params.topicid);
        }
    }, []);

    useEffect(() => {
        if(props.validatedStoreId) {
            // refetch feature flags when store number is updated
            props.fetchFeatureFlags(props.validatedStoreId as number);
        }
    }, [props.validatedStoreId]);

    useEffect(() => {
        if(props.redirects && props.validatedStoreId) {
            processRedirects();
        }
    }, [props.redirects, props.validatedStoreId]);

    useEffect(() => {
        // Service/Topic validation for when service/topic are passed in via url
        // Only want this to run ONCE to validate the initial service/topicid
        if(props.serviceTypes && props.serviceTopics && runServiceTopicValidation) {
            if (isValidGuid(serviceId as string) && isValidGuid(topicId as string)) {
                let typeName = props.serviceTypes.find(service => service.typeId === props.serviceId)?.typeName;
                let topicName = props.serviceTopics.find(topic => topic.topicId === props.topicId)?.topicName;

                // check for disabled service/topics before moving on to the date time stage
                if (!isValidService(typeName)) {
                    window.location.href = `${window.location.protocol}//${window.location.host}/${localizedStrings.getLanguage()}/appointment/create/?storeid=${props.validatedStoreId}`;
                } else if (!isValidTopic(topicName)) {
                    window.location.href = `${window.location.protocol}//${window.location.host}/${localizedStrings.getLanguage()}/appointment/create/?storeid=${props.validatedStoreId}&serviceid=${props.serviceId}`;
                }
            }
            setRunServiceTopicValidation(false);
        }
    }, [props.serviceTypes, props.serviceTopics]);

    const processRedirects = () => {
        let storeRedirect = props.redirects?.storeRedirects?.find(redirect => redirect.storeNumber == props.validatedStoreId);
        let serviceRedirect = props.redirects?.serviceRedirects?.find(redirect => redirect.serviceId == props.params.serviceid);
        let topicRedirect = props.redirects?.topicRedirects?.find(redirect => redirect.topicId == props.params.topicid);

        if(window.location.href.includes("%3F")) {
            window.location.href = window.location.href.replace(/%3F/g, "");  // CodeQL.SM02383 Incomplete string escaping or encoding
        }

        if(!storeRedirect && !serviceRedirect && !topicRedirect) {
            return;
        }

        var s = "000" + (storeRedirect ? storeRedirect?.redirectTo?.toString() : props.validatedStoreId?.toString());
        let finalStoreid = s?.substr(s.length-4);

        let matches = window.location.pathname.match(Constants.localeRegex);
        var locale;

        if(matches && matches.length > 0) {
            locale = matches[0];
        } else {
            const interfaceLocale = localizedStrings.getInterfaceLanguage().toLowerCase();
            var loc = localizedStrings.getAvailableLanguages().includes(interfaceLocale) ? interfaceLocale : Constants.DefaultLocale;
            locale = `/${loc}/`;
        }

        var getUrl = window.location;
        var url = `${getUrl.origin}${locale}appointment/create/?storeid=${finalStoreid}`;
        
        var includedQueryVariables = Constants.StoreRedirectExpectedParameters;
        if(serviceRedirect) {
            url += `&serviceid=${serviceRedirect.redirectTo}`;
            includedQueryVariables.push("serviceid");
        }
        if(topicRedirect) {
            url += `&topicid=${topicRedirect.redirectTo}`;
            includedQueryVariables.push("topicid");
        }

        url += getAdditionalParametersFromQueryStringAsQueryString(props.params, Constants.StoreRedirectExpectedParameters);
        window.location.href = url;
    }

    const isValidService = (serviceName: string | undefined) => {
        // hide Education Consultation for en-ca users
        if (localizedStrings.getLanguage() === Constants.CanadaLocale) {
            return serviceName?.toLowerCase() !== Constants.EducationAppointmentTypes.EducationConsultation.toLowerCase();
        }
        return true;
    }

    const isValidTopic = (topicName: string | undefined) => {
        // hide Surface live demo for en-ca users
        if (localizedStrings.getLanguage() === Constants.CanadaLocale && props.validatedStoreId == 9988) {
            if(topicName?.toLowerCase() == Constants.SurfaceProductLiveDemonstrationTopic.toLowerCase() || topicName?.toLowerCase() == Constants.HoloLensTopic.toLowerCase()) {
                return false;
            } 
        }
        if (localizedStrings.getLanguage() === Constants.CanadaLocale && props.validatedStoreId == 9987) {
            return topicName?.toLowerCase() !== Constants.SurfaceLiveDemoTopic.toLowerCase();
        }
        

        return true;
    }

    return (
        <>
            {props.errorMessage && (
                <MessageBar 
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    onDismiss={() => props.resetErrorMessage()}
                    dismissButtonAriaLabel={"Close"}
                >
                    {props.errorMessage}
                </MessageBar>
            )}
            {props.isStoreDisabled && (
                <MaintenanceScreen/>
            )}
            {!props.isStoreDisabled && props.validatedStoreId && (
                <AppointmentScheduler serviceId={serviceId} topicId={topicId}/>
            )}
        </>
    )
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: ISchedulerGatewayProvidedProps): Partial<ISchedulerGatewayOwnProps> {
    return {
        ...providedProps,
        storeSearchResults: AppointmentsSelectors.getStoreSearchResults(state),
        errorMessage: AppointmentsSelectors.getErrorMessage(state),
        validatedStoreId: AppointmentsSelectors.getValidatedStoreId(state),
        redirects: AppointmentsSelectors.getRedirects(state),
        serviceTypes: AppointmentsSelectors.getAppointmentServiceTypes(state),
        serviceTopics: AppointmentsSelectors.getAppointmentServiceTopics(state),
        isStoreDisabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "IsStoreDisabled", false)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: AppointmentsActions.logTelemetry,
    initScheduler: AppointmentsActions.initScheduler,
    updateValidatedStoreId: AppointmentsActions.updateValidatedStoreId,
    fetchStoreByStoreId: AppointmentsActions.fetchStoreByStoreId,
    setErrorMessage: AppointmentsActions.setErrorMessage,
    resetErrorMessage: AppointmentsActions.resetErrorMessage,
    fetchFeatureFlags: FeatureManagementActions.fetchFeatureFlags,
    fetchRedirects: AppointmentsActions.fetchRedirects,
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(SchedulerGateway);