let rootUrl = "";

export function setRootUrl(url: string): void {
    rootUrl = url;

    if (url) {
        const currentPath = window.location.pathname;
        if (currentPath) {
            const rootUrlIndex = currentPath.toLocaleLowerCase().indexOf(url.toLocaleLowerCase());
            if (rootUrlIndex > 0) {
                rootUrl = currentPath.substr(0, rootUrlIndex) + url;
            }
        }
    }
}

export function getRootUrl(): string {
    return rootUrl;
}

export function getRelativeUrl(fullUrl: string): string {
    if (fullUrl && fullUrl.startsWith(rootUrl + "/")) {
        return fullUrl.substr(rootUrl.length + 1);
    } else if (fullUrl === rootUrl) {
        return "";
    }
    return fullUrl;
}

export function makeFullUrl(relativeUrl: string): string {
    if (rootUrl) {
        if (relativeUrl && relativeUrl !== "/") {
            return rootUrl + (relativeUrl[0] === "/" ? "" : "/") + relativeUrl;
        }
        return rootUrl;
    }
    return relativeUrl;
}