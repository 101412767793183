import { IPageActionOverrideValues } from '@microsoft/1ds-analytics-web-js';
import { AppointmentForCreationDto, AppointmentTopicCustomQuestion, IAppointmentForCreationDto, IAppointmentForUpdateDto, ITimeSlotDto, Redirects, StoreDto, StoreRedirect } from './../../../contracts/swagger/_generated';
import { ActionsUnion, createAction, createActionWithCallback, createThunkActionType } from "../../../platform/redux/ActionHelper";
import { AppointmentsFeature, AppointmentUpdateType } from "../../../common/Constants";
import { Appointment, AppointmentServiceType, AppointmentTopic, AppointmentForUpdateDto, IOptionSetAttribute, IAppointment } from "../../../contracts/swagger/_generated";
import { AppointmentStages, IAccordionState, IModals, ManagementStages } from '../Contracts';

/**
 * Actions for Appointments view
 */
export enum AppointmentsActionTypes {
    InitScheduler = "Appointments/InitSceduler",
    InitManager = "Appointments/InitManager",
    LogTelemetry = "Appointments/LogTelemetry",
    LogOneDSPageView = "Appointments/LogOneDSPageView",
    LogOneDSAction = "Appointments/LogOneDSAction",

    FetchAppointmentServiceTypesByStore = "Appointments/FetchAppointmentServiceTypesByStore",
    FetchAppointmentServiceTypesByStoreSuccess = "Appointments/FetchAppointmentServiceTypesByStoreSuccess",
    FetchAppointmentServiceTypesByStoreFailure = "Appointments/FetchAppointmentServiceTypesByStoreFailure",

    FetchAppointmentServiceTopics = "Appointments/FetchAppointmentServiceTopics",
    FetchAppointmentServiceTopicsSuccess = "Appointments/FetchAppointmentServiceTopicsSuccess",
    FetchAppointmentServiceTopicsFailure = "Appointments/FetchAppointmentServiceTopicsFailure",

    FetchAppointmentByConfirmationNumber = "Appointments/FetchAppointmentByConfirmationNumber",
    FetchAppointmentByConfirmationNumberSuccess = "Appointments/FetchAppointmentByConfirmationNumberSuccess",
    FetchAppointmentByConfirmationNumberFailure = "Appointments/FetchAppointmentByConfirmationNumberFailure",

    FetchAppointmentTimeslots = "Appointments/FetchAppointmentTimeslots",
    FetchAppointmentTimeslotsSuccess = "Appointments/FetchAppointmentTimeslotsSuccess",
    FetchAppointmentTimeslotsFailure = "Appointments/FetchAppointmentTimeslotsFailure",

    UpdateAppointment = "Appointments/UpdateAppointment",
    UpdateAppointmentSuccess = "Appointments/UpdateAppointmentSuccess",
    UpdateAppointmentFailure = "Appointments/UpdateAppointmentFailure",

    FetchAppointmentStatuses = "Appointments/FetchAppointmentStatuses",
    FetchAppointmentStatusesSuccess = "Appointments/FetchAppointmentStatusesSuccess",
    FetchAppointmentStatusesFailure = "Appointments/FetchAppointmentStatuesFailure",

    FetchAppointmentStatusReasons = "Appointments/FetchAppointmentStatusReasons",
    FetchAppointmentStatusReasonsSuccess = "Appointments/FetchAppointmentStatusReasonsSuccess",
    FetchAppointmentStatusReasonsFailure = "Appointments/FetchAppointmentStatusReasonsFailure",

    UpdateSelectedTimezone = "Appointments/UpdateSelectedTimezone",

    CreateAppointment = "Appointments/CreateAppointment",
    CreateAppointmentSuccess = "Appointments/CreateAppointmentSuccess",
    CreateAppointmentFailure = "Appointments/CreateAppointmentFailure",

    ResetCreatedAppointment = "Appointments/ResetCreatedAppointment",

    SetIsSubmitInProgress = "Appointments/SetIsSubmitInProgress",

    FetchTopicCustomQuestions = "Appointments/FetchTopicCustomQuestions",
    FetchTopicCustomQuestionsSuccess = "Appointments/FetchTopicCustomQuestionsSuccess",
    FetchTopicCustomQuestionsFailure = "Appointments/FetchTopicCustomQuestionsFailure",

    FetchStoreByStoreId = "Appointments/FetchStoreByStoreId",
    FetchStoreByStoreIdSuccess = "Appointments/FetchStoreByStoreIdSuccess",
    FetchStoreByStoreIdFailure = "Appointments/FetchStoreByStoreIdFailure",

    FetchStores = "Appointments/FetchStores",
    FetchStoresSuccess = "Appointments/FetchStoresSuccess",
    FetchStoresFailure = "Appointments/FetchStoresFailure",

    FetchAppointmentById = "Appointments/FetchAppointmentById",
    FetchAppointmentByIdSuccess = "Appointments/FetchAppointmentByIdSuccess",
    FetchAppointmentByIdFailure = "Appointments/FetchAppointmentByIdFailure",

    SetErrorMessage = "Appointments/SetErrorMessage",
    ResetErrorMessage = "Appointments/ResetErrorMessage",

    FetchCaptchaSiteKey = "Appointments/FetchCaptchaSiteKey",
    FetchCaptchaSiteKeySuccess = "Appointments/FetchCaptchaSiteKeySuccess",
    FetchCaptchaSiteKeyFailure = "Appointments/FetchCaptchaSiteKeyFailure",

    ResetAppointmentTimeslots = "Appointments/ResetAppointmentTimeslots",

    FetchRedirects = "Appointments/FetchRedirects",
    FetchRedirectsSuccess = "Appointments/FetchRedirectsSuccess",
    FetchRedirectsFailure = "Appointments/FetchRedirectsFailure",

    //scheduler state actions
    UpdateValidatedStoreId = "Appointments/UpdateValidatedStoreId",
    UpdateSchedulerStage = "Appointments/UpdateSchedulerStage",
    UpdateSchedulerSelectedTypeName = "Appointments/UpdateSchedulerSelectedTypeName",
    UpdateSchedulerSelectedTopicName = "Appointments/UpdateSchedulerSelectedTopicName",
    UpdateSchedulerSelectedDate = "Appointments/UpdateSchedulerSelectedDate",
    UpdateSchedulerSelectedTime = "Appointments/UpdateSchedulerSelectedTime",
    ResetSchedulerSelectedTime = "Appointments/ResetSchedulerSelectedTime",
    UpdateSchedulerAppointmentThen = "Appointments/UpdateSchedulerAppointmentThen",
    UpdateSchedulerAppointment = "Appointments/UpdateSchedulerAppointment",
    UpdateSchedulerAccordions = "Appointments/UpdateSchedulerAccordions",
    UpdateSchedulerIsDateValid = "Appointments/UpdateSchedulerIsDateValid",

    //manager state actions
    UpdateConfirmationNumber = "Appointments/UpdateConfirmationNumber",
    UpdateManagerSelectedDate = "Appointments/UpdateManagerSelectedDate",
    UpdateManagerSelectedTime = "Appointments/UpdateManagerSelectedTime",
    UpdateManagerStage = "Appointments/UpdateManagerStage",
    UpdateManagerAppointment = "Appointments/UpdateManagerAppointment",

    UpdateModals = "Appointments/UpdateModals"
}

const AppointmentsStandardActions = {
    initScheduler: () => createAction(AppointmentsActionTypes.InitScheduler),
    initManager: () => createAction(AppointmentsActionTypes.InitManager),

    logTelemetry: (feature: AppointmentsFeature, action: string, storeId?: number, properties?: { [key: string]: any }, errorMessage?: string) =>
        createAction(AppointmentsActionTypes.LogTelemetry, { feature, action, storeId, properties, errorMessage }),
    logOneDSPageView: () => createAction(AppointmentsActionTypes.LogOneDSPageView),
    logOneDSAction: (overrideValues: IPageActionOverrideValues) => createAction(AppointmentsActionTypes.LogOneDSAction, overrideValues),

    fetchAppointmentServiceTypesByStore: (storeId: number) => createAction(AppointmentsActionTypes.FetchAppointmentServiceTypesByStore, storeId),
    fetchAppointmentServiceTypesByStoreSuccess: (serviceTypes: AppointmentServiceType[]) => 
        createAction(AppointmentsActionTypes.FetchAppointmentServiceTypesByStoreSuccess, serviceTypes),
    fetchAppointmentServiceTypesByStoreFailure: (error: string) => 
        createAction(AppointmentsActionTypes.FetchAppointmentServiceTypesByStoreFailure, error),

    fetchAppointmentServiceTopics: (storeId: number, serviceTypeId: string) => 
        createAction(AppointmentsActionTypes.FetchAppointmentServiceTopics, {storeId, serviceTypeId}),
    fetchAppointmentServiceTopicsSuccess: (topics: AppointmentTopic[]) => 
        createAction(AppointmentsActionTypes.FetchAppointmentServiceTopicsSuccess, topics),
    fetchAppointmentServiceTopicsFailure: (error: string) => 
        createAction(AppointmentsActionTypes.FetchAppointmentServiceTopicsFailure, error),

    fetchAppointmentByConfirmationNumberSuccess: (appointment: Appointment) =>
        createAction(AppointmentsActionTypes.FetchAppointmentByConfirmationNumberSuccess, appointment),
    fetchAppointmentByConfirmationNumberFailure: (error: string) =>
        createAction(AppointmentsActionTypes.FetchAppointmentByConfirmationNumberFailure, error),


    fetchAppointmentTimeslots: (storeId: number, serviceTypeId: string) =>
        createAction(AppointmentsActionTypes.FetchAppointmentTimeslots, {storeId, serviceTypeId}),
    fetchAppointmentTimeslotsSuccess: (timeslots: Date[] | ITimeSlotDto[]) =>
        createAction(AppointmentsActionTypes.FetchAppointmentTimeslotsSuccess, timeslots),
    fetchAppointmentTimeslotsFailure: (error: string) =>
        createAction(AppointmentsActionTypes.FetchAppointmentTimeslotsFailure, error),

    updateAppointmentSuccess: (type: AppointmentUpdateType, appt?: IAppointment) => createAction(AppointmentsActionTypes.UpdateAppointmentSuccess, {type, appt}),
    updateAppointmentFailure: (error: string) => createAction(AppointmentsActionTypes.UpdateAppointmentFailure, error),

    fetchAppointmentStatuses: () => createAction(AppointmentsActionTypes.FetchAppointmentStatuses),
    fetchAppointmentStatusesSuccess: (statuses: IOptionSetAttribute[]) => createAction(AppointmentsActionTypes.FetchAppointmentStatusesSuccess, statuses),
    fetchAppointmentStatusesFailure: (error: string) => createAction(AppointmentsActionTypes.FetchAppointmentStatusesFailure, error),

    fetchAppointmentStatusReasons: () => createAction(AppointmentsActionTypes.FetchAppointmentStatusReasons),
    fetchAppointmentStatusReasonsSuccess: (statuses: IOptionSetAttribute[]) => createAction(AppointmentsActionTypes.FetchAppointmentStatusReasonsSuccess, statuses),
    fetchAppointmentStatusReasonsFailure: (error: string) => createAction(AppointmentsActionTypes.FetchAppointmentStatusReasonsFailure, error),

    updateSelectedTimezone: (timezone: string) => createAction(AppointmentsActionTypes.UpdateSelectedTimezone, timezone),

    createAppointment: (storeId: number, appointment: AppointmentForCreationDto, captchaToken?: string) =>
        createAction(AppointmentsActionTypes.CreateAppointment, {storeId, captchaToken, appointment}),
    createAppointmentSuccess: (appointment: IAppointment) =>
        createAction(AppointmentsActionTypes.CreateAppointmentSuccess, appointment),
    createAppointmentFailure: (error: string) =>
        createAction(AppointmentsActionTypes.CreateAppointmentFailure, error),

    resetCreatedAppointment: () => createAction(AppointmentsActionTypes.ResetCreatedAppointment),

    setIsSubmitInProgress: (isSubmitInProgress: boolean) => createAction(AppointmentsActionTypes.SetIsSubmitInProgress, isSubmitInProgress),

    fetchTopicCustomQuestions: (storeId: number, serviceTypeId: string, topicId: string) =>
        createAction(AppointmentsActionTypes.FetchTopicCustomQuestions, {storeId, serviceTypeId, topicId}),
    fetchTopicCustomQuestionsSuccess: (customQuestions: AppointmentTopicCustomQuestion[]) =>
        createAction(AppointmentsActionTypes.FetchTopicCustomQuestionsSuccess, customQuestions),
    fetchTopicCustomQuestionsFailure: (error: string) =>
        createAction(AppointmentsActionTypes.FetchTopicCustomQuestionsFailure, error),

    fetchStoreByStoreIdSuccess: (stores: StoreDto[]) => createAction(AppointmentsActionTypes.FetchStoreByStoreIdSuccess, stores),
    fetchStoreByStoreIdFailure: (error: string) => createAction(AppointmentsActionTypes.FetchStoreByStoreIdFailure, error),

    fetchStores: () => createAction(AppointmentsActionTypes.FetchStores),
    fetchStoresSuccess: (stores: StoreDto[]) => createAction(AppointmentsActionTypes.FetchStoresSuccess, stores),
    fetchStoresFailure: (error: string) => createAction(AppointmentsActionTypes.FetchStoresFailure, error),

    fetchAppointmentByIdSuccess: (appt: Appointment) => 
        createAction(AppointmentsActionTypes.FetchAppointmentByIdSuccess, appt),
    fetchAppointmentByIdFailure: (error: string) => 
        createAction(AppointmentsActionTypes.FetchAppointmentByIdFailure, error),

    setErrorMessage: (error: string) => createAction(AppointmentsActionTypes.SetErrorMessage, error),
    resetErrorMessage: () => createAction(AppointmentsActionTypes.ResetErrorMessage),

    fetchCaptchaSiteKey: () => createAction(AppointmentsActionTypes.FetchCaptchaSiteKey),
    fetchCaptchaSiteKeySuccess: (key: string) => createAction(AppointmentsActionTypes.FetchCaptchaSiteKeySuccess, key),
    fetchCaptchaSiteKeyFailure: (error: string) => createAction(AppointmentsActionTypes.FetchCaptchaSiteKeyFailure, error),
    
    resetAppointmentTimeslots: () => createAction(AppointmentsActionTypes.ResetAppointmentTimeslots),

    fetchRedirects: () => createAction(AppointmentsActionTypes.FetchRedirects),
    fetchRedirectsSuccess: (redirects: Redirects) => createAction(AppointmentsActionTypes.FetchRedirectsSuccess, redirects),
    fetchRedirectsFailure: (error: string) => createAction(AppointmentsActionTypes.FetchRedirectsFailure, error),

    updateValidatedStoreId: (storeId: number) => createAction(AppointmentsActionTypes.UpdateValidatedStoreId, storeId),
    updateSchedulerStage: (stage: AppointmentStages) => createAction(AppointmentsActionTypes.UpdateSchedulerStage, stage),
    updateSchedulerSelectedTypeName: (name: string) => createAction(AppointmentsActionTypes.UpdateSchedulerSelectedTypeName, name),
    updateSchedulerSelectedTopicName: (name: string) => createAction(AppointmentsActionTypes.UpdateSchedulerSelectedTopicName, name),
    updateSchedulerSelectedDate: (date: string) => createAction(AppointmentsActionTypes.UpdateSchedulerSelectedDate, date),
    updateSchedulerSelectedTime: (time: string) => createAction(AppointmentsActionTypes.UpdateSchedulerSelectedTime, time),
    resetSchedulerSelectedTime: () => createAction(AppointmentsActionTypes.ResetSchedulerSelectedTime),
    updateSchedulerAppointment: (stage: AppointmentStages, appt: IAppointmentForCreationDto) => 
        createAction(AppointmentsActionTypes.UpdateSchedulerAppointment, {stage, appt}),
    updateSchedulerAccordions: (acc: IAccordionState) => createAction(AppointmentsActionTypes.UpdateSchedulerAccordions, acc),
    updateSchedulerIsDateValid: (isValid: boolean) => createAction(AppointmentsActionTypes.UpdateSchedulerIsDateValid, isValid),

    updateConfirmationNumber: (confirmationNumber: string) => createAction(AppointmentsActionTypes.UpdateConfirmationNumber, confirmationNumber),
    updateManagerStage: (stage: ManagementStages) => createAction(AppointmentsActionTypes.UpdateManagerStage, stage),
    updateManagerSelectedDate: (date?: string) => createAction(AppointmentsActionTypes.UpdateManagerSelectedDate, date),
    updateManagerSelectedTime: (time?: string) => createAction(AppointmentsActionTypes.UpdateManagerSelectedTime, time),
    updateManagerAppointment: (appt: Partial<IAppointmentForUpdateDto>) => createAction(AppointmentsActionTypes.UpdateManagerAppointment, appt),

    updateModals: (modals: IModals) => createAction(AppointmentsActionTypes.UpdateModals, modals)
};

const AppointmentsThunkActions = {
    fetchAppointmentById: (storeNumber: number, appointmentId: string) => 
        createActionWithCallback<Appointment>(AppointmentsActionTypes.FetchAppointmentById, {storeNumber, appointmentId}),
    
    updateAppointment: (storeNumber: number, appointmentId: string, appt: AppointmentForUpdateDto, type: AppointmentUpdateType, captchaToken?: string) => 
        createActionWithCallback<IAppointment>(AppointmentsActionTypes.UpdateAppointment, {storeNumber, appointmentId, captchaToken, appt, type}),

    fetchAppointmentByConfirmationNumber: (confirmationNumber: string) =>
        createActionWithCallback<IAppointment>(AppointmentsActionTypes.FetchAppointmentByConfirmationNumber, confirmationNumber),

    fetchStoreByStoreId: (storeId: string) => createActionWithCallback<StoreDto[]>(AppointmentsActionTypes.FetchStoreByStoreId, storeId),
}

const AppointmentsThunkActionsTypes = {
    fetchAppointmentById: (storeNumber: number, appointmentId: string) => 
        createThunkActionType(AppointmentsActionTypes.FetchAppointmentById, {storeNumber, appointmentId}),
    
    updateAppointment: (storeNumber: number, appointmentId: string, appt: AppointmentForUpdateDto, type: AppointmentUpdateType, captchaToken?: string) => 
        createThunkActionType(AppointmentsActionTypes.UpdateAppointment, {storeNumber, appointmentId, captchaToken, appt, type}),

    fetchAppointmentByConfirmationNumber: (confirmationNumber: string) =>
        createThunkActionType(AppointmentsActionTypes.FetchAppointmentByConfirmationNumber, confirmationNumber),
    
    fetchStoreByStoreId: (storeId: string) => createThunkActionType(AppointmentsActionTypes.FetchStoreByStoreId, storeId),
}

export const AppointmentsActions = {...AppointmentsStandardActions, ...AppointmentsThunkActions};
const AllActionsTypes = {...AppointmentsStandardActions, ...AppointmentsThunkActionsTypes};
export type AppointmentsActionsType = ActionsUnion<typeof AllActionsTypes>;
