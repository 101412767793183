import { SagaIterator } from "redux-saga";
import { takeEvery, call, put, getContext } from "redux-saga/effects";
import { Page } from "../../../contracts/Page";
import { RapPageService, IRapPageService } from "../../../platform/services/Page";
import { ActionsOfType } from "../../../platform/redux/ActionHelper";
import { PageActions, PageActionsType, PageActionTypes } from "./PageActions";
import { PageTelemetryLogger } from "./../PageTelemetryLogger";
import { TelemetryLogger } from "../../../common/Telemetry";

export function* pageSaga(): SagaIterator {
    yield takeEvery(PageActionTypes.LogTelemetry, logTelemetry);
    yield takeEvery(PageActionTypes.LogTrace, logTrace);
    yield takeEvery(PageActionTypes.FetchPageData, fetchPageData);
}

export function* logTelemetry(telemetryAction: ActionsOfType<PageActionsType, PageActionTypes.LogTelemetry>): SagaIterator {
    const { feature, action, properties, errorMessage } = telemetryAction.payload;
    const pageContext = yield getContext("pageContext");

    if (pageContext) {
        var telemetryLogger: PageTelemetryLogger = new PageTelemetryLogger(pageContext, feature, action);

        try {
            if (errorMessage && errorMessage.length > 0) {
                yield call(telemetryLogger.captureError, errorMessage);
            } else {
                yield call(telemetryLogger.captureTelemetry, properties ? properties : {});
            }
        } catch (error) {
            yield call(telemetryLogger.captureError, errorMessage);
        }
    }
}

export function* logTrace(telemetryAction: ActionsOfType<PageActionsType, PageActionTypes.LogTrace>): SagaIterator {
    const { traceId, message, severity, properties } = telemetryAction.payload;
    const pageContext = yield getContext("pageContext");

    if (pageContext) {
        var telemetryLogger: TelemetryLogger = new TelemetryLogger(pageContext);

        try {
            if (message && message.length > 0) {
                yield call(telemetryLogger.sendComplexTrace, traceId, message, severity, properties);
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function* fetchPageData(action: ActionsOfType<PageActionsType, PageActionTypes.FetchPageData>): SagaIterator {
    try {
        const pageContext: any = yield getContext("pageContext");
        if (pageContext) {
            const pageService: IRapPageService = ((yield call(
                [pageContext, pageContext.getService],
                "IRapPageService"
            )) as unknown) as RapPageService;
            const pageData: Page = ((yield call([pageService, pageService.getData])) as unknown) as Page;
            yield put(PageActions.fetchPageDataSuccess(pageData));
        }
    } catch (error) {
        yield put(PageActions.fetchPageDataFailure((error as any).message || error));
    }
}
