import React from "react";
import $ from "jquery";
import Shell from "./platform/core/components/Shell/Shell";
import { ReactRootComponent } from "./platform/Layout";
import { RapPageContext, IRapPageContext, Services, RestClients, ServiceOptions } from "./platform/Context";
import { RapTelemetryService, IRapTelemetryService } from "./platform/services/Telemetry";
import { RapPageService, IRapPageService } from "./platform/services/Page";
import { RapAuthenticationService } from "./platform/services/Authentication";
import { RestClientOptionsProviderService } from "./platform/services/RestClientOptionsProvider";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import "./styling/_authloading.scss";
import * as Constants from "./../src/common/Constants";
import { DigitalReservationsRestClientName } from "./platform/RestClientBase";
import { DigitalReservationsApiClient } from "./contracts/swagger/_generated";
import { IOneDsService, OneDsService } from "./platform/services/OneDs";

import './App.scss';

export interface IAppState {
    accessToken: string;
    theme: string;
    fontSize: number;
}

export class App extends React.Component<{}, IAppState> {
    private pageContext: IRapPageContext;

    constructor(props: any) {
        super(props);
        this.state = { accessToken: "", theme: "default", fontSize: 10 };

        RestClients.add(DigitalReservationsRestClientName, { factory: DigitalReservationsApiClient });

        Services.add("IRapPageService", { serviceFactory: RapPageService });
        Services.add("IRapTelemetryService", { serviceFactory: RapTelemetryService, options: ServiceOptions.Persistant });
        Services.add("IRapAuthenticationService", { serviceFactory: RapAuthenticationService }); // TODO: Evaluate whether we should keep this or not. We dont need auth right away for this project but maybe later we'll want it?
        Services.add("IRestClientOptionsProviderService", { serviceFactory: RestClientOptionsProviderService });
        Services.add("IOneDsService", { serviceFactory: OneDsService });

        this.pageContext = new RapPageContext(Services, RestClients);
        initializeIcons();
    }

    public async componentDidMount() {
        this.initializeServices.bind(this);
    }

    private initializeServices() {
        //Initialize other services
        this.pageContext.getService<IRapTelemetryService>("IRapTelemetryService");
        this.pageContext.getService<IRapPageService>("IRapPageService");
        this.pageContext.getService<IOneDsService>("IOneDsService");
    }

    render() {
        return (
            <div className="c-base-div">
                <ReactRootComponent pageContext={this.pageContext}>
                    <Shell />
                </ReactRootComponent>
            </div>
        );
    }
}

export function setThemeClassName(theme: string) {
    let $body = $("body");
    if ($body) {
        $body.removeClass(Constants.Theme_Dark);
        $body.removeClass(Constants.Theme_HighContrast);
        $body.removeClass(Constants.Theme_Default);

        switch (theme) {
            case Constants.Theme_Default_Key:
                $body.addClass(Constants.Theme_Default);
                break;
            case Constants.Theme_HighContrast_Key:
                $body.addClass(Constants.Theme_HighContrast);
                break;
            case Constants.Theme_Dark_Key:
                $body.addClass(Constants.Theme_Dark);
                break;
        }
    }
}

export function getSelectedThemeKey(): string {
    let $body = $("body");
    if ($body) {
        if ($body.hasClass(Constants.Theme_Dark)) {
            return Constants.Theme_Dark_Key;
        }

        if ($body.hasClass(Constants.Theme_Default)) {
            return Constants.Theme_Default_Key;
        }

        if ($body.hasClass(Constants.Theme_HighContrast)) {
            return Constants.Theme_HighContrast_Key;
        }
    }

    return "";
}
