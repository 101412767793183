import { Area, TelemetryProperties } from "./../../pages/Dashboard/Contracts";
import { PageFeature } from "../../common/Constants";
import { TelemetryLogger } from "../../common/Telemetry";
import { IRapPageContext } from "./../../platform/Context";

export class PageTelemetryLogger {
    protected _adminTelemetry: TelemetryLogger;
    private _feature: PageFeature;
    private _action: string;

    public constructor(context: IRapPageContext, feature: PageFeature, action: string) {
        this._adminTelemetry = new TelemetryLogger(context);

        this._feature = feature;
        this._action = action;
    }

    public captureAttemptStart = (): void => {
        this.captureTelemetry({});
    };

    public captureError = (errorMessage: string): void => {
        var properties: { [key: string]: any } = {};
        properties[TelemetryProperties.Action] = this._action;
        properties[TelemetryProperties.ErrorMessage] = errorMessage;

        this._adminTelemetry.sendComplexTelemetry(Area.Dashboard, PageFeature[this._feature], properties);
    };

    public captureTelemetry = (properties: { [key: string]: any }): void => {
        properties[TelemetryProperties.Action] = this._action;

        this._adminTelemetry.sendComplexTelemetry(Area.Dashboard, PageFeature[this._feature], properties);
    };
}
