import React from "react";
import { connect } from "react-redux";

import { RapComponent, IRapComponentProperties } from "../../../../platform/Layout";
import { Accordion } from "../../../../common/components/Accordion/Accordion"; 

import { IDashboardViewState } from "../../../../pages/Dashboard/Contracts";
import { AppointmentsActions } from "../../redux/AppointmentsActions";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";

import { IAppointmentForCreationDto, IAppointmentServiceType, IAppointmentTopic } from "../../../../contracts/swagger/_generated";
import { DefaultButton, Icon } from "@fluentui/react";
import { AppointmentStages, IAccordionState, ITypeObject } from "../../Contracts";
import { getTypeObjectDetails, timeoutFunction } from "../../AppointmentsHelper";
import { SvgIcon } from "../../../../common/components/SvgIcon/SvgIcon";

import * as Constants from "../../../../common/Constants";
import * as AppointmentsSelectors from "../../redux/AppointmentsSelectors";

import "./AppointmentType.scss";
import { announce } from "../../../../platform/core/util/Accessibility";

interface IAppointmentTypeState {
}

//Props passed by parent component
interface IAppointmentTypeProvidedProps extends IRapComponentProperties {
}

//Props mapped from state object
interface IAppointmentTypeInitializerOwnProps extends IAppointmentTypeProvidedProps {
    serviceTypes: IAppointmentServiceType[];
    serviceTopics: IAppointmentTopic[];
    selectedTypeName?: string;
    appointment: IAppointmentForCreationDto;
    accordions: IAccordionState;
    storeId: number;
}

// eslint-disable-next-line
export type IAppointmentTypeInitializerProps = IAppointmentTypeInitializerOwnProps & typeof ActionsToDispatch;

class AppointmentTypeInitializer extends RapComponent<IAppointmentTypeInitializerProps, IAppointmentTypeState> {
    constructor(props: IAppointmentTypeInitializerProps) {
        super(props);
        this.props.fetchAppointmentServiceTypesByStore(this.props.storeId);
    }

    public componentDidUpdate(prevProps: IAppointmentTypeInitializerProps) {
        // service types load
        if (!prevProps.serviceTypes && this.props.serviceTypes && this.props.serviceTypes.length === 0) {
            this.props.setErrorMessage(localizedStrings.TypeTopic?.noServices as string);
        }

        if(this.props.appointment.appointmentTypeId && !this.props.selectedTypeName && this.props.serviceTypes) {
            const appointmentServiceType = this.props.serviceTypes?.find(x => x.typeId === this.props.appointment?.appointmentTypeId);
            this.props.updateSelectedTypeName(appointmentServiceType?.typeName as string);
        }
    }

    private isValidService(serviceName: string | undefined) {
        //TODO: Remove this once training services are deactivated
        // remove all training services that dont include setup
        if(this.props.storeId == 9987 && serviceName?.toLowerCase().includes("training") && !serviceName?.toLowerCase().includes("setup")){
            return false;
        }

        // hide Education Consultation for en-ca users
        if (localizedStrings.getLanguage() === Constants.CanadaLocale && serviceName?.toLowerCase().includes(Constants.EducationAppointmentTypes.EducationConsultation.toLowerCase())) {
            return false;
        }

        return true;
    }

    private getContent = () => {
        if(this.props.serviceTypes) {
            return this.props.serviceTypes.map((service: IAppointmentServiceType) => {
                var typeObject = this.getTypeObject(service);
                if(!this.isValidService(typeObject.typeName)) {
                    return;
                }
                return (
                    <div className="c-type-container" key={service.typeId}>
                        <div className="c-logo-container">
                            <div className="c-logo-container-logo">
                                {typeObject.details.iconName ? (
                                    <Icon iconName={typeObject.details.iconName} className="c-icon"/>
                                ) : (
                                    <SvgIcon svg={typeObject.details.svg} className="c-icon"/>
                                )}
                            </div>
                            <div className="c-logo-subcontainer">
                                <div className="c-logo-container-title">
                                    <span>{typeObject.details.label}</span>
                                </div>
                                <div className="c-logo-container-time">
                                    <Icon iconName={"Clock"} className="c-icon-clock"/>
                                    <span>{`${service.duration} minutes`}</span>
                                </div>
                            </div>
                        </div>
                        <div className="c-info-container2">
                            <div className="c-info-container2-description">
                                <span>{service.description}</span>
                            </div>
                            <div className="c-info-container2-button">
                                <DefaultButton 
                                    text={typeObject.details.buttonLabel} 
                                    className={
                                        "c-button" + 
                                        ((this.props.selectedTypeName && this.props.selectedTypeName === service.typeName) ? 
                                        " selected" : 
                                        "")
                                    } 
                                    tabIndex={0}
                                    onClick={() => this.onTypePress(typeObject)}
                                    data-bi-bhvr={Constants.CheckpointBehavior1DS}
                                    data-bi-scn={Constants.BookAppointmentScenario}
                                    data-bi-scnstp={Constants.TypeStep}
                                    data-bi-stpnum={2}
                                    data-bi-field1={typeObject.details.buttonLabel}
                                />
                            </div>
                        </div>
                    </div>
                );
            });
        }
        return [];
    }

    private getTypeObject = (service: IAppointmentServiceType): ITypeObject => {
        const details = getTypeObjectDetails(service.typeName ? service.typeName : "");
        return {
            ...service,
            details
        } as ITypeObject
    }

    private onTypePress = (service: ITypeObject) => { 
        this.props.logTelemetry(Constants.AppointmentsFeature.SelectType, "Appointment type selected: " + service.details.label, this.props.storeId);

        if (service.typeId !== this.props.appointment.appointmentTypeId) {
            // Reset timeslots if a different type was selected
            this.props.resetAppointmentTimeslots();
        }

        this.props.updateAppointment(AppointmentStages.TYPE, 
        {
            ...this.props.appointment, 
            appointmentTypeId: service.typeId ? service.typeId : "" 
        });

        this.props.updateAccordions({...this.props.accordions, isTypeOpen: false});
        timeoutFunction(
            () => {
                if(this.props.serviceTopics) {
                    return true;
                }
                return false;
            },
            () => this.props.updateAccordions({...this.props.accordions, isTopicOpen: true}),
            500,
            100,
            10,
            localizedStrings.AppointmentScheduler?.unableToLoadTopics as string
        );

        this.props.updateSelectedTypeName(service.typeName as string);
        announce(`${service.details.buttonLabel} ${localizedStrings.DateAndTime?.selectedLabel}`);

    }

    private getTitle = () => {
        return this.props.isOpen ? `${localizedStrings.TypeTopic?.chooseType} *` : localizedStrings.TypeTopic?.chooseType;
    }

    public render() {
        return (<>
            <Accordion 
                title={this.getTitle()}
                isOpen={this.props.accordions.isTypeOpen} 
                onClick={() => this.props.updateAccordions({...this.props.accordions, isTypeOpen: !this.props.accordions.isTypeOpen})}
                isValid={this.props.appointment.appointmentTypeId !== ""}
                disabled={this.props.serviceTypes === undefined || this.props.serviceTypes.length === 0}
                >
                    {this.props.accordions.isTypeOpen && this.props.accordions.isTypeOpen === true && (<>
                        {this.getContent()}
                        <div className="c-type-required">{localizedStrings.AppointmentScheduler?.requiredText}</div>
                    </>)}
            </Accordion>
        </>)
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IDashboardViewState, providedProps: IAppointmentTypeProvidedProps): Partial<IAppointmentTypeInitializerOwnProps> {
    return {
        ...providedProps,
        serviceTypes: AppointmentsSelectors.getAppointmentServiceTypes(state),
        selectedTypeName: AppointmentsSelectors.getSelectedTypeName(state),
        appointment: AppointmentsSelectors.getSchedulerAppointment(state),
        accordions: AppointmentsSelectors.getSchedulerAccordions(state),
        serviceTopics: AppointmentsSelectors.getAppointmentServiceTopics(state),
        storeId: AppointmentsSelectors.getValidatedStoreId(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: AppointmentsActions.logTelemetry,
    fetchAppointmentServiceTypesByStore: AppointmentsActions.fetchAppointmentServiceTypesByStore,
    fetchAppointmentServiceTopics: AppointmentsActions.fetchAppointmentServiceTopics,
    updateSelectedTypeName: AppointmentsActions.updateSchedulerSelectedTypeName,
    setErrorMessage: AppointmentsActions.setErrorMessage,
    updateAppointment: AppointmentsActions.updateSchedulerAppointment,
    updateAccordions: AppointmentsActions.updateSchedulerAccordions,
    resetAppointmentTimeslots: AppointmentsActions.resetAppointmentTimeslots
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentTypeInitializer);
